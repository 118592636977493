import { Timestamps, mixin } from "./common";

import { hydrate } from "@bespohk/lib";

class User {
  readonly uuid: string;
  email: string;
  firstName: string;
  lastName: string;
  code?: string;
  title?: string;
  readonly isActive: boolean;
  roles?: string[];
  @hydrate
  createdDate?: Date;
  @hydrate
  updatedDate?: Date;

  public get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  public isRole(...keys: string[]): boolean {
    return keys.reduce((is: boolean, key) => {
      if (this.roles.indexOf(key) === -1) {
        is = false;
      }
      return is;
    }, true);
  }

  public get isAdmin(): boolean {
    return this.isRole("admin");
  }

  public get isSales(): boolean {
    return this.isRole("sales");
  }

  public get isOperations(): boolean {
    return this.isRole("ops");
  }

  public get isBusinessDevelopmentManager(): boolean {
    return this.isRole("bdm");
  }

  public toString(): string {
    return this.fullName;
  }

  public get name(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  public get humanRoles(): string {
    return this.roles
      ? this.roles.map(role => User.roleMapping[role]).join(", ")
      : "";
  }

  public static get roleMapping() {
    return {
      admin: "Administrator",
      sales: "Sales",
      ops: "Operations",
      bdm: "Business Development Manager",
    };
  }
}

interface User extends Timestamps {} // eslint-disable-line @typescript-eslint/no-empty-interface

mixin(User, [Timestamps]);

export default User;
