type Item = {
  partNumber: string;
  reference: string;
  description: string;
  blankReference: string;
  pg: string;
  priceEach: number;
  priceTotal: number;
  quantity: number;
};

class OEF {
  items: Item[];

  public get asCsv(): string {
    return this.items
      .reduce((items, item) => {
        if (!items.length) {
          items = [
            "Part Number,Reference,Description,,PG,Price (each),Price (total),QTY",
          ];
        }
        items.push(Object.values(item).join(","));

        return items;
      }, [])
      .join("\n");
  }
}

export { Item };

export default OEF;
