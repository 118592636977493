enum Territory {
  victoria = "Victoria",
  new_south_wales = "New South Wales", // eslint-disable-line @typescript-eslint/camelcase
  tasmania = "Tasmania",
  queensland = "Queensland",
  western_australia = "Western Australia", // eslint-disable-line @typescript-eslint/camelcase
  south_australia = "South Australia", // eslint-disable-line @typescript-eslint/camelcase
  northern_territory = "Northern Territory", // eslint-disable-line @typescript-eslint/camelcase
  australian_capital_territory = "Australian Capital Territory", // eslint-disable-line @typescript-eslint/camelcase
  new_zealand = "New Zealand", // eslint-disable-line @typescript-eslint/camelcase
  xpo = "XPO",
  ice = "ICE",
}

const mapTerritoryToCode = (territory: Territory): string => {
  const code: string[] = territory.toLowerCase().split("_");
  if (code[0] === "queensland") {
    return "QLD";
  }
  if (code.length < 2) {
    return code[0].substr(0, 3).toUpperCase();
  }

  let mappedTerritory = code.map(c => c[0].toUpperCase()).join("");
  if (mappedTerritory === "SA") {
    mappedTerritory = "SAU";
  } else if (mappedTerritory === "WA") {
    mappedTerritory = "WAU";
  } else if (mappedTerritory === "NT") {
    mappedTerritory = "NTS";
  }

  return mappedTerritory;
};

export { mapTerritoryToCode };

export default Territory;
