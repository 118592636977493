import { default as BaseAction, Pagination } from "../types";

import Types from "./types";

type Action<T, C> = BaseAction<Pagination<T>> & {
  // klass: C;
};

const fetch = (): Action<[], null> => ({
  type: Types.FETCH_REQUEST,
  payload: {
    count: 0,
    results: [],
  },
  // klass: null,
});

const fetchSuccess = <T, C>(payload: Pagination<T>): Action<T, C> => ({
  type: Types.FETCH_SUCCESS,
  payload,
});

const fetchError = (): Action<[], null> => ({
  type: Types.FETCH_ERROR,
  payload: {
    count: 0,
    results: [],
  },
});

const clear = (): Action<[], null> => ({
  type: Types.CLEAR,
  payload: {
    count: 0,
    results: [],
  },
});

export { fetch, fetchSuccess, fetchError, clear, Action };
