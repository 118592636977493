import { Offering } from "@app/models/project";

type GroupedOfferings = {
  [s: string]: Offering[];
};

const groupOfferings = (offerings: Offering[]): GroupedOfferings =>
  offerings.reduce((grouped: GroupedOfferings, offering: Offering) => {
    if (!(offering.type in grouped)) {
      grouped[offering.type] = [];
    }
    grouped[offering.type].push(offering);
    return grouped;
  }, {});

export { groupOfferings };
