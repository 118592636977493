import { default as Model } from "@app/models/backplate";
import Wallbox from "./wallbox";
import { gridRenderer } from "../helpers";
import styles from "./styles.modules.css";

type OwnProps = {
  backplate: Model;
  showCosts?: boolean;
  isDrawing?: boolean;
};

const Backplate = ({ backplate, showCosts, isDrawing }: OwnProps) =>
  gridRenderer(backplate.wallboxes, Wallbox, "wallbox", styles.rows, {
    showCosts,
    isDrawing,
  });

export { Backplate };
export default Backplate;
