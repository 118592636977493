import { Dimensions, Error, handlingFeesForQuantity } from "./common";
import { rows as generateRows, rows } from "@app/helpers/components";

import { INVALID_GANG, HEIGHT_OFFSET } from "@app/helpers/constants";
import { Panel } from "./panel";
import { Wallbox } from "./wallbox";
import { rounding } from "@app/helpers/currency";
import { log } from "@bespohk/lib";

type SubAssembly = {
  itemCode: string;
  description: string;
  quantity: number;
};

type WallboxSubAssembly = SubAssembly & {
  dividers: number;
  joins: number;
};

const WIDTH_OFFSET = 7;

class Backplate {
  wallboxes: Wallbox[] = [];
  panel: Panel;
  partSubAssemblies: SubAssembly[] = [];

  public get isStandardPart(): boolean {
    return (
      this.wallboxes.length === 1 &&
      !!this.wallboxes[0].hasStandardPartNumber &&
      !this.wallboxes[0].dividers.length
    );
  }

  public get standardPartNumber(): string {
    if (!this.isStandardPart) {
      return null;
    }
    return this.wallboxes[0].standardPartNumber;
  }

  public get platePartNumber(): string {
    if (!this.isStandardPart) {
      return null;
    }

    return this.wallboxes[0].platePartNumber;
  }

  public get isValid(): boolean {
    return !this.wallboxes.find(wallbox => wallbox.gang === INVALID_GANG);
  }

  public get isVertical(): boolean {
    return !!this.wallboxes.find(wallbox => wallbox.isVertical);
  }

  public get gangs(): string[] {
    return this.wallboxes.map(wallbox => wallbox.humanGang);
  }

  public get hasPotentialWallboxSubAssemblies(): boolean {
    const hasDividers = !!this.wallboxes.find(
      wallbox => wallbox.dividers.length,
    );

    return hasDividers || this.wallboxes.length > 1;
  }

  public get wallboxSubAssemblies(): WallboxSubAssembly[] {
    const subAssemblies = this.wallboxes.reduce(
      (assemblies: WallboxSubAssembly[], wallbox: Wallbox, index: number) => {
        const dividers = wallbox.dividers.length;
        let nextWallbox: Wallbox = null;
        try {
          nextWallbox = this.wallboxes[index + 1];
        } catch (e) {
          // No wallbox
        }
        let prevWallbox: Wallbox = null;
        try {
          prevWallbox = this.wallboxes[index - 1];
        } catch (e) {
          // No wallbox
        }

        let joins = 0;
        // let joins = total === 1 ? 0 : index > 0 && index < total - 1 ? 2 : 1;
        if (nextWallbox && !nextWallbox.startsRow) {
          joins++;
        }
        if (prevWallbox && !wallbox.startsRow) {
          joins++;
        }
        if (!wallbox.panelType) {
          log("No panelType associated with this wallbox", wallbox, this.panel);
          return assemblies;
        }

        assemblies.push({
          dividers,
          joins,
          itemCode: wallbox.standardPartNumber,
          description: `${wallbox.panelType.subAssemblyPrefix} ${wallbox.humanGang} WB CW `,
          quantity: 1,
        });

        return assemblies;
      },
      [],
    );
    subAssemblies.forEach(assembly => {
      const joins = assembly.joins ? `J${assembly.joins}` : "";
      const dividers = assembly.dividers ? `D${assembly.dividers}` : "";
      if (assembly.joins || assembly.dividers) {
        assembly.itemCode += `-${joins}${dividers}`;
        assembly.description += `${assembly.dividers} DIVIDER & ${assembly.joins} JOIN`;
      }
    });
    return subAssemblies;
  }

  public get subAssemblies(): WallboxSubAssembly[] | SubAssembly[] {
    return [...this.wallboxSubAssemblies, ...this.partSubAssemblies];
  }

  public get partNumber(): string {
    const { hasPotentialWallboxSubAssemblies } = this;
    if (this.isStandardPart && !hasPotentialWallboxSubAssemblies) {
      return this.standardPartNumber;
    }

    return `${this.panel.yyNumber}-WB`;
  }

  public get cost(): number {
    const { panel } = this;
    const handlingFee = handlingFeesForQuantity(panel.quantity) / 2;

    return rounding(
      this.wallboxes.reduce((cost, wallbox) => {
        cost += wallbox.cost;
        return cost;
      }, handlingFee),
    );
  }

  public get totalCost(): number {
    return rounding(this.cost * this.panel.quantity);
  }

  public get depth(): number {
    return this.wallboxes.reduce((p, c) => {
      if (c.rcdBank) {
        return 75;
      }
      return p;
    }, 50);
  }

  public get dimensions(): Dimensions {
    const { strappingSize, hasGas, isVertical } = this.panel;
    const rows = generateRows<Wallbox>(this.wallboxes);
    const width = rows[0].reduce((width, wallbox) => {
      width += wallbox.width;
      return width;
    }, WIDTH_OFFSET);
    const heightOffset = isVertical && hasGas ? HEIGHT_OFFSET : 0;
    return {
      width,
      height: rows.length * strappingSize - 36 - heightOffset,
    };
  }

  public errors(): Error[] {
    const errors: Error[] = [];

    if (this.wallboxes.length === 1 && this.wallboxes[0].gang === "1") {
      errors.push({
        message:
          "You must have at least a 2 gang wallbox, please add additional spacers.",
        skippable: false,
      });
    }
    this.wallboxes.forEach((wallbox: Wallbox, index) => {
      if (!wallbox.isValid) {
        errors.push({
          message: `The drawing cannot be saved, the width of the wallbox must be divisible by 50 (error generating box ${index}, check your spacers, current width ${wallbox.width}). Add spacers to your drawing.`,
          skippable: false,
        });
      }
    });
    if (this.isVertical) {
      const wallboxRows = rows<Wallbox>(this.wallboxes);
      wallboxRows.reduce((width, row, index) => {
        const rowWidth = row.reduce(
          (width, wallbox) => width + wallbox.width,
          0,
        );
        if (width && rowWidth !== width) {
          errors.push({
            message: `Wallboxes must be the same size in a vertical drawing (issue at row ${index +
              2}, width ${rowWidth} must equal ${width}).`,
            skippable: false,
          });
        }
        width = rowWidth;
        return width;
      }, 0);
    }

    const diffWidths =
      this.dimensions.width - WIDTH_OFFSET - this.panel.dimensions.width;
    if (diffWidths !== 30 && diffWidths !== -30) {
      errors.push({
        skippable: false,
        message:
          "Invalid dimensions, are you sure you've added enough spacers?",
      });
    }

    return errors;
  }
}

export { INVALID_GANG, SubAssembly };

export default Backplate;
