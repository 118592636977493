import { Operations, operations } from "@app/state/ducks/auth/operations";
import React, { useEffect } from "react";

import { Redirect } from "react-router-dom";
import State from "@app/state";
import Token from "@app/models/token";
import { useOperations } from "@app/helpers/redux";
import { useSelector } from "react-redux";

type OwnProps = {};

const Logout = (_: OwnProps) => {
  const ops: Operations = useOperations(operations);
  const token: Token = useSelector((state: State) => state.auth.data.token);

  useEffect(() => {
    ops.logout();
  });

  return token ? <Redirect to="/" /> : <></>;
};

export { Logout };
export default Logout;
