import { getValue } from "@bespohk/lib";

const valueExistsInArrayOfObjects = (
  dot: string,
  needle: any,
  haystack: any[],
): boolean => {
  const valueToFind = getValue(needle, dot);
  return !!haystack.find(item => valueToFind === getValue(item, dot));
};

export { valueExistsInArrayOfObjects };
