// @ts-nocheck
import { Layout } from "@app/containers/layout";
import { Provider } from "react-redux";
import React from "react";
import { Theme } from "@bespohk/uikit/components";
import buttonStyles from "@app/assets/css/theme/button.styles.modules.css";
import { configureStore } from "@app/state";
import fieldStyles from "@app/assets/css/theme/field.styles.modules.css";
import inputStyles from "@app/assets/css/theme/input.styles.modules.css";
import loaderStyles from "@app/assets/css/theme/loader.styles.modules.css";
import pageReadStyles from "@app/assets/css/theme/page-read.styles.modules.css";
import paginationStyles from "@app/assets/css/theme/pagination.styles.modules.css";
import selectStyles from "@app/assets/css/theme/select.styles.modules.css";
import tableStyles from "@app/assets/css/theme/table.styles.modules.css";
import wrapperStyles from "@app/assets/css/theme/wrapper.styles.modules.css";

const App = (): any => (
  <Provider store={configureStore()}>
    <Theme
      components={{
        Button: buttonStyles,
        Table: tableStyles,
        Pagination: paginationStyles,
        Input: inputStyles,
        Wrapper: wrapperStyles,
        Field: fieldStyles,
        Select: selectStyles,
        Loader: loaderStyles,
        PageRead: pageReadStyles,
      }}
    >
      <Layout />
    </Theme>
  </Provider>
);

export { App };
export default App;
