import { NavigationButton } from "@app/components";
import React from "react";
import { Text } from "@bespohk/uikit/components";

type OwnProps = {};

const Error404 = (_: OwnProps) => {
  return (
    <div>
      <Text>There aren't the droids you're looking for.</Text>

      <NavigationButton label="Return" path="/" />
    </div>
  );
};

export { Error404 };
export default Error404;
