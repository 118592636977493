import { Timestamps, mixin } from "./common";

import Component from "./component";
import { INVALID_GANG } from "@app/helpers/constants";
import PanelType from "./panel-type";
import { formatter } from "@app/helpers/currency";
import { offset as getOffset } from "@app/helpers/components";
import { hydrate } from "@bespohk/lib";

class Definition {
  readonly uuid: string;
  code: string;
  standardPartNumber: string;
  platePartNumber: string;
  gang: string;
  cost: number;
  width: number;
  description: number;
  panelType: PanelType;
  gasOnly?: boolean;
  rcdBank?: boolean;
  @hydrate
  createdDate?: Date;
  @hydrate
  updatedDate?: Date;

  public get humanCost() {
    return formatter.format(this.cost);
  }

  public get humanWidth(): string {
    return `${this.width}mm`;
  }

  public get humanGang(): string {
    let suffix = "G";
    let gang = this.gang;
    if (gang === INVALID_GANG) {
      return gang;
    }
    if (this.gasOnly) {
      suffix = "GG";
      gang = Math.round(parseFloat(gang) / 2).toFixed(1);
      const parsedFixedGang: number = parseFloat(gang);
      gang = parsedFixedGang % 1 !== 0 ? gang : `${parsedFixedGang / 1}`; // Strip the decimal for visual
    }

    return `${gang}${suffix}`;
  }

  public get svg(): string {
    return `${this.code}.svg`;
  }

  public toString(): string {
    return this.code;
  }
}

interface Wallbox extends Timestamps {} // eslint-disable-line @typescript-eslint/no-empty-interface

mixin(Definition, [Timestamps]);

class Wallbox extends Definition {
  components: Component[] = [];

  public get isValid(): boolean {
    return !(this.gang === INVALID_GANG);
  }

  public get hasStandardPartNumber(): boolean {
    return !!this.standardPartNumber;
  }

  public get humanDescription(): string {
    return this.description
      ? `${this.description}`
      : `${this.humanGang} Gang Wallbox`;
  }

  public get isVertical(): boolean {
    return !!this.components.find(component => component.startsRow);
  }

  public get startsRow(): boolean {
    return !!(this.components.length && this.components[0].startsRow);
  }

  public get dividers(): number[] {
    const dividers: number[] = [];
    let lastComponent: Component = null;
    const { components } = this;
    components.forEach((component, index) => {
      const { isNurseCall, forcedDivider } = component;
      let addDivider = false;

      if (lastComponent) {
        let actualLastComponent = lastComponent;
        let skippedSpacers = false;
        if (!forcedDivider) {
          while (actualLastComponent.isSpacer && index > 0) {
            skippedSpacers = true;
            index -= 1;
            actualLastComponent = this.components[index];
          }
        }
        let offset = getOffset(components, index);
        if (skippedSpacers) {
          offset += actualLastComponent.width;
        }
        if (
          (actualLastComponent.isType("lv") && component.isType("elv")) ||
          (actualLastComponent.isType("elv") && component.isType("lv")) ||
          isNurseCall
        ) {
          addDivider = true;
        }

        if (addDivider || forcedDivider) {
          if (offset % 2 !== 0) {
            offset += 25; // Divider offset is not a multiple of 50, need to add 25mm
          }
          if (component.forcedDivider !== false) {
            // if it hasn't been explicitly removed add it in
            component.forcedDivider = true;
            dividers.push(offset);
          }
        }
      }
      lastComponent = component;
    });

    return dividers;
  }
}

export { Wallbox, Definition };

export default Wallbox;
