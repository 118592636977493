import { Action } from "./actions";
import Project from "@app/models/project";
import { State } from "../types";
import Types from "./types";
import { rehydrate } from "@bespohk/lib";
import Panel from "@app/models/panel";

const initialState: State<Project> = {
  loading: null,
  data: null,
};

const setPanelDeleteState = (
  project: Project,
  panel: Panel,
  state: boolean,
) => {
  const existingPanel = project.panels.find(p => p.equals(panel));
  existingPanel.deleted = state;
  const panelSetPanel = project.panelSets.find(panelSet =>
    panelSet.panel.equals(panel),
  );
  panelSetPanel.panel.deleted = state;
};

const setPanelApprovalState = (
  project: Project,
  panel: Panel,
  type: any,
  state: boolean,
) => {
  const existingPanel = project.panels.find(p => p.equals(panel));
  existingPanel[type] = state;
  const panelSetPanel = project.panelSets.find(panelSet =>
    panelSet.panel.equals(panel),
  );
  panelSetPanel.panel[type] = state;
};

const reducer = (state = initialState, action: Action): State<Project> => {
  const { type } = action;
  let { payload } = action;
  let loading = null;

  switch (type) {
    case Types.FETCH_REQUEST:
      loading = true;
      payload = state.data;
      break;

    case Types.FETCH_SUCCESS:
      loading = false;
      break;

    case Types.FETCH_ERROR:
      loading = false;
      break;

    case Types.CREATE_SUCCESS:
    case Types.UPDATE_SUCCESS:
      loading = false;
      break;

    case Types.ADD_NEW_PANEL: {
      loading = false;
      const { panel, panelSet } = action.data;
      payload.panelSets.push(panelSet);
      payload.panels.push(panel);
      break;
    }

    case Types.DELETE_PANEL: {
      loading = false;
      setPanelDeleteState(payload, action.data, true);
      break;
    }

    case Types.CLEAR:
      return {
        loading: null,
        data: null,
      };

    case Types.SET_OFFERINGS: {
      payload.offerings = action.data;
      break;
    }

    case Types.UNDELETE_PANEL_SUCCESS: {
      loading = false;
      setPanelDeleteState(payload, action.data, false);

      break;
    }

    case Types.APPROVE_PANEL_SUCCESS: {
      loading = false;
      setPanelApprovalState(
        payload,
        action.data.panel,
        action.data.type,
        action.data.valid,
      );
      break;
    }

    default:
      return state;
  }

  return { loading, data: rehydrate(Project, payload) };
};

export { reducer };

export default reducer;
