import { Icon, Icons } from "@bespohk/uikit/components";

import React from "react";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";

const cx = classNames.bind(styles);

type OwnProps = {
  valid: boolean;
};

const BooleanColumnRenderer = ({ valid }: OwnProps) => {
  return (
    <Icon
      className={cx({ valid: true, invalid: !valid })}
      name={valid ? Icons.CheckCircle : Icons.Circle}
    />
  );
};

BooleanColumnRenderer.defaultProps = {
  valid: false,
} as OwnProps;

export { BooleanColumnRenderer };
export default BooleanColumnRenderer;
