import React from "react";
import { Image, Wrapper } from "@bespohk/uikit/components";
import { asset } from "@app/helpers/assets";
import styles from "./styles.modules.css";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const Header = () => (
  <div className={cx({ header: true })}>
    <Wrapper>
      <Image src={asset("/img/logo.svg")} className={cx({ logo: true })} />
    </Wrapper>
  </div>
);

export { Header };
export default Header;
