import { Wrapper, useTheme } from "@bespohk/uikit/components";

import React from "react";
import styles from "./styles.modules.css";

type OwnProps = {
  children: React.ReactNode;
};

const Content = ({ children }: OwnProps) => {
  const cx = useTheme(Content, styles);
  return (
    <div className={cx({ outer: true })}>
      <Wrapper>{children}</Wrapper>
    </div>
  );
};

export { Content };
export default Content;
