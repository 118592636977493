import Layout from "./layout";
import React from "react";

type OwnProps = {};

const Factory = (_: OwnProps) => {
  return <Layout type="factory" />;
};

export { Factory };
export default Factory;
