import { Image, Wrapper, useTheme } from "@bespohk/uikit/components";

import React from "react";
import { asset } from "@app/helpers/assets";
import styles from "./styles.modules.css";

type OwnProps = {};

const Branding = (_: OwnProps) => {
  const cx = useTheme("Branding", styles);
  return (
    <Wrapper>
      <div className={cx({ masthead: true })}>
        <div className={cx({ brand: true })}>Morris</div>
        <Image src={asset("/img/logo.svg")} className={cx({ logo: true })} />
      </div>
    </Wrapper>
  );
};

export { Branding };
export default Branding;
