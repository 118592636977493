import Panel from "@app/models/panel";
import Project from "@app/models/project";
import React from "react";
import { Text } from "@bespohk/uikit/dist/components";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";

const cx = classNames.bind(styles);

type OwnProps = {
  project: Project;
  panel: Panel;
};

type Group = {
  [key: string]: string[];
};

const Legend = ({ panel }: OwnProps) => {
  const componentsByEquipmentCode = panel.components.reduce(
    (grouped: Group, component) => {
      const { equipmentCode, legendDescription, isSpacer } = component;
      if (isSpacer) {
        return grouped;
      }
      if (!grouped[equipmentCode]) {
        grouped[equipmentCode] = [];
      }

      const name = legendDescription;

      if (grouped[equipmentCode].indexOf(name) === -1) {
        grouped[equipmentCode].push(name);
      }

      return grouped;
    },
    {},
  );

  return (
    <div className={cx({ legend: true })}>
      {panel.isVertical && (
        <Text className={styles.strapping}>
          All centre lines between services from one row to another is{" "}
          {panel.strappingSize}mm
        </Text>
      )}
      <table
        className={cx({ table: true, outer: true })}
        cellSpacing={0}
        cellPadding={0}
      >
        <thead>
          <tr>
            <th colSpan={2}>Components</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(componentsByEquipmentCode).map(equipmentCode => (
            <tr key={equipmentCode}>
              <td>{equipmentCode}</td>
              <td>{componentsByEquipmentCode[equipmentCode].join(", ")}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export { Legend };
export default Legend;
