class Wiring {
  description: string;
  partNumber: string;
  enabled: boolean;
  bodyProtected: boolean;
  cardiacProtected: boolean;
  gpoCentres: number[];
  type: "active" | "neutral" | "earth";
  way: number;

  public toString(): string {
    return this.description;
  }
}

export default Wiring;
