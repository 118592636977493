const Types = {
  LOGIN_REQUEST: "@@auth/LOGIN_REQUEST",
  LOGIN_SUCCESS: "@@auth/LOGIN_SUCCESS",
  LOGIN_FAILURE: "@@auth/LOGIN_FAILURE",
  LOGOUT_REQUEST: "@@auth/LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "@@auth/LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "@@auth/LOGOUT_FAILURE",
};

export { Types };

export default Types;
