import { Definition } from "@app/models/component";
import React from "react";
import { TabularData } from "@app/containers";

const Components = () => (
  <TabularData
    type={Definition}
    endpoint="/components"
    query="{uuid,description,code,cost,updatedDate}"
    columns={[
      { key: "description", label: "Name" },
      { key: "code" },
      { key: "humanCost", label: "Cost" },
      { key: "humanUpdatedAt", label: "Last Updated" },
    ]}
    filter={{
      export: true,
      placeholder:
        "Enter a code, description, or short description to search for...",
      builder: (q: string) => `(name:${q})`,
    }}
  />
);

export { Components };
export default Components;
