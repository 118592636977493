import Action from "../types";
import Types from "./types";

const create = (): Action<null> => ({
  type: Types.CREATE_REQUEST,
  payload: null,
});

const createSuccess = <T>(resource: T): Action<T> => ({
  type: Types.CREATE_SUCCESS,
  payload: resource,
});

const createError = (): Action<null> => ({
  type: Types.CREATE_ERROR,
  payload: null,
});

const fetch = (): Action<null> => ({
  type: Types.FETCH_REQUEST,
  payload: null,
});

const fetchSuccess = <T>(resource: any): Action<T> => ({
  type: Types.FETCH_SUCCESS,
  payload: resource,
});

const fetchError = (): Action<null> => ({
  type: Types.FETCH_ERROR,
  payload: null,
});

const update = (): Action<null> => ({
  type: Types.UPDATE_REQUEST,
  payload: null,
});

const updateSuccess = <T>(resource: T): Action<T> => ({
  type: Types.UPDATE_SUCCESS,
  payload: resource,
});

const updateError = (): Action<null> => ({
  type: Types.UPDATE_ERROR,
  payload: null,
});

const deleteRequest = (): Action<null> => ({
  type: Types.DELETE_REQUEST,
  payload: null,
});

const deleteSuccess = (): Action<null> => ({
  type: Types.DELETE_SUCCESS,
  payload: null,
});

const deleteError = (): Action<null> => ({
  type: Types.DELETE_ERROR,
  payload: null,
});

const clear = (): Action<null> => ({
  type: Types.CLEAR,
  payload: null,
});

export {
  create,
  createSuccess,
  createError,
  fetch,
  fetchSuccess,
  fetchError,
  update,
  updateSuccess,
  updateError,
  deleteRequest,
  deleteSuccess,
  deleteError,
  clear,
};
