import { generateAttempt, generateSuccess } from "./actions";

import { Dispatch } from "redux";
import OEF from "@app/models/oef";
import Project from "@app/models/project";
import { download } from "@app/helpers/browser";
import { generate as generateOef } from "@app/services/oef";
import { log } from "@bespohk/lib";

const generate = (project: Project): any => (dispatch: Dispatch<any>): OEF => {
  dispatch(generateAttempt());
  const oef: OEF = generateOef(project);
  dispatch(generateSuccess(oef));

  log(oef);
  download(oef.asCsv, `${project.name}-oef-${Date.now()}.csv`, "text/csv");

  return oef;
};

type Operations = {
  generate: (project: Project) => OEF;
};

const operations: Operations = {
  generate,
};

export { operations, Operations };
