import {
  Button,
  ButtonBar,
  Field,
  Form,
  Heading,
  Wrapper,
} from "@bespohk/uikit/components";
import { Operations, operations } from "@app/state/ducks/resource/operations";
import React, { Fragment, useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";

import { Schema } from "yup";
import State from "@app/state";
import { isFunction } from "@bespohk/lib";
import { scroll } from "@app/helpers/browser";
import styles from "./styles.modules.css";
import { useOperations } from "@app/helpers/redux";
import { useSelector } from "react-redux";

type Fieldset = {
  name: string;
  label?: string;
  renderer: any;
  props?: any;
  additional?: any;
};

type FieldsetHeader = {
  title: string;
};

type Redux = {
  operations: any;
  reducer: string;
};

type OwnProps = {
  def: any;
  identifier: string;
  type: any;
  validate?: Schema<any>;
  fieldsets: (Fieldset | FieldsetHeader)[][];
  children?: React.ReactNode | ((resource: any) => React.ReactNode);
  actions?: React.ReactNode;
  endpoint: string;
  preValidateTransform?: Schema<any>;
  initialTransform?: Schema<any>;
  actionBarLayout?: "left" | "right";
  redirectBuilder?: any;
  query?: string;
  redux?: Redux; // Provides the ability to use a different duck
};

const propsParser = (props, value) => {
  if (!props) {
    return null;
  }
  const { options, ...rest } = props;
  if (isFunction(options) && value) {
    // If props are specified and options is a function, we're populating the
    // options for the field from the API
    rest.options = options(value);
  } else {
    rest.options = isFunction(options) ? [] : options;
  }
  delete rest.required;

  return rest;
};

const renderField = (field, values) => {
  const { name, label, props, title, additional } = field;

  if (title) {
    return (
      <Fragment key={title}>
        <Heading size="medium">{title}</Heading>
      </Fragment>
    );
  }

  const value = values[field.name];
  const processedProps = isFunction(props) ? props(values) : props;
  const parsedProps = propsParser(processedProps, value);
  const required = processedProps && processedProps.required;
  const processedAdditional = additional
    ? isFunction(additional)
      ? additional(values)
      : additional
    : null;

  return (
    <Field key={name} label={label} required={required}>
      {<field.renderer name={name} {...parsedProps} />}
      {processedAdditional}
    </Field>
  );
};

const newIdentifier = "new";

const ModelForm = ({
  def,
  type,
  validate,
  preValidateTransform,
  initialTransform,
  fieldsets,
  children,
  endpoint,
  identifier,
  query,
  actionBarLayout,
  actions,
  redirectBuilder,
  redux,
}: OwnProps) => {
  const params = useParams();
  const id = params[identifier];
  const isNew = id === newIdentifier;
  const reducer = redux ? redux.reducer : "resource";
  const internalOperations = redux ? redux.operations : operations;

  let resource = useSelector((state: State) => state[reducer].data);

  const { update, create, clear, fetch } = useOperations<Operations>(
    internalOperations,
  );

  useEffect(() => {
    if (isNew) {
      return;
    }
    let path = `${endpoint}/${id}`;
    if (query) {
      path += `?query=${query}`;
    }
    fetch(type, path);

    return () => {
      clear();
    };
  }, []);

  if (isNew && resource && resource[identifier]) {
    let pathToRedirect = `${endpoint}/${resource[identifier]}`;

    const url = new URL(window.location.href);
    const redirect = url.searchParams.get("redirect");
    if (redirect && redirectBuilder) {
      pathToRedirect = `${redirect}?${redirectBuilder(resource)}`;
    }

    return <Redirect to={pathToRedirect} />;
  }

  if (isNew && def) {
    resource = def;
  }

  return (
    <Form
      preValidateTransform={preValidateTransform}
      initialTransform={initialTransform}
      initial={resource}
      validate={validate}
      onSubmit={values => {
        const modelEndpoint = !isNew ? `${endpoint}/${id}` : endpoint;
        const submitOperation: any = !isNew ? update : create;
        return submitOperation(modelEndpoint, values, type);
      }}
    >
      {({ values }) => {
        return (
          <>
            {fieldsets.map((fieldset, index) => (
              <Wrapper grid key={index} className={styles.wrapper}>
                {fieldset.map(field =>
                  field ? renderField(field, values) : null,
                )}
              </Wrapper>
            ))}
            {typeof children === "function" ? children(resource) : children}
            <ButtonBar layout={actionBarLayout}>
              {actions}
              <Button
                label={isNew ? "Cancel" : "Back"}
                style="tertiary"
                action={() => history.back()}
              />
              <Button
                label={isNew ? "Create" : "Save"}
                type="submit"
                action={() => scroll()}
              />
            </ButtonBar>
          </>
        );
      }}
    </Form>
  );
};

ModelForm.defaultProps = {
  actionBarLayout: "right",
} as OwnProps;

export { ModelForm };
export default ModelForm;
