import {
  Operations,
  operations,
} from "@app/state/ducks/panel-builder/operations";
import React, { useState } from "react";

import { Input } from "@bespohk/uikit/components";
import Panel from "@app/models/panel";
import Project from "@app/models/project";
import styles from "./styles.modules.css";
import { useOperations } from "@app/helpers/redux";
import State from "@app/state";
import { useSelector } from "react-redux";

type OwnProps = {
  panel: Panel;
  project: Project;
  readonly?: boolean;
};

const lookupSelector = (state: State) => state.panelBuilder.lookups;

const Discount = ({ panel, project, readonly }: OwnProps) => {
  const [discount, setDiscount] = useState(
    panel.discount ? panel.discount : "0",
  );

  const lookups = useSelector(lookupSelector);

  const { updateDiscount } = useOperations<Operations>(operations);

  return (
    <Input
      type="number"
      className={styles.discount}
      name="discount"
      disabled={readonly}
      value={discount}
      onBlur={(name, value) => {
        updateDiscount(project, panel, parseFloat(value), lookups);
      }}
      onChange={(name, value) => {
        if (!value) {
          value = 0;
        }
        setDiscount(parseFloat(value));
      }}
    />
  );
};

export { Discount };
export default Discount;
