import { BooleanColumnRenderer } from "@app/components";
import PanelType from "@app/models/panel-type";
import React from "react";
import { TabularData } from "@app/containers";
const renderActive = (panelType: PanelType): React.ReactNode => (
  <BooleanColumnRenderer valid={panelType.active} />
);

const PanelTypes = () => (
  <TabularData
    type={PanelType}
    endpoint="/panel-types"
    identifier="id"
    filter={{
      placeholder: "Enter a name to search for...",
      builder: (q: string) => `(name: ${q})`,
    }}
    columns={[
      { key: "name" },
      { key: "description" },
      { key: "active", renderer: renderActive, width: "10%" },
    ]}
  />
);

export { PanelTypes };
export default PanelTypes;
