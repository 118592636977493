import * as yup from "yup";

import { Choice, Input } from "@bespohk/uikit/components";
import { choiceFromBoolean, choiceToBoolean } from "@app/helpers/form";

import { default as Model } from "@app/models/panel-type";
import { ModelForm } from "@app/containers";
import React from "react";

type OwnProps = {};

const PanelType = (_: OwnProps) => {
  return (
    <ModelForm
      identifier="id"
      endpoint="/panel-types"
      type={Model}
      initialTransform={yup.object().shape({
        active: choiceFromBoolean(),
        id: yup.number().strip(true),
      })}
      preValidateTransform={yup.object().shape({
        name: yup.string().trim(),
        shortName: yup.string().trim(),
        description: yup.string().trim(),
        active: choiceToBoolean(),
      })}
      validate={yup.object().shape({
        name: yup.string().required("Name is a required field."),
        shortName: yup.string().required("Short Name is a required field."),
        description: yup.string().required("Description is a required field."),
        active: yup.boolean(),
      })}
      fieldsets={[
        [
          {
            name: "name",
            renderer: Input,
            props: { required: true },
          },
          { name: "shortName", renderer: Input, props: { required: true } },
          {
            name: "subAssemblyPrefix",
            renderer: Input,
            props: { required: true },
          },
        ],
        [{ name: "description", renderer: Input, props: { required: true } }],
        [
          {
            name: "active",
            label: null,
            renderer: Choice,
            props: {
              options: [{ value: true, label: "Is visible in drawings" }],
            },
          },
        ],
      ]}
    />
  );
};

export { PanelType };
export default PanelType;
