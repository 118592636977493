const Types = {
  CREATE_REQUEST: "@@resource/CREATE_REQUEST",
  CREATE_SUCCESS: "@@resource/CREATE_SUCCESS",
  CREATE_ERROR: "@@resource/CREATE_ERROR",
  FETCH_REQUEST: "@@resource/FETCH_REQUEST",
  FETCH_SUCCESS: "@@resource/FETCH_SUCCESS",
  FETCH_ERROR: "@@resource/FETCH_ERROR",
  UPDATE_REQUEST: "@@resource/UPDATE_REQUEST",
  UPDATE_SUCCESS: "@@resource/UPDATE_SUCCESS",
  UPDATE_ERROR: "@@resource/UPDATE_ERROR",
  DELETE_REQUEST: "@@resource/DELETE_REQUEST",
  DELETE_SUCCESS: "@@resource/DELETE_SUCCESS",
  DELETE_ERROR: "@@resource/DELETE_ERROR",
  CLEAR: "@@resource/CLEAR",
};

export { Types };

export default Types;
