import {
  Operations as InclusionOperations,
  operations as inclusionOperations,
} from "@app/state/ducks/inclusion/operations";
import { Operations, operations } from "@app/state/ducks/project/operations";
import React, { useEffect } from "react";
import { Text, Wrapper, Button } from "@bespohk/uikit/components";

import Breakdown from "./breakdown";
import { Cover } from "./cover";
import Inclusion from "@app/models/inclusion";
import { Legend } from "./legend";
import Overview from "./overview";
import { Print } from "@app/components";
import Section from "./section";
import State from "@app/state";
import styles from "./styles.modules.css";
import { useOperations } from "@app/helpers/redux";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { usePrinting } from "@app/helpers/hooks";

import classNames from "classnames/bind";

const cx = classNames.bind(styles);

type OwnProps = {};

const Inclusions = (_: OwnProps) => {
  const { fetch } = useOperations<Operations>(operations);
  const { generate } = useOperations<InclusionOperations>(inclusionOperations);
  const { loading, data } = useSelector((state: State) => state.project);
  const [printing, print] = usePrinting();

  const inclusion: Inclusion = useSelector(
    (state: State) => state.inclusion.data,
  );

  const params = useParams();
  const projectUuid = params["projectUuid"];

  useEffect(() => {
    fetch(null, `/projects/${projectUuid}`);
  }, []);

  useEffect(() => {
    if (data && !loading) {
      generate(data);
    }
  }, [data]);

  if (!inclusion) {
    return null; // TODO: add loader
  }

  return (
    <>
      {!printing && (
        <Button
          label="Print"
          action={() =>
            print(
              `${data.salesOrderNumber}_${data.name}_Inclusions-${data.revision}`,
            )
          }
          className={cx({ print: true })}
        />
      )}
      <Print>
        <Cover inclusion={inclusion} />
        <Wrapper className={styles.wrapper}>
          <Section label="INCLUSIONS SHEET" center />
          <Overview inclusion={inclusion} />
          <Breakdown inclusion={inclusion} />
          <Legend inclusion={inclusion} />
          <Text className={styles.terms}>
            * Inclusions on ALL products are to be ESCO Series components unless
            specified otherwise. Whilst all care has been taken in the
            preparation of this sheet, any errors and omissions with
            take-off/sheet is NOT the responsibility of ESCO Industries,
            quantities/layouts/inclusions are the responsibility of others. The
            words 'provision only' indicate mounting facilities only will be
            provided, the supply and installation of the equipment is by others.
            Should Ordered Quantities vary to those quoted, we reserve the right
            to adjust pricing accordingly. Customer approval drawing lead times
            and manufacturing lead times to be confirmed upon receipt of
            purchase order. Up to two (2) sets of approval drawings have been
            allowed for in this price. No discount will be given if only one set
            used, and for every set required after the first two, a $200 ex GST
            will apply. Non account holders will be required to pay in full
            prior to manufacturing.
          </Text>
        </Wrapper>
      </Print>
    </>
  );
};

export { Inclusions };
export default Inclusions;
