import Component from "./component";
import { default as Model } from "@app/models/component";
import { gridRenderer } from "../helpers";

type OwnProps = {
  components: Model[];
};

const Components = ({ components }: OwnProps) =>
  gridRenderer(components, Component, "component");

export { Components };
export default Components;
