import Action from "../types";
import OEF from "@app/models/oef";
import Types from "./types";

const generateAttempt = (): Action<null> => ({
  type: Types.GENERATE_ATTEMPT,
  payload: null,
});

const generateSuccess = (oef: OEF): Action<OEF> => ({
  type: Types.GENERATE_SUCCESS,
  payload: oef,
});

export { generateAttempt, generateSuccess };
