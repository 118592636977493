import BodyProtected from "./body-protected";
import Panel from "@app/models/panel";
import Project from "@app/models/project";
import React from "react";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";
import { Overlay } from "./overlay";

const cx = classNames.bind(styles);

type OwnProps = {
  type: "customer" | "factory" | "construction";
  project: Project;
  panel: Panel;
};

const Approval = ({ type, panel, project }: OwnProps) => {
  return (
    <table className={cx({ table: true, approval: true, outer: true })}>
      <thead>
        <tr>
          <th colSpan={3}>Customer Signed Approval</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {type === "construction" ? "As-Built" : `${type} Approval Drawing`}
          </td>
          <td colSpan={2} className={cx({ lower: true })}>
            Must be approved & signed off with no changes before manufacturing
            can commence
          </td>
        </tr>
        <tr>
          <td>Name</td>
          <td>Date</td>
          <td>Signature</td>
        </tr>
        <tr>
          <td>&nbsp;</td>
          <td></td>
          <td>
            {type === "factory" && (
              <Overlay
                type={type}
                message={
                  panel.approved
                    ? "Approved"
                    : panel.deleted
                    ? "No longer required"
                    : project.isCad
                    ? "Superceded by CAD"
                    : ""
                }
              />
            )}
            <BodyProtected panel={panel} />
            {panel.panelType.name.toLowerCase().includes("sealed") && (
              <span className={cx("sealed")}>SEALED MSP</span>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export { Approval };
export default Approval;
