import { Icon, Icons } from "@bespohk/uikit/components";

import React from "react";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";

type OwnProps = {
  label: string;
  layout: "horizontal" | "vertical";
  isDrawing?: boolean;
};

const cx = classNames.bind(styles);

const Arrow = ({ label, layout, isDrawing }: OwnProps) => {
  const firstArrow: Icons =
    layout === "horizontal" ? Icons.CaretLeft : Icons.CaretUp;
  const lastArrow: Icons =
    layout === "horizontal" ? Icons.CaretRight : Icons.CaretDown;
  return (
    <div className={cx({ arrow: true, [layout]: true, isDrawing })}>
      <Icon name={firstArrow} className={cx({ chevron: true })} />
      <div className={cx({ label: true })}>{label}</div>
      <Icon name={lastArrow} className={cx({ chevron: true })} />
    </div>
  );
};

export { Arrow };
export default Arrow;
