import "@app/assets/css/vendor/reset.css";
import "@app/assets/css/base.css";

import { App } from "./app";
import React from "react";
import { render } from "react-dom";

import * as Sentry from "@sentry/browser";
import { log } from "@bespohk/lib";

Sentry.init({
  dsn: process.env.SENTRY_DSN,
});
log("Initialised Sentry", process.env.SENTRY_DSN);

const rootElement = document.getElementById("root");
document.addEventListener("DOMContentLoaded", () => {
  render(<App />, rootElement);
});
