import Approval from "@app/models/approval";
import { BooleanColumnRenderer } from "@app/components";
import React from "react";
import { TabularData } from "@app/containers";

type OwnProps = {};

const sentRenderer = (approval: Approval): React.ReactNode => (
  <BooleanColumnRenderer valid={approval.sent} />
);

const projectNameRenderer = (approval: Approval): React.ReactNode =>
  approval.project.name;

const sendDateRenderer = (approval: Approval): string => approval.humanSendAt;
const createdDateRenderer = (approval: Approval): string =>
  approval.humanCreatedAt;

const ScheduledApprovals = (_: OwnProps) => (
  <TabularData
    type={Approval}
    identifier={"id"}
    filter={false}
    endpoint="/projects/approvals"
    query="{id,sent,sendDate,createdDate,project{uuid,name}}"
    columns={[
      { key: "project", renderer: projectNameRenderer },
      { key: "sendDate", width: "20%", renderer: sendDateRenderer },
      { key: "sent", width: "10%", renderer: sentRenderer },
      { key: "createdDate", width: "20%", renderer: createdDateRenderer },
    ]}
  />
);

export { ScheduledApprovals };
export default ScheduledApprovals;
