import Document, { BOM, OrderLine } from "@app/models/bom";
import { zerofill } from "@app/helpers/strings";

import Project from "@app/models/project";
import { mapTerritoryToCode } from "@app/models/territory";

const formatDate = (d: Date): string =>
  `${d.getFullYear()}${zerofill(d.getMonth() + 1, 2)}${zerofill(
    d.getDate(),
    2,
  )}`;

const DEFAULT_ITEM_GROUP = "PAMF";
const DEFAULT_WORK_CENTRE = "MP";
const DEFAULT_WAREHOUSE = "SYD";
const DEFAULT_OP_CODE = "ZZ";

const clean = (value: string, force = true): string => {
  return (value && value.toString().indexOf("&") >= 0) || force
    ? `<![CDATA[${value}]]>`
    : value;
};

const trimPronto = (value: string) => {
  if (!value) {
    return "";
  }
  return value.substring(0, 19);
};

const generate = (project: Project): Document => {
  const document: Document = new Document();

  document.documentHeader = {
    docType: "MORRIS Quotation",
    docId: project.uuid,
    docRevisionNo: project.revision,
    docQuote: clean(project.salesOrderNumber, false),
  };

  document.project = {
    projectCode: clean(project.code, false),
    projectName: clean(trimPronto(project.name), false),
    projectPriority: project.priority,
  };

  const orderLines: OrderLine[] = [];

  document.order = {
    orderHeader: {
      orderValidTo: formatDate(project.validUntilDate),
      orderDate: formatDate(project.updatedDate),
      orderRep: clean(project.bdm.code, false),
      orderWarehouse: DEFAULT_WAREHOUSE,
      orderTerritory: mapTerritoryToCode(project.customer.state),
      orderReference: clean(trimPronto(project.purchaseOrderNumber), false),
      customer: {
        customerCode: project.customer.accountCode,
        customerName: clean(project.customer.businessName, false),
      },
      delivery: {
        deliveryStreet1: clean(project.customer.address, false),
        deliveryStreet2: "",
        deliverySuburb: clean(project.customer.suburb),
        deliveryState: mapTerritoryToCode(project.customer.state),
        deliveryCountry: clean(project.customer.country),
        deliveryPostcode: project.customer.postcode,
      },
    },
    orderLines: {
      line: orderLines,
    },
  };

  const boms: BOM[] = [];
  document.boms = { bom: boms };

  let lineNo = 1;

  const { activePanelSets: panelSets } = project;

  panelSets.forEach(panelSet => {
    // Panel Set
    const { panel, backplate } = panelSet;

    const panelSetPartNumber = `${panel.panelType.shortName} PS ${panel.mspReference}`;

    // Order Lines: Panel

    orderLines.push({
      lineNo: lineNo,
      stockCode: panelSet.panelPartNumber,
      customerPartNo: clean(panel.mspReference, false),
      lineDescription: clean(panelSetPartNumber, false),
      uom: "EACH",
      orderQuantity: panel.quantity,
      discountRate: panel.expectedPrice ? 0 : panel.discount,
      itemPrice: panelSet.totalCosts.panel.unit,
      lineTotal: panelSet.discountedTotalCosts.panel.total,
    });

    // Order Lines: Wallbox

    const wallboxPartNumber = `${panel.panelType.shortName} WB ${panel.mspReference}`;

    orderLines.push({
      lineNo: lineNo + 1,
      stockCode: backplate.partNumber,
      customerPartNo: clean(panel.mspReference, false),
      lineDescription: clean(wallboxPartNumber, false),
      uom: "EACH",
      orderQuantity: panel.quantity,
      discountRate: panel.expectedPrice ? 0 : panel.discount,
      itemPrice: panelSet.totalCosts.backplate.unit,
      lineTotal: panelSet.discountedTotalCosts.backplate.total,
    });

    lineNo += 2;

    // BOM

    const { panelSubAssemblies: panelSetSubAssemblies } = panelSet;
    const totalPanelSetSubAssemblies = panelSetSubAssemblies.length || 1; // Always at least 1 assembly

    const panelSetBom: BOM = {
      bomHeader: {
        bomItemCode: panel.partNumber,
        bomDescription: clean(panelSetPartNumber, false),
        bomDrawingNo: panel.yyNumber,
        bomItemGroup: DEFAULT_ITEM_GROUP,
      },
      bomComponents: {
        component: [],
      },
      bomRouting: {
        route: [
          {
            rteSeqNo: 1,
            rteOpCode: panel.partNumber,
            rteWorkCentre: DEFAULT_WORK_CENTRE,
            rteQuantityPer: panel.quantity,
            rteLabourTime: totalPanelSetSubAssemblies * 2 + 5,
            rteOverheadTime: 10,
            rteNotes: "ASSEMBLE COMPLETE",
          },
        ],
      },
    };

    let j = 1;

    const { components } = panel;

    // BOM: Components
    components.forEach(component => {
      if (component.isGas) {
        return; // Ignore gas from BOM
      }
      const itemCode = component.bomPartNumber;
      const existingBomComponent = panelSetBom.bomComponents.component.find(
        bomComponent => bomComponent.cmpItemCode === itemCode,
      );
      if (existingBomComponent) {
        existingBomComponent.cmpQuantity++;
        return;
      }

      panelSetBom.bomComponents.component.push({
        cmpSeqNo: j,
        cmpItemCode: itemCode,
        cmpDescription: component.bomDescription,
        cmpQuantity: 1,
      });

      j++;
    });

    // Front Plate as component of PanelSet BOM

    panelSetBom.bomComponents.component.push({
      cmpSeqNo: j,
      cmpItemCode: panel.punchingLayoutJoined,
      cmpDescription: "Front Plate",
      cmpQuantity: 1,
    });

    // BOM: Hbars

    if (panel.hbars.length) {
      const hbar = panel.hbars[0];
      panelSetBom.bomComponents.component.push({
        cmpSeqNo: j,
        cmpItemCode: hbar.partNumber,
        cmpDescription: hbar.description,
        cmpQuantity: panel.hbars.length,
      });
      j++;
    }

    // BOM: Grids

    /*
    // Grids should not be displayed in the BOM with new Grid kits
    panelSet.gridContainer.grids.forEach(grid => {
      const itemCode = grid.code;
      const existingBomComponent = panelSetBom.bomComponents.component.find(
        bomComponent => bomComponent.cmpItemCode === itemCode,
      );
      if (existingBomComponent) {
        existingBomComponent.cmpQuantity++;
        return;
      }
      panelSetBom.bomComponents.component.push({
        cmpSeqNo: j,
        cmpItemCode: grid.code,
        cmpDescription: grid.description,
        cmpQuantity: 1,
      });
      j++;
    });
    */

    // BOM: Panel Sub Assemblies

    panelSetSubAssemblies.forEach(subAssembly => {
      panelSetBom.bomComponents.component.push({
        cmpSeqNo: j,
        cmpItemCode: subAssembly.itemCode,
        cmpDescription: subAssembly.description,
        cmpQuantity: subAssembly.quantity,
      });
      j++;
    });

    boms.push(panelSetBom);

    // BOM: Front plate

    j = 1;

    const { frontPlateSubAssemblies: frontPlateSubAssemblies } = panelSet;

    const frontPlateBom: BOM = {
      bomHeader: {
        bomItemCode: panel.punchingLayoutJoined,
        bomDescription: "Front Plate",
        bomDrawingNo: panel.yyNumber,
        bomItemGroup: DEFAULT_ITEM_GROUP,
      },
      bomComponents: {
        component: [],
      },
      bomRouting: {
        route: [
          {
            rteSeqNo: 1,
            rteOpCode: DEFAULT_OP_CODE,
            rteWorkCentre: DEFAULT_WORK_CENTRE,
            rteQuantityPer: 1,
            rteLabourTime: panel.components.reduce((labourTime, component) => {
              labourTime += component.labourTime;
              return labourTime;
            }, 0),
            rteOverheadTime: 10,
            rteNotes: "MANUFACTURE AS PER DRAWING",
          },
        ],
      },
    };

    frontPlateSubAssemblies.forEach(subAssembly => {
      frontPlateBom.bomComponents.component.push({
        cmpSeqNo: j,
        cmpItemCode: subAssembly.itemCode,
        cmpDescription: subAssembly.description,
        cmpQuantity: subAssembly.quantity,
      });
      j++;
    });

    boms.push(frontPlateBom);

    // BOM: Wallboxes

    if (backplate.isStandardPart) {
      return; // Ignore standard part numbers from BOM
    }

    j = 1;

    const { subAssemblies: wallboxSubAssemblies } = backplate;
    const totalWallboxSubAssemblies = wallboxSubAssemblies.length || 1; // Always at least 1 assembly

    const wallboxBom: BOM = {
      bomHeader: {
        bomItemCode: backplate.partNumber,
        bomDescription: wallboxPartNumber,
        bomDrawingNo: panel.yyNumber,
        bomItemGroup: DEFAULT_ITEM_GROUP,
      },
      bomComponents: {
        component: [],
      },
      bomRouting: {
        route: [
          {
            rteSeqNo: 1,
            rteOpCode: DEFAULT_OP_CODE,
            rteWorkCentre: DEFAULT_WORK_CENTRE,
            rteQuantityPer: 1,
            rteLabourTime: totalWallboxSubAssemblies * 1 + 4, // 1min per + 4 minutes
            rteOverheadTime: 0,
            rteNotes: "ASSEMBLE COMPLETE",
          },
        ],
      },
    };

    // Wallboxes are no longer included in the BOM, only the sub-assemblies
    // const { wallboxes } = backplate;
    // wallboxes.forEach(wallbox => {
    //   if (wallbox.gasOnly) {
    //     return;
    //   }
    //   const itemCode = wallbox.code;
    //   const existingBomComponent = wallboxBom.bomComponents.component.find(
    //     bomComponent => bomComponent.cmpItemCode === itemCode,
    //   );
    //   if (existingBomComponent) {
    //     existingBomComponent.cmpQuantity++;
    //     return;
    //   }
    //   wallboxBom.bomComponents.component.push({
    //     cmpSeqNo: j,
    //     cmpItemCode: itemCode,
    //     cmpDescription: wallbox.humanDescription,
    //     cmpQuantity: 1,
    //   });
    //   j++;
    // });

    // BOM: Wallbox Sub Assemblies
    wallboxSubAssemblies.forEach(subAssembly => {
      wallboxBom.bomComponents.component.push({
        cmpSeqNo: j,
        cmpDescription: clean(subAssembly.description),
        cmpItemCode: subAssembly.itemCode,
        cmpQuantity: subAssembly.quantity,
      });
      j++;
    });

    boms.push(wallboxBom);
  });

  return document;
};

export { generate };
