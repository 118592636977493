import Component, { Data, Overrides } from "@app/models/component";
import Panel, {
  Definition as PanelDefinition,
  WritePanel,
} from "@app/models/panel";
import Types, { PanelModifier } from "./types";

import Action from "../types";
import { AnyAction } from "redux";
import ClampRail from "@app/models/clamp-rail";
import Divider from "@app/models/divider";
import Engraving from "@app/models/engraving";
import { Definition as Grid } from "@app/models/grid";
import Hbar from "@app/models/hbar";
import Material from "@app/models/material";
import PanelType from "@app/models/panel-type";
import PopRivet from "@app/models/pop-rivet";
import Project from "@app/models/project";
import Screw from "@app/models/screw";
import Series from "@app/models/series";
import Strapping from "@app/models/strapping";
import { Definition as Wallbox } from "@app/models/wallbox";
import Wiring from "@app/models/wiring";
import PlateFinish from "@app/models/plate-finish";

// Action return types

type ModifyComponentAction = Action<{
  component: Component;
  index: number;
  newIndex: number;
  overrides: Overrides;
}>;
type AddComponentAction = Action<{
  component: Component;
  series: Series;
  panelType: PanelType;
  plateFinish: PlateFinish;
  index?: number;
  overrides?: Overrides;
}>;
type RemoveComponentAction = Action<number>;
type PanelAction = Action<{
  panel: Panel;
  modifier: PanelModifier;
}>;
type DiscountAction = Action<{
  discount: number;
}>;
type Lookups = {
  wallboxes: Wallbox[];
  grids: Grid[];
  hbars: Hbar[];
  spacers: Component[];
  data: Data[];
  clampRails: ClampRail[];
  screws: Screw[];
  popRivets: PopRivet[];
  strappings: Strapping[];
  engravings: Engraving[];
  materials: Material[];
  wirings: Wiring[];
  dividers: Divider[];
  plateFinishes: PlateFinish[];
  panelTypes: PanelType[];
  series: Series[];
};
type LookupAction = Action<Lookups>;
type SaveAction = Action<WritePanel>;
type DeleteAction = Action<{ project: Project; panel: Panel }>;
type UpdateAction = Action<{ project: Project; panel: Panel }>;
type ChangeGPOCenterAction = Action<{ center: number; components: string[] }>;

// Lookup actions

const updateLookups = (
  wallboxes: Wallbox[],
  grids: Grid[],
  hbars: Hbar[],
  spacers: Component[],
  data: Data[],
  clampRails: ClampRail[],
  screws: Screw[],
  popRivets: PopRivet[],
  strappings: Strapping[],
  engravings: Engraving[],
  materials: Material[],
  wirings: Wiring[],
  dividers: Divider[],
  plateFinishes: PlateFinish[],
  panelTypes: PanelType[],
  series: Series[],
): LookupAction => ({
  type: Types.UPDATE_LOOKUPS,
  payload: {
    wallboxes,
    grids,
    hbars,
    spacers,
    data,
    clampRails,
    screws,
    popRivets,
    strappings,
    engravings,
    materials,
    wirings,
    dividers,
    plateFinishes,
    panelTypes,
    series,
  },
});

// Panel actions

const setWorkingSuccess = (
  panel: Panel,
  modifier: PanelModifier = null,
): PanelAction => ({
  type: Types.SET_WORKING_SUCCESS,
  payload: {
    panel,
    modifier,
  },
});

const setWorkingRequest = (): AnyAction => ({
  type: Types.SET_WORKING_REQUEST,
});

const changeDiscount = (discount: number): DiscountAction => ({
  type: Types.UPDATE_DISCOUNT,
  payload: {
    discount,
  },
});

const createRequest = (panel: WritePanel): SaveAction => ({
  type: Types.CREATE_REQUEST,
  payload: panel,
});

const createSuccess = (panel: PanelDefinition): any => ({
  type: Types.CREATE_SUCCESS,
  payload: panel,
});

const createError = (): any => ({
  type: Types.CREATE_ERROR,
  payload: null,
});

const updateRequest = (panel: WritePanel): SaveAction => ({
  type: Types.UPDATE_REQUEST,
  payload: panel,
});

const updateSuccess = (panel: Panel, project: Project): UpdateAction => ({
  type: Types.UPDATE_SUCCESS,
  payload: {
    panel,
    project,
  },
});

const updateError = (): any => ({
  type: Types.UPDATE_ERROR,
  payload: null,
});

const deleteRequest = (project: Project, panel: Panel): DeleteAction => ({
  type: Types.DELETE_REQUEST,
  payload: {
    project,
    panel,
  },
});

const deleteSuccess = (project: Project, panel: Panel): DeleteAction => ({
  type: Types.DELETE_SUCCESS,
  payload: {
    project,
    panel,
  },
});

const changeGPOCenterRequest = (
  center: number,
  components: string[],
): ChangeGPOCenterAction => ({
  type: Types.GPO_CENTER_CHANGE_REQUEST,
  payload: {
    center,
    components,
  },
});

const changeGPOCenterSuccess = (
  oldComponentUuids: string[],
  components: Component[],
): any => ({
  type: Types.GPO_CENTER_CHANGE_SUCCESS,
  payload: {
    oldComponentUuids,
    components,
  },
});

// Component actions

const addComponent = (
  component: Component,
  series: Series,
  panelType: PanelType,
  plateFinish: PlateFinish,
  index?: number,
  overrides?: Overrides,
): AddComponentAction => ({
  type: Types.ADD_COMPONENT,
  payload: {
    component,
    series,
    panelType,
    plateFinish,
    index,
    overrides,
  },
});

const replaceComponent = (
  component: Component,
  series: Series,
  panelType: PanelType,
  plateFinish: PlateFinish,
  index?: number,
): AddComponentAction => ({
  type: Types.REPLACE_COMPONENT,
  payload: {
    component,
    series,
    panelType,
    plateFinish,
    index,
  },
});

const removeComponent = (index: number): RemoveComponentAction => ({
  type: Types.REMOVE_COMPONENT,
  payload: index,
});

const modifyComponent = (
  component: Component,
  index: number,
  newIndex: number,
  overrides: Overrides,
): ModifyComponentAction => ({
  type: Types.MODIFY_COMPONENT,
  payload: { index, newIndex, component, overrides },
});

const clearComponents = (): Action<null> => ({
  type: Types.CLEAR_COMPONENTS,
  payload: null,
});

const clearBuilder = (): Action<null> => ({
  type: Types.CLEAR,
  payload: null,
});

export {
  updateLookups,
  setWorkingRequest,
  setWorkingSuccess,
  addComponent,
  removeComponent,
  modifyComponent,
  clearComponents,
  PanelAction,
  LookupAction,
  AddComponentAction,
  ModifyComponentAction,
  RemoveComponentAction,
  DiscountAction,
  createRequest,
  createSuccess,
  createError,
  updateRequest,
  updateSuccess,
  updateError,
  deleteRequest,
  deleteSuccess,
  changeDiscount,
  clearBuilder,
  changeGPOCenterRequest,
  changeGPOCenterSuccess,
  replaceComponent,
  Lookups,
};
