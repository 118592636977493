import Types, { State } from "./types";

import { Action } from "./actions";

const initialState: State<any, any> = {
  loading: null,
  data: {
    count: 0,
    results: [],
  },
  // klass: null
};

const reducer = (
  state = initialState,
  action: Action<any, any>,
): State<any, any> => {
  const { type, payload } = action;
  let loading = null;
  let data = state.data;

  switch (type) {
    case Types.FETCH_REQUEST:
      loading = true;
      data = payload;
      break;

    case Types.FETCH_SUCCESS:
    case Types.FETCH_ERROR:
      loading = false;
      data = payload;
      break;

    case Types.CLEAR:
      loading = false;
      data = payload;
      break;

    default:
      loading = null;
      break;
  }

  return {
    loading,
    data,
  };
};

export { reducer };

export default reducer;
