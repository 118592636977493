import React from "react";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";

const cx = classNames.bind(styles);

type OwnProps = {
  position: number;
};

const Divider = ({ position }: OwnProps) => {
  return (
    <div className={cx({ divider: true })} style={{ left: `${position}px` }} />
  );
};

export { Divider };
export default Divider;
