import {
  AddComponentAction,
  DiscountAction,
  LookupAction,
  ModifyComponentAction,
  PanelAction,
  RemoveComponentAction,
} from "./actions";
import { Lookups } from "./actions";
import Types, { InternalState } from "./types";

import Component from "@app/models/component";
import Panel from "@app/models/panel";
import { generate } from "@app/services/panel-set";
import { last, rehydrate } from "@bespohk/lib";
import { peek } from "@app/helpers/components";

const defaultPanel = (lookups: Lookups): Panel => {
  return rehydrate(Panel, {
    components: [],
    discount: 0,
    protection: "rcd_protected", // Protection.rcd_protected,
    protectionOther: "body_protected", //ProtectionOther.body_protected,
    plateFinish: lookups.plateFinishes.find(
      finish => finish.name.toLowerCase() === "stainless steel",
    ),
    panelType: lookups.panelTypes.find(
      type => type.name.toLowerCase() === "alphamax",
    ),
    series: lookups.series.find(
      series_ => series_.name.toLowerCase() === "b series",
    ),
    gpoCentre: 50,
  });
};

const initialState: InternalState = {
  loading: true,
  panel: null,
  panelSet: null,
  lookups: {
    hbars: [],
    wallboxes: [],
    grids: [],
    spacers: [],
    data: [],
    clampRails: [],
    screws: [],
    popRivets: [],
    strappings: [],
    engravings: [],
    materials: [],
    wirings: [],
    dividers: [],
    plateFinishes: [],
    panelTypes: [],
    series: [],
  },
};

const cleanComponent = (component: Component) => {
  component.showEngravedLine = undefined;
  component.showEngravedLineBefore = undefined;
};

const fixHbars = (
  components: Component[],
  component: Component,
  index: number,
) => {
  if (component.fixed) {
    return;
  }
  if (component.showHbar) {
    const [, siblingComponent] = peek(components, index);
    if (siblingComponent) {
      siblingComponent.fixed = true;
      siblingComponent.showHbar = true;
    }
    component.showHbar = false;
  }
};

const fixWallboxes = (
  components: Component[],
  component: Component,
  index: number,
) => {
  if (component.fixed) {
    return;
  }
  if (component.forcedWallboxEnd) {
    const [, siblingComponent] = peek(components, index);
    if (siblingComponent) {
      siblingComponent.fixed = true;
      siblingComponent.forcedWallboxEnd = true;
    }
    component.forcedWallboxEnd = false;
  }
};

const reducer = (
  state = initialState,
  action:
    | PanelAction
    | LookupAction
    | AddComponentAction
    | RemoveComponentAction
    | DiscountAction
    | ModifyComponentAction,
): InternalState => {
  const { type } = action;
  let { lookups, panel } = state;
  let loading = false;

  switch (type) {
    case Types.UPDATE_LOOKUPS:
      lookups = (action as LookupAction).payload;
      break;

    case Types.SET_WORKING_REQUEST:
      loading = true;
      break;

    case Types.SET_WORKING_SUCCESS: {
      const { modifier } = (action as PanelAction).payload;
      let workingPanel = (action as PanelAction).payload.panel;
      if (!workingPanel) {
        workingPanel = defaultPanel(lookups);
      }
      if (modifier === "mirror" || modifier === "copy" || modifier === "new") {
        if (modifier === "new") {
          workingPanel.components = [];
        }
        if (modifier === "mirror") {
          let components: Component[];
          if (workingPanel.isVertical) {
            // Convert to rows, then reverse the rows
            const rows: Component[][] = workingPanel.components.reduce(
              (p, c) => {
                if (c.rowStart) {
                  p.push([]);
                }
                c.rowStart = false;
                p[p.length - 1].push(c);
                return p;
              },
              [[]],
            );
            components = rows.reduce((p, components) => {
              const component: Component = last(components);
              const reversedComponents = components.reverse();
              component.rowStart = true;
              p.push(...reversedComponents);
              return p;
            }, []);
          } else {
            workingPanel.components.forEach((component, index) => {
              fixHbars(workingPanel.components, component, index);
            });
            components = workingPanel.components.reverse();
          }
          components.forEach(component => component.reverseData());
          workingPanel.components = components;
          components.forEach((component, index) => {
            cleanComponent(component);
            fixWallboxes(components, component, index);
          });
        }
        workingPanel = rehydrate(Panel, {
          ...workingPanel,
          uuid: null,
          quantity: 1,
          mspReference: null,
        });
      }
      loading = false;
      panel = workingPanel;
      break;
    }

    case Types.GPO_CENTER_CHANGE_SUCCESS: {
      const { components, oldComponentUuids } = action.payload as any;
      oldComponentUuids.forEach((uuid, index) => {
        if (!uuid) {
          return;
        }
        const oldIndex = panel.components.findIndex(
          component => component.uuid === uuid,
        );
        if (oldIndex > -1 && components[index]) {
          panel.components[oldIndex] = components[index];
        }
      });
      break;
    }

    case Types.UPDATE_DISCOUNT: {
      const { discount } = (action as DiscountAction).payload;
      panel.discount = discount;
      break;
    }

    case Types.CLEAR_COMPONENTS: {
      panel.components = [];
      break;
    }

    case Types.CLEAR: {
      return {
        loading: false,
        panel: defaultPanel(lookups),
        panelSet: null,
        lookups,
      };
    }

    case Types.ADD_COMPONENT: {
      const payload = (action as AddComponentAction).payload;
      const {
        component,
        series,
        panelType,
        plateFinish,
        index,
        overrides,
      } = payload;
      const newComponent: Component = rehydrate(Component, { ...component });
      // const newPanel = { ...panel, component }; // Include component in payload to retain in form
      if (!panel.series && series) {
        panel.series = series;
      }
      if (!panel.panelType && panelType) {
        panel.panelType = panelType;
      }
      if (!panel.plateFinish && plateFinish) {
        panel.plateFinish = plateFinish;
      }
      if (overrides) {
        Object.keys(overrides).forEach(override => {
          newComponent[override] = overrides[override];
        });
      }
      if (index > -1) {
        panel.components.splice(index, 0, newComponent);
      } else {
        panel.components.push(newComponent);
      }
      break;
    }

    case Types.REPLACE_COMPONENT: {
      const payload = (action as AddComponentAction).payload;
      const { component, series, panelType, plateFinish, index } = payload;
      const newComponent: Component = rehydrate(Component, { ...component });
      // const newPanel = { ...panel, component }; // Include component in payload to retain in form
      if (!panel.series && series) {
        panel.series = series;
      }
      if (!panel.panelType && panelType) {
        panel.panelType = panelType;
      }
      if (!panel.plateFinish && plateFinish) {
        panel.plateFinish = plateFinish;
      }
      const existingComponent = panel.components[index];
      newComponent.rowStart = existingComponent.rowStart;
      newComponent.forcedWallboxEnd = existingComponent.forcedWallboxEnd;
      newComponent.showHbar = existingComponent.showHbar;
      newComponent.showEngravedLineBefore =
        existingComponent.showEngravedLineBefore;
      panel.components.splice(index, 1);
      panel.components.splice(index, 0, newComponent);
      break;
    }

    case Types.REMOVE_COMPONENT: {
      const number = (action as RemoveComponentAction).payload;
      panel.components.splice(number, 1);
      break;
    }

    case Types.MODIFY_COMPONENT: {
      const payload = (action as ModifyComponentAction).payload;
      const { index, newIndex, component, overrides } = payload;
      if (overrides) {
        Object.keys(overrides).forEach(override => {
          component[override] = overrides[override];
        });
      }
      if (index !== newIndex) {
        if (component.rowStart) {
          component.rowStart = false;
          panel.components[newIndex].rowStart = true;
        }
        panel.components.splice(index, 1);
        panel.components.splice(newIndex, 0, component);
      } else {
        panel.components[index] = component;
      }
      break;
    }

    default:
      return state;
  }

  const panelSet = panel ? generate(panel, lookups) : null;

  return {
    loading,
    panelSet,
    panel,
    lookups,
  };
};

export { reducer };

export default reducer;
