import { Lookups } from "./actions";

import Panel from "@app/models/panel";
import PanelSet from "@app/models/panel-set";

const Types = {
  UPDATE_LOOKUPS: "@@panel-builder/UPDATE_LOOKUPS",
  SET_WORKING_REQUEST: "@@panel-builder/SET_WORKING_REQUEST",
  SET_WORKING_SUCCESS: "@@panel-builder/SET_WORKING_SUCCESS",
  ADD_COMPONENT: "@@panel/ADD_COMPONENT",
  INSERT_COMPONENT: "@@panel-builder/INSERT_COMPONENT",
  REMOVE_COMPONENT: "@@panel-builder/REMOVE_COMPONENT",
  MODIFY_COMPONENT: "@@panel-builder/MODIFY_COMPONENT",
  MOVE_COMPONENT: "@@panel-builder/MOVE_COMPONENT",
  CLEAR_COMPONENTS: "@@panel-builder/CLEAR_COMPONENTS",
  REPLACE_COMPONENT: "@@panel-builder/REPLACE_COMPONENT",
  UPDATE_DISCOUNT: "@@panel-builder/UPDATE_DISCOUNT",
  CREATE_REQUEST: "@@panel-builder/CREATE_REQUEST",
  CREATE_SUCCESS: "@@panel-builder/CREATE_SUCCESS",
  CREATE_ERROR: "@@panel-builder/CREATE_ERROR",
  UPDATE_REQUEST: "@@panel-builder/UPDATE_REQUEST",
  UPDATE_SUCCESS: "@@panel-builder/UPDATE_SUCCESS",
  UPDATE_ERROR: "@@panel-builder/UPDATE_ERROR",
  DELETE_REQUEST: "@@panel-builder/DELETE_REQUEST",
  DELETE_SUCCESS: "@@panel-builder/DELETE_SUCCESS",
  DELETE_ERROR: "@@panel-builder/DELETE_ERROR",
  GPO_CENTER_CHANGE_REQUEST: "@@panel-builder/GPO_CENTER_CHANGE_REQUEST",
  GPO_CENTER_CHANGE_SUCCESS: "@@panel-builder/GPO_CENTER_CHANGE_SUCCESS",
  CLEAR: "@@panel-builder/CLEAR",
};

type InternalState = {
  loading: boolean;
  panel: Panel;
  panelSet: PanelSet;
  lookups: Lookups;
};

type PanelModifier = "mirror" | "copy" | "new";

export { Types, InternalState, PanelModifier };

export default Types;
