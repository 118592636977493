import {
  ApiErrors,
  get,
  del as httpDel,
  patch,
  post,
  put,
} from "@app/helpers/api";
import Component, { Data, Overrides } from "@app/models/component";
import Panel, { Definition, WritePanel, Errors } from "@app/models/panel";
import {
  addComponent as addComponentAction,
  changeDiscount,
  clearBuilder,
  clearComponents as clearComponentsAction,
  replaceComponent as replaceComponentAction,
  createError,
  createRequest,
  createSuccess,
  deleteRequest,
  deleteSuccess,
  modifyComponent as modifyComponentAction,
  removeComponent as removeComponentAction,
  setWorkingRequest,
  setWorkingSuccess,
  updateError,
  updateLookups as updateLookupsAction,
  updateRequest,
  updateSuccess,
  changeGPOCenterSuccess,
  changeGPOCenterRequest,
} from "./actions";
import { error, rehydrate, last } from "@bespohk/lib";

import ClampRail from "@app/models/clamp-rail";
import { Dispatch } from "redux";
import Divider from "@app/models/divider";
import Engraving from "@app/models/engraving";
import { Definition as Grid } from "@app/models/grid";
import Hbar from "@app/models/hbar";
import { Lookups } from "@app/services/types";
import Material from "@app/models/material";
// import { Pagination } from "../types";
import { PanelModifier } from "./types";
import PanelSet from "@app/models/panel-set";
import PanelType from "@app/models/panel-type";
import PopRivet from "@app/models/pop-rivet";
import Project from "@app/models/project";
import Screw from "@app/models/screw";
import Series from "@app/models/series";
import Strapping from "@app/models/strapping";
import { Definition as Wallbox } from "@app/models/wallbox";
import Wiring from "@app/models/wiring";
import { operations as alertOperations } from "../alerts/operations";
import { generate } from "@app/services/panel-set";
import { operations as projectOperations } from "../project/operations";
import { fetchSuccess } from "../project/actions";
import PlateFinish from "@app/models/plate-finish";
import { isPan } from "@app/helpers/components";
import { ServiceType } from "@app/models/type";

const { add } = alertOperations;

// Panel operations

const setWorking = (panel: Panel, modifier: PanelModifier = null): any => (
  dispatch: Dispatch<any>,
) => {
  if (panel) {
    add("info", `Switched to ${panel.mspReference}`, 3)(dispatch);
  }
  dispatch(setWorkingRequest());
  /*
    TODO:  Probably a better way than to call 13 endpoints, figure out how
    to cache this, look into shared state

    Pagination<Hbar>,
    Pagination<Grid>,
    Pagination<Wallbox>,
    Pagination<Component>,
    Pagination<Data>,
    Pagination<ClampRail>,
    Pagination<Screw>,
    Pagination<PopRivet>,
    Pagination<Strapping>,
    Pagination<Engraving>,
    Pagination<Material>,
    Pagination<Wiring>,
    Pagination<Divider>

  */
  return Promise.all<any>([
    get("/hbars?limit=500", Hbar),
    get("/grids?limit=500", Grid),
    get("/wallboxes?limit=500", Wallbox),
    get("/components?query=(enabled:True,code:spacer){*}&limit=50", Component),
    get("/components/datas?limit=500", Data),
    get("/clamp-rails?query=(enabled:True){*}&limit=500", ClampRail),
    get("/screws?query=(enabled:True){*}&limit=500", Screw),
    get("/pop-rivets?query=(enabled:True){*}&limit=500", PopRivet),
    get("/strappings?query=(enabled:True){*}&limit=500", Strapping),
    get("/engravings?query=(enabled:True){*}&limit=500", Engraving),
    get("/materials?query=(enabled:True){*}&limit=500", Material),
    get("/wirings?query=(enabled:True){*}&limit=500", Wiring),
    get("/dividers?query=(enabled:True){*}&limit=500", Divider),
    get("/plate-finishes?limit=500", PlateFinish),
    get("/panel-types?limit=500", PanelType),
    get("/series?limit=500", Series),
  ]).then(data => {
    const [
      hbars,
      grids,
      wallboxes,
      spacers,
      componentData,
      clampRails,
      screws,
      popRivets,
      strappings,
      engravings,
      materials,
      wirings,
      dividers,
      plateFinishes,
      panelTypes,
      series,
    ] = data;

    dispatch(
      updateLookupsAction(
        wallboxes.results,
        grids.results,
        hbars.results,
        spacers.results,
        componentData.results,
        clampRails.results,
        screws.results,
        popRivets.results,
        strappings.results,
        engravings.results,
        materials.results,
        wirings.results,
        dividers.results,
        plateFinishes.results,
        panelTypes.results,
        series.results,
      ),
    );
    dispatch(setWorkingSuccess(panel, modifier));
  });
};

const create = (data: WritePanel, project: Project, lookups: Lookups): any => (
  dispatch: Dispatch<any>,
) => {
  dispatch(createRequest(data));
  return post(`/projects/${data.project}/panels`, data, Definition)
    .then((panel: Definition) => {
      add(
        "success",
        `Created ${panel.mspReference} successfully.`,
        10,
      )(dispatch);
      const newPanel: Panel = rehydrate(Panel, panel);
      const panelSet: PanelSet = generate(newPanel, lookups);
      projectOperations.addPanel(project, newPanel, panelSet)(dispatch);
      dispatch(createSuccess(panel));
      return panel;
    })
    .catch((errors: ApiErrors) => {
      error(errors);
      dispatch(createError());
      throw errors;
    });
};

const patchProject = (
  project: Project,
  panel: Definition,
  lookups: Lookups,
) => {
  const updatedPanel = rehydrate(Panel, panel);
  const panelSet: PanelSet = generate(updatedPanel, lookups);
  const index = project.panelSets.findIndex(
    panelSet_ => panelSet_.panel.uuid === panelSet.panel.uuid,
  );
  project.panelSets[index] = panelSet;

  return [updatedPanel, project];
};

const update = (
  panel: Panel,
  data: WritePanel,
  project: Project,
  lookups: Lookups,
): any => (dispatch: Dispatch<any>) => {
  // Required as <Form> component does not internally update components
  data.components = panel.components as any;
  dispatch(updateRequest(data));
  return put(`/projects/${data.project}/panels/${panel.uuid}`, data, Definition)
    .then((panel: Definition) => {
      add(
        "success",
        `Updated ${panel.mspReference} successfully.`,
        4,
      )(dispatch);
      const [updatedPanel, project_] = patchProject(project, panel, lookups);
      dispatch(fetchSuccess(project_));
      dispatch(updateSuccess(updatedPanel, project));
      return panel;
    })
    .catch((errors: ApiErrors) => {
      error(errors);
      dispatch(createError());
      add(
        "error",
        {
          title: "Update Error",
          body: `Unable to update ${panel.mspReference}, please correct the errors and try again.`,
        },
        4,
      )(dispatch);
      dispatch(updateError());
      throw errors;
    });
};

const del = (project: Project, panel: Panel): any => (
  dispatch: Dispatch<any>,
) => {
  dispatch(deleteRequest(project, panel));
  return httpDel(`/projects/${project.uuid}/panels/${panel.uuid}`)
    .then(() => {
      add(
        "success",
        `Deleted ${panel.mspReference} from ${project.name} successfully.`,
        4,
      )(dispatch);
      dispatch(deleteSuccess(project, panel));
      projectOperations.deletePanel(project, panel)(dispatch);
    })
    .catch((errors: ApiErrors) => {
      error(errors);
      throw errors;
    });
};

const updateDiscount = (
  project: Project,
  panel: Panel,
  discount: number,
  lookups: Lookups,
): any => (dispatch: Dispatch<any>) => {
  return patch(`/projects/${project.uuid}/panels/${panel.uuid}`, {
    discount,
  })
    .then(panel => {
      add(
        "success",
        `Updated discount on ${panel.mspReference} to ${discount}%`,
        4,
      )(dispatch);
      const project_ = patchProject(project, panel, lookups)[1];
      dispatch(fetchSuccess(project_));
      dispatch(changeDiscount(discount));
    })
    .catch((errors: ApiErrors) => {
      add(
        "error",
        `Failed to update discount on ${panel.mspReference} to ${discount}%, please try again.`,
        4,
      )(dispatch);
      error(errors);
      throw errors;
    });
};

const clear = (): any => (dispatch: Dispatch<any>) => {
  dispatch(clearBuilder());
};

const validate = (panelSet: PanelSet, lookups: Lookups) => (
  dispatch: Dispatch<any>,
) => {
  const backplateErrors = panelSet.backplate.errors();
  const panelErrors = panelSet.panel.errors(lookups);

  const { serviceTypes } = panelSet.panel;
  const lastServiceType: ServiceType | undefined = last(serviceTypes);

  const lastComponent: Component | undefined = lastServiceType
    ? last(lastServiceType.components)
    : undefined;

  if (lastServiceType && isPan(lastComponent, panelSet.panel.series)) {
    const previousSibling =
      lastServiceType.components[lastServiceType.components.length - 2];
    if (
      previousSibling &&
      !previousSibling.isGpo &&
      !(lastServiceType.name === "LV")
    ) {
      panelErrors.push(Errors.pan);
    }
  }

  const panWallboxError = {
    message: "GPO with Neon can't be in it's own wallbox",
    skippable: false,
  };
  const hasGpoInOwnWallbox = panelSet.backplate.wallboxes.find(
    wallbox =>
      wallbox.components.length === 1 &&
      isPan(wallbox.components[0], panelSet.panel.series),
  );
  if (
    hasGpoInOwnWallbox &&
    panelSet.panel.series.name.toLowerCase() === "b series"
  ) {
    panelErrors.push(panWallboxError);
  }

  const errors = [...panelErrors, ...backplateErrors];

  if (errors.length) {
    add("error", errors[0].message, 10)(dispatch);
    return Promise.reject(errors);
  } else {
    return Promise.resolve(true);
  }
};

const changeGPOCenter = (center: number, components: string[]) => (
  dispatch: Dispatch<any>,
) => {
  dispatch(changeGPOCenterRequest(center, components));
  return (
    post(`/components/matching-center/${center}`, components, Component)
      .then(matched => {
        dispatch(changeGPOCenterSuccess(components, matched));
      })
      // eslint-disable-next-line
      .catch(() => {})
  );
};

// Component operations

const addComponent = (
  component: Component,
  series: Series,
  panelType: PanelType,
  plateFinish: PlateFinish,
  index?: number,
  overrides?: Overrides,
): any => (dispatch: Dispatch<any>): any =>
  dispatch(
    addComponentAction(
      component,
      series,
      panelType,
      plateFinish,
      index,
      overrides,
    ),
  );

const removeComponent = (index: number): any => (dispatch: Dispatch<any>) =>
  dispatch(removeComponentAction(index));

const clearComponents = (): any => (dispatch: Dispatch<any>) =>
  dispatch(clearComponentsAction());

const modifyComponent = (
  component: Component,
  index: number,
  newIndex: number,
  overrides: Overrides,
): any => (dispatch: Dispatch<any>) =>
  dispatch(modifyComponentAction(component, index, newIndex, overrides));

const replaceComponent = (
  component: Component,
  index: number,
  series: Series,
  panelType: PanelType,
  plateFinish: PlateFinish,
): any => (dispatch: Dispatch<any>) => {
  dispatch(
    replaceComponentAction(component, series, panelType, plateFinish, index),
  );
  // dispatch(removeComponentAction(index));
  // dispatch(
  //   addComponentAction(component, series, panelType, plateFinish, index),
  // );
};

type Operations = {
  setWorking: (panel: Panel, modifier?: PanelModifier) => void;
  addComponent: (
    component: Component,
    series: Series,
    panelType: PanelType,
    plateFinish: PlateFinish,
    index?: number,
    overrides?: Overrides,
  ) => void;
  removeComponent: (index: number) => void;
  clearComponents: () => void;
  modifyComponent: (
    component: Component,
    index: number,
    newIndex: number,
    overrides: Overrides,
  ) => void;
  replaceComponent: (
    component: Component,
    index: number,
    series: Series,
    panelType: PanelType,
    plateFinish: PlateFinish,
  ) => void;
  create: (
    data: WritePanel,
    project: Project,
    lookups: Lookups,
  ) => Promise<any>;
  update: (
    panel: Panel,
    data: WritePanel,
    project: Project,
    lookups: Lookups,
  ) => Promise<any>;
  del: (project: Project, panel: Panel) => Promise<any>;
  updateDiscount: (
    project: Project,
    panel: Panel,
    discount: number,
    lookups: Lookups,
  ) => Promise<any>;
  clear: () => void;
  validate: (panel: PanelSet, lookups: Lookups) => any;
  changeGPOCenter: (center: number, components: string[]) => any;
};

const operations: Operations = {
  setWorking,
  addComponent,
  removeComponent,
  clearComponents,
  modifyComponent,
  replaceComponent,
  create,
  update,
  del,
  updateDiscount,
  clear,
  validate,
  changeGPOCenter,
};

export { operations, Operations };
