import Action, { State } from "../types";

import OEF from "@app/models/oef";
import Types from "./types";

const initialState: State<OEF> = {
  loading: false,
  data: null,
};

const reducer = (state = initialState, action: Action<OEF>): State<OEF> => {
  const { type, payload } = action;
  let loading = false;

  switch (type) {
    case Types.GENERATE_ATTEMPT:
      loading = true;
      break;

    case Types.GENERATE_SUCCESS:
      loading = false;
      break;

    default:
      return state;
  }

  return { loading, data: payload };
};

export { reducer };

export default reducer;
