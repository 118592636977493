import React from "react";
import styles from "./styles.modules.css";
import { useTheme } from "@bespohk/uikit/components";

type OwnProps = {
  children: React.ReactNode;
};

const FilterBar = ({ children }: OwnProps) => {
  const cx = useTheme(FilterBar, styles);
  return <div className={cx({ outer: true })}>{children}</div>;
};

export { FilterBar };
export default FilterBar;
