import Grid, { Container, Definition } from "@app/models/grid";
import { areSpacersAndGas, width } from "@app/helpers/components";
import { last, rehydrate } from "@bespohk/lib";

import Backplate from "@app/models/backplate";
import Component from "@app/models/component";
import Panel from "@app/models/panel";
import Type from "@app/models/type";
import { Definition as Wallbox } from "@app/models/wallbox";

const findGridForComponents = (
  components: Component[],
  panel: Panel,
  gridDefinitions: Definition[],
  wallboxDefinitions: Wallbox[],
): Grid => {
  const componentsWidth: number = width(components);
  const wallbox: Wallbox = wallboxDefinitions.find(
    wallbox => wallbox.width === componentsWidth,
  );

  const grid: Definition = wallbox
    ? gridDefinitions.find(
        grid =>
          grid.gasOnly === areSpacersAndGas(components) &&
          grid.hasSeries(panel.series) &&
          grid.panelType.id === panel.panelType.id &&
          grid.size === parseInt(wallbox.gang),
      )
    : rehydrate(Definition, {
        uuid: null,
        description: "Unknown",
        code: null,
        size: null,
        panelType: panel.panelType,
        series: [panel.series],
        gasOnly: false,
        ncOnly: false,
        merlonNcOnly: false,
        createdDate: new Date(),
        updatedDate: new Date(),
      });

  const generatedGrid: Grid = rehydrate(Grid, {
    ...grid,
    components,
    width: componentsWidth,
  });

  return generatedGrid;
};

const generate = (
  backplate: Backplate,
  gridDefinitions: Definition[],
  wallboxDefinitions: Wallbox[],
): Container => {
  const container: Container = new Container();
  container.grids = [];

  backplate.wallboxes.forEach(wallbox => {
    let lastType: Type = null;
    const groupedComponents: Component[][] = [[]];
    wallbox.components.forEach(component => {
      if (
        lastType &&
        lastType.id !== component.type.id &&
        (component.type.id === 1 || component.type.id === 2)
      ) {
        groupedComponents.push([]);
      }
      if (!component.isSpacer) {
        lastType = component.type;
      }
      last(groupedComponents).push(component);
    });
    groupedComponents.forEach(components => {
      container.grids.push(
        findGridForComponents(
          components,
          backplate.panel,
          gridDefinitions,
          wallboxDefinitions,
        ),
      );
    });
  });

  return container;
};

export { generate };
