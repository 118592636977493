class Screw {
  description: string;
  partNumber: string;
  enabled: boolean;
  type: "front_plate" | "wallbox";

  public toString(): string {
    return this.description;
  }
}

export default Screw;
