import { BooleanColumnRenderer } from "@app/components";
import Grid from "@app/models/grid";
import React from "react";
import { TabularData } from "@app/containers";

const gasOnlyRenderer = (grid: Grid): React.ReactNode => (
  <BooleanColumnRenderer valid={grid.gasOnly} />
);

const Grids = () => (
  <TabularData
    type={Grid}
    endpoint="/grids"
    filter={{
      placeholder: "Enter a code or description to search for...",
      builder: (q: string) => `(name: ${q})`,
    }}
    columns={[
      { key: "code" },
      { key: "description" },
      { key: "size", label: "Gang", width: "10%" },
      { key: "gasOnly", renderer: gasOnlyRenderer, width: "10%" },
      { key: "humanUpdatedAt", label: "Last Updated" },
    ]}
  />
);

export { Grids };
export default Grids;
