import Layout from "./layout";
import React from "react";

type OwnProps = {};

const Construction = (_: OwnProps) => {
  return <Layout type="construction" showCutout />;
};

export { Construction };
export default Construction;
