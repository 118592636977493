import { formatter, rounding } from "@app/helpers/currency";

import Backplate from "./backplate";
import { Container } from "./grid";
import Panel from "./panel";
import { handlingFeesForQuantity } from "./common";

type Cost = "cost" | "totalCost";
type Part = "panel" | "backplate";

type SubAssembly = {
  itemCode: string;
  description: string;
  quantity: number;
};

type Total = {
  total: number;
  unit: number;
};

type Totals = {
  panelSet: Total;
  panel: Total;
  backplate: Total;
};

class PanelSet {
  panel: Panel;
  backplate: Backplate;
  gridContainer: Container;
  panelSubAssemblies: SubAssembly[] = [];
  frontPlateSubAssemblies: SubAssembly[] = [];

  public get deleted(): boolean {
    return this.panel.deleted;
  }

  public get handlingFee(): number {
    return handlingFeesForQuantity(this.panel.quantity);
  }

  public get cost(): number {
    return rounding(this.panel.cost + this.backplate.cost);
  }

  public get totalCost(): number {
    return rounding(this.panel.totalCost + this.backplate.totalCost);
  }

  public get humanCost(): string {
    return formatter.format(this.cost);
  }

  public get humanTotalCost(): string {
    return formatter.format(this.totalCost);
  }

  public discounted(cost: Cost, part: Part = null): number {
    const actualPart = part ? this[part] : this;
    let { discount } = this.panel;
    if (!discount || this.panel.expectedPrice) {
      discount = 0;
    }
    const discountDecimal = parseFloat((discount / 100).toFixed(2));
    let costToDiscount = actualPart[cost];
    if (cost === "totalCost") {
      const { quantity } = this.panel;
      // We need to do this due to how Pronto takes into account discounts,
      // it will discount individual items first, then multiply by quantity
      const discountedTotal =
        actualPart.cost - actualPart.cost * discountDecimal;
      costToDiscount = parseFloat(discountedTotal.toFixed(2)) * quantity;
    } else {
      costToDiscount = costToDiscount - costToDiscount * discountDecimal;
    }

    return rounding(parseFloat(costToDiscount.toFixed(2)));
  }

  public humanDiscounted(cost: Cost, part: Part = null): string {
    return formatter.format(this.discounted(cost, part));
  }

  public get netUnitCost(): number {
    const { expectedPrice } = this.panel;
    if (expectedPrice) {
      return expectedPrice;
    }

    return this.discounted("cost");
  }

  public get humanNetUnitCost(): string {
    return formatter.format(this.netUnitCost);
  }

  public get netCost(): number {
    return this.discounted("totalCost");
  }

  public get humanNetCost(): string {
    return formatter.format(this.netCost);
  }

  public get humanNetDiscountedCost(): string {
    return formatter.format(this.discountedTotalCosts.panelSet.total);
  }

  public get subAssemblies(): SubAssembly[] {
    return [...this.panelSubAssemblies, ...this.frontPlateSubAssemblies];
  }

  public get panelPartNumber(): string {
    return this.backplate.isStandardPart
      ? this.backplate.platePartNumber || this.panel.partNumber
      : this.panel.partNumber;
  }

  public get discountedTotalCosts(): Totals {
    let panelPrice = this.discounted("cost", "panel");
    let panelPriceTotal = this.discounted("totalCost", "panel");
    let wallboxPrice = this.discounted("cost", "backplate");
    let wallboxPriceTotal = this.discounted("totalCost", "backplate");
    const { expectedPrice } = this.panel;
    if (expectedPrice) {
      const calculateDiscount = expectedPrice / (panelPrice + wallboxPrice);
      panelPrice = rounding(calculateDiscount * panelPrice);
      wallboxPrice = rounding(calculateDiscount * wallboxPrice);

      panelPriceTotal = rounding(calculateDiscount * panelPriceTotal);
      wallboxPriceTotal = rounding(calculateDiscount * wallboxPriceTotal);
    }

    const panelSetPrice = panelPrice + wallboxPrice;
    const panelSetPriceTotal = panelPriceTotal + wallboxPriceTotal;

    return {
      panelSet: { total: panelSetPriceTotal, unit: panelSetPrice },
      panel: {
        total: panelPriceTotal,
        unit: panelPrice,
      },
      backplate: {
        total: wallboxPriceTotal,
        unit: wallboxPrice,
      },
    };
  }

  public get totalCosts(): Totals {
    let panelPrice = this.panel.cost;
    let panelPriceTotal = this.panel.totalCost;
    let wallboxPrice = this.backplate.cost;
    let wallboxPriceTotal = this.backplate.totalCost;
    const { expectedPrice } = this.panel;
    if (expectedPrice) {
      const calculateDiscount = expectedPrice / (panelPrice + wallboxPrice);
      panelPrice = rounding(calculateDiscount * panelPrice);
      wallboxPrice = rounding(calculateDiscount * wallboxPrice);

      panelPriceTotal = rounding(calculateDiscount * panelPriceTotal);
      wallboxPriceTotal = rounding(calculateDiscount * wallboxPriceTotal);
    }

    const panelSetPrice = panelPrice + wallboxPrice;
    const panelSetPriceTotal = panelPriceTotal + wallboxPriceTotal;

    return {
      panelSet: { total: panelSetPriceTotal, unit: panelSetPrice },
      panel: {
        total: panelPriceTotal,
        unit: panelPrice,
      },
      backplate: {
        total: wallboxPriceTotal,
        unit: wallboxPrice,
      },
    };
  }
}

export { PanelSet, SubAssembly };

export default PanelSet;
