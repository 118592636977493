import { Component, Components } from "@app/pages/components";
import { ComponentData, ComponentDatas } from "@app/pages/component-datas";
import {
  Construction as ConstructionDrawing,
  Customer as CustomerDrawing,
  Factory as FactoryDrawing,
} from "@app/pages/drawings";
import { Contact, Contacts } from "@app/pages/contacts";
import { Customer, Customers } from "@app/pages/customers";
import { Grid, Grids } from "@app/pages/grids";
import { Hbar, Hbars } from "@app/pages/hbars";
import { Login, Logout } from "@app/pages/auth";
import { PanelType, PanelTypes } from "@app/pages/panel-types";
import { PlateFinish, PlateFinishes } from "@app/pages/plate-finishes";
import { Project, Projects, History } from "@app/pages/projects";
import { Redirect, matchPath } from "react-router-dom";
import { User, Users } from "@app/pages/users";
import { Wallbox, Wallboxes } from "@app/pages/wallboxes";

import { Error404 } from "@app/pages/errors";
import { Inclusions } from "@app/pages/inclusions";
import Panel from "@app/pages/panels/panel";
import React from "react";
import { ScheduledApprovals } from "@app/pages/scheduled-approvals";

type Route = {
  path: string;
  name: string;
  component: any;
  exact?: boolean;
  humanName?: string;
  contentOnly?: boolean;
};

const authorized: Route[] = [
  {
    name: "plateFinishes",
    humanName: "Plate Finishes",
    path: "/plate-finishes",
    component: PlateFinishes,
    exact: true,
  },
  {
    name: "plateFinish",
    humanName: "Plate Finish",
    path: "/plate-finishes/:id",
    component: PlateFinish,
    exact: true,
  },
  {
    name: "panelTypes",
    humanName: "Panel Types",
    path: "/panel-types",
    component: PanelTypes,
    exact: true,
  },
  {
    name: "panelType",
    humanName: "Panel Type",
    path: "/panel-types/:id",
    component: PanelType,
    exact: true,
  },
  {
    name: "wallboxes",
    humanName: "Wallboxes",
    path: "/wallboxes",
    component: Wallboxes,
    exact: true,
  },
  {
    name: "wallbox",
    humanName: "Wallbox",
    path: "/wallboxes/:uuid",
    component: Wallbox,
    exact: true,
  },
  {
    name: "grids",
    humanName: "Grids",
    path: "/grids",
    component: Grids,
    exact: true,
  },
  {
    name: "grid",
    humanName: "Grid",
    path: "/grids/:uuid",
    component: Grid,
    exact: true,
  },
  {
    name: "hbars",
    humanName: "Hbars",
    path: "/hbars",
    component: Hbars,
    exact: true,
  },
  {
    name: "hbar",
    humanName: "Hbar",
    path: "/hbars/:id",
    component: Hbar,
    exact: true,
  },
  {
    name: "componentDatas",
    humanName: "Component Data",
    path: "/components/datas",
    component: ComponentDatas,
    exact: true,
  },
  {
    name: "componentData",
    humanName: "Component Data",
    path: "/components/datas/:id",
    component: ComponentData,
    exact: true,
  },
  {
    name: "components",
    humanName: "Components",
    path: "/components",
    component: Components,
    exact: true,
  },
  {
    name: "component",
    humanName: "Component",
    path: "/components/:uuid",
    component: Component,
    exact: true,
  },
  {
    name: "customers",
    humanName: "Customers",
    path: "/customers",
    component: Customers,
    exact: true,
  },
  {
    name: "customer",
    humanName: "Customer",
    path: "/customers/:uuid",
    component: Customer,
    exact: true,
  },
  {
    name: "contacts",
    humanName: "Contacts",
    path: "/contacts",
    component: Contacts,
    exact: true,
  },
  {
    name: "contact",
    humanName: "Contact",
    path: "/contacts/:uuid",
    component: Contact,
    exact: true,
  },
  {
    name: "approvals",
    humanName: "Scheduled Approvals",
    path: "/projects/approvals",
    component: ScheduledApprovals,
    exact: true,
  },
  {
    name: "projects",
    humanName: "Projects",
    path: "/projects",
    component: Projects,
    exact: true,
  },
  {
    name: "project",
    humanName: "Project",
    path: "/projects/:uuid",
    component: Project,
    exact: true,
  },
  {
    name: "history",
    humanName: "Project History",
    path: "/projects/:uuid/history",
    component: History,
    exact: true,
  },
  {
    name: "panel",
    humanName: "Panel",
    path: "/projects/:projectUuid/panels/:uuid",
    component: Panel,
    exact: true,
  },
  {
    name: "inclusion",
    humanName: "Inclusion",
    path: "/projects/:projectUuid/inclusions",
    component: Inclusions,
    exact: true,
    contentOnly: true,
  },
  {
    name: "customerDrawing",
    humanName: "Customer Drawing",
    path: "/projects/:projectUuid/drawings/customer",
    component: CustomerDrawing,
    exact: true,
    contentOnly: true,
  },
  {
    name: "factoryDrawing",
    humanName: "Factory Drawing",
    path: "/projects/:projectUuid/drawings/factory",
    component: FactoryDrawing,
    exact: true,
    contentOnly: true,
  },
  {
    name: "constructionDrawing",
    humanName: "Construction Drawing",
    path: "/projects/:projectUuid/drawings/construction",
    component: ConstructionDrawing,
    exact: true,
    contentOnly: true,
  },
  {
    name: "users",
    humanName: "Users",
    path: "/users",
    component: Users,
    exact: true,
  },
  {
    name: "user",
    humanName: "User",
    path: "/users/:uuid",
    component: User,
    exact: true,
  },
  {
    // Redirect the homepage to /projects
    name: "home",
    path: "/",
    component: () => <Redirect to="/projects" />,
    exact: true,
  },
  {
    name: "logout",
    path: "/auth/logout",
    component: Logout,
    exact: true,
  },
  {
    name: "login",
    path: "/auth/login",
    component: Login,
    exact: true,
  },
  {
    // Fallback if nothing found
    name: "error",
    humanName: "Page Not Found",
    path: "",
    component: Error404,
  },
];

const guest: Route[] = [
  {
    name: "login",
    path: "/auth/login",
    component: Login,
    exact: true,
  },
  {
    name: "error",
    humanName: "Page Not Found",
    path: "",
    component: () => <Redirect to="/auth/login" />,
  },
];

const getMatch = (path: string): Route => {
  const matchedRoute: Route = authorized.find(route =>
    matchPath(path, {
      path: route.path,
      exact: route.exact,
    }),
  );

  return matchedRoute;
};

export { authorized, guest, getMatch, Route };
export default authorized;
