import Cell from "../cell";
import Inclusion from "@app/models/inclusion";
import React from "react";
import Table from "../table";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";

const cx = classNames.bind(styles);

type OwnProps = {
  inclusion: Inclusion;
};

const Legend = ({ inclusion }: OwnProps) => {
  const { categories } = inclusion;

  const equipment = Object.keys(categories).reduce((equipment, key) => {
    const category = categories[key];
    Object.keys(category).forEach(equipmentCode => {
      equipment.push({
        name: equipmentCode,
        components: category[equipmentCode].join(", "),
      });
    });
    return equipment;
  }, []);

  return (
    <Table className={cx({ outer: true })}>
      <thead>
        <tr>
          <Cell header highlight value="Legend" colSpan={2} />
        </tr>
        <tr>
          <Cell header value="Code" />
          <Cell header value="Name" />
        </tr>
      </thead>
      <tbody>
        {equipment.map((equipment, index) => (
          <tr key={index}>
            <Cell value={equipment.name} />
            <Cell value={equipment.components} />
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export { Legend };
export default Legend;
