import { Approval, Details, Legend, Overlay, Part, Title } from "./parts";
import { Button } from "@bespohk/uikit/components";
import { Operations, operations } from "@app/state/ducks/project/operations";
import React, { useEffect } from "react";

import Cover from "./cover";
import Cutout from "./parts/cutout";
import Loading from "./loading";
import { Preview, SizingMethod } from "@app/components/preview";
import State from "@app/state";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";
import { useOperations } from "@app/helpers/redux";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Header } from "./header";

const cx = classNames.bind(styles);

type OwnProps = {
  type: "customer" | "factory" | "construction";
  showCutout?: boolean;
};

const Layout = ({ type, showCutout }: OwnProps) => {
  const { data, loading } = useSelector((state: State) => state.project);
  const [sizingMethod, setSizingMethod] = React.useState<SizingMethod>(
    "parent",
  );

  const { fetch } = useOperations<Operations>(operations);

  const params = useParams();
  const projectUuid = params["projectUuid"];

  useEffect(() => {
    fetch(null, `/projects/${projectUuid}`);
  }, []);

  useEffect(() => {
    if (!loading && data) {
      document.title = `${data.salesOrderNumber}_${data.name}_Revision-${data.revision}`;
    }
  }, [loading, data]);

  return (
    <div className={cx({ outer: true })}>
      {!loading && (
        <div className={cx("print")}>
          <Button
            label="Print"
            action={() => {
              setSizingMethod("a3");
              setTimeout(() => {
                window.print();
              }, 1000);
            }}
          />
          <Button label="Browser" action={() => setSizingMethod("parent")} />
        </div>
      )}

      {type === "customer" && (
        <Cover className={cx({ [sizingMethod]: true })} />
      )}
      {type !== "customer" && <Header />}

      {loading && <Loading />}
      <>
        {data &&
          !loading &&
          data.panelSets
            .filter(panelSet => panelSet.panel.hasComponents)
            .filter(panelSet => !panelSet.deleted)
            .map((panelSet, index) => (
              <div
                key={panelSet.panel.uuid}
                className={cx("page", "panel", {
                  break: index < data.panelSets.length - 1,
                  [sizingMethod]: true,
                })}
              >
                <div className={cx({ drawing: true })}>
                  <Part position="top">
                    <Title project={data} panel={panelSet.panel} />
                    <Approval
                      project={data}
                      panel={panelSet.panel}
                      type={type}
                    />
                  </Part>
                  <Preview
                    sizingMethod={sizingMethod}
                    factoryView={type === "factory"}
                    fullView={type === "factory"}
                    panelSet={panelSet}
                    isDrawing
                    componentsLength={panelSet.panel.components.length}
                  />
                  <Part position="bottom">
                    <Legend project={data} panel={panelSet.panel} />
                    <Details
                      project={data}
                      panelSet={panelSet}
                      isFactory={type === "factory"}
                    />
                  </Part>
                  {showCutout && <Cutout panelSet={panelSet} />}
                  {type !== "factory" && (
                    <Overlay
                      type={type}
                      message={
                        panelSet.panel.approved
                          ? "Approved"
                          : panelSet.panel.deleted
                          ? "No longer required"
                          : data.isCad
                          ? "Superceded by CAD"
                          : ""
                      }
                    />
                  )}
                </div>
              </div>
            ))}
      </>
    </div>
  );
};

export { Layout };
export default Layout;
