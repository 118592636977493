import {
  Heading,
  PageRead,
  Wrapper,
  useTheme,
} from "@bespohk/uikit/components";
import { Route, getMatch } from "@app/app";

import React from "react";
import styles from "./styles.modules.css";
import { useLocation } from "react-router-dom";

type OwnProps = {};

const Header = (_: OwnProps) => {
  const location = useLocation();
  const cx = useTheme(Header, styles);
  const route: Route = getMatch(location.pathname);

  return (
    <div className={cx({ outer: true })}>
      <PageRead />
      <Wrapper>
        <Heading value={route.humanName} />
      </Wrapper>
    </div>
  );
};

export { Header };
export default Header;
