import Cell from "./cell";
import Inclusion from "@app/models/inclusion";
import React from "react";
import Table from "./table";
import { Text } from "@bespohk/uikit/components";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";

const cx = classNames.bind(styles);

type OwnProps = {
  inclusion: Inclusion;
};

const Overview = ({ inclusion }: OwnProps) => {
  const { project } = inclusion;
  return (
    <Table className={cx({ overview: true })}>
      <tbody>
        <tr>
          <Cell value="Project Name" />
          <Cell highlight value={project.name} />
        </tr>
        <tr>
          <Cell value="Drawing Reference" />
          <Cell highlight value={project.drawingReference} />
        </tr>
        <tr>
          <Cell value="Notes" />
          <Cell
            highlight
            value={inclusion.notes.map((note, index) => (
              <Text key={index} className={cx({ note: true })}>
                {note}
              </Text>
            ))}
          />
        </tr>
        <tr>
          <Cell value="Revision" />
          <Cell highlight value={project.revision} />
        </tr>
      </tbody>
    </Table>
  );
};

export { Overview };
export default Overview;
