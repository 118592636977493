import { AnyAction } from "redux";

type Action<T> = AnyAction & {
  payload: T;
};

type State<T> = {
  loading: boolean;
  data: T;
};

class Pagination<T> {
  count: number;
  next?: string;
  previous?: string;
  results: T[];
}

export { Action, State, Pagination };

export default Action;
