import React from "react";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";

type OwnProps = {
  message?: string;
  type: "customer" | "factory" | "construction";
};

const cx = classNames.bind(styles);

const Overlay = ({ message, type }: OwnProps) => {
  if (!message) {
    return null;
  }
  return <div className={cx({ overlay: true, [type]: true })}>{message}</div>;
};

export { Overlay };
export default Overlay;
