import { ThunkDispatch } from "redux-thunk";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { useMemo } from "react";

const bindOperationsToProps = (...operations: any[]) => (
  dispatch: ThunkDispatch<{}, {}, any>,
): any =>
  /**
   * Binds action creators to a single object, useful for redux' connect()
   *
   * @usage
   * type DispatchProps = {
   *    operations: Operations
   * }
   *
   * bindOperationsToProps(operations); // imported from the relevant dux
   */
  ({
    operations: operations.reduce((ops, operation) => {
      Object.keys(operation).forEach(op => {
        ops[op] = bindActionCreators(operation[op], dispatch);
      });
      return ops;
    }, {}),
  });

const useOperations = <T>(...operations: any[]): T => {
  /**
   * Binds action creators to a single object, use when not using connect()
   */
  const dispatch = useDispatch();

  return useMemo(() => {
    return operations.reduce((available, duck) => {
      Object.keys(duck).forEach(operation => {
        available[operation] = bindActionCreators(duck[operation], dispatch);
      });
      return available;
    }, {});
  }, [dispatch]);
};

export { bindOperationsToProps, useOperations };
