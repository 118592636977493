import { Icon, Icons } from "@bespohk/uikit/components";

import React from "react";
import classNames from "classnames/bind";
import { isString } from "@bespohk/lib";
import styles from "./styles.modules.css";
import { useHistory } from "react-router";

const cx = classNames.bind(styles);

type OwnProps = {
  icon?: Icons;
  path: any; // TODO: Type this to string or callback
  label: any;
  className?: string;
  size?: "regular" | "small";
  callback?: () => boolean | void;
};

const NavigationButton = ({
  path,
  label,
  className,
  callback,
  icon,
  size,
}: OwnProps) => {
  const history = useHistory();

  const attrs = {
    href: isString(path) ? path : null,
  };

  return (
    <a
      {...attrs}
      className={cx({ button: true, [className]: !!className, [size]: true })}
      onClick={e => {
        e.preventDefault();
        if (callback) {
          if (callback()) {
            return;
          }
        }

        if (isString(path)) {
          history.push(path);
        } else {
          path();
        }
      }}
    >
      {icon && <Icon name={icon} className={cx({ icon: true })} />}
      {label}
    </a>
  );
};

NavigationButton.defaultProps = {
  size: "regular",
} as OwnProps;

export { NavigationButton };
export default NavigationButton;
