import { History, createBrowserHistory } from "history";
import { Store, StoreEnhancer, applyMiddleware, createStore } from "redux";

import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import reducer from "./ducks";
import thunk from "redux-thunk";

const history: History = createBrowserHistory();

const configureStore = (): Store => {
  let middleware: StoreEnhancer = applyMiddleware(
    // routerMiddleware(history),
    thunk,
  );

  const compose = composeWithDevTools({
    trace: true,
    traceLimit: 40,
  });
  middleware = compose(middleware);

  return createStore(reducer(history), middleware);
};

export { configureStore };
