import Divider from "./divider";
import { Wallbox as Model } from "@app/models/wallbox";
import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";
import { Costs } from "../costs";

const cx = classNames.bind(styles);

type OwnProps = {
  wallbox: Model;
  showCosts?: boolean;
  isDrawing?: boolean;
};

const Wallbox = ({ wallbox, showCosts, isDrawing }: OwnProps) => {
  const [date] = useState(Date.now());
  const style: React.CSSProperties = {
    width: `${wallbox.width}px`,
  };
  if (wallbox.isValid) {
    style.backgroundImage = `url("${process.env.ASSET_BASE}/img/components/wallboxes/${wallbox.svg}?d=${date}")`;
  }

  return (
    <>
      <div
        className={cx({ wallbox: true, rcd: wallbox.rcdBank, isDrawing })}
        style={style}
      >
        {wallbox.dividers.map(position => (
          <Divider key={position} position={position} />
        ))}
        <div className={cx({ gang: true })}>
          {wallbox.humanGang}
          <Costs cost={showCosts ? wallbox.humanCost : null} />
        </div>
        <div className={cx({ dimensions: true })}>{wallbox.width}</div>
      </div>
    </>
  );
};

export { Wallbox };
export default Wallbox;
