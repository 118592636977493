import { Route, getMatch } from "@app/app";
import { useHistory, useLocation } from "react-router-dom";

import React from "react";
import styles from "./styles.modules.css";
import { useTheme } from "@bespohk/uikit/components";

type OwnProps = {
  label: string;
  path: string;
  className?: string;
};

const Button = ({ label, path, className }: OwnProps) => {
  const history = useHistory();
  const location = useLocation();

  const cx = useTheme(Button, styles);
  const paths: string[] = location.pathname.split("/");
  const topPath: string =
    paths.length > 2 ? `${paths[1]}/${paths[2]}` : paths[1];
  const route: Route = getMatch(`/${topPath}`);
  const buttonRoute: Route = getMatch(path);
  return (
    <a
      href={path}
      className={cx({
        item: true,
        active: route === buttonRoute,
        [className]: !!className,
      })}
      onClick={e => {
        history.push(path);
        e.preventDefault();
      }}
    >
      {label}
    </a>
  );
};

export { Button };
export default Button;
