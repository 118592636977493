// @ts-nocheck
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Content, Footer, Header, Menu } from "@app/components";
import { authorized, getMatch, guest } from "@app/app";

import { Alerts } from "@app/containers/alerts";
import { Boundry } from "@app/components/errors";
import React from "react";
import State from "@app/state";
import Token from "@app/models/token";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { get } from "@app/helpers/api";
import { Operations, operations } from "@app/state/ducks/auth/operations";
import { useOperations } from "@app/helpers/redux";

type OwnProps = {};

const authSelector = (state: State): Token => state.auth.data.token;
const pathSelector = (state: State): string => state.history.location.pathname;

const layoutSelector = createSelector(
  authSelector,
  pathSelector,
  (auth, path) => ({
    auth,
    path,
  }),
);

const Layout = (_: OwnProps) => {
  const { auth, path } = useSelector(layoutSelector);
  const ops: Operations = useOperations(operations);

  if (!auth) {
    return (
      <BrowserRouter>
        <Switch>
          {guest.map(route => (
            <Route
              key={route.name}
              path={route.path}
              component={route.component}
              exact={route.exact}
            />
          ))}
        </Switch>
      </BrowserRouter>
    );
  }

  const route = getMatch(path);

  const checkAuth = async () => {
    try {
      await get("/auth/status");
    } catch (e) {
      ops.logout();
      // window.location.href = "/auth/login";
      return;
    }

    setTimeout(checkAuth, 10000);
  };

  checkAuth();

  const routes = (
    <Switch>
      {authorized.map(route => (
        <Route
          key={route.name}
          path={route.path}
          component={route.component}
          exact={route.exact}
        />
      ))}
    </Switch>
  );

  return (
    <BrowserRouter>
      {route.contentOnly ? (
        routes
      ) : (
        <>
          <Menu />
          <Boundry>
            <Header />
            <Content>
              <Alerts />
              {routes}
            </Content>
          </Boundry>
          <Footer />
        </>
      )}
    </BrowserRouter>
  );
};

export { Layout };
export default Layout;
