import { State as BaseState } from "../types";
import { Pagination } from "../types";

const Types = {
  FETCH_REQUEST: "@@resources/FETCH_REQUEST",
  FETCH_SUCCESS: "@@resources/FETCH_SUCCESS",
  FETCH_ERROR: "@@resources/FETCH_ERROR",
  CLEAR: "@@resources/CLEAR",
};

type State<T, C> = BaseState<Pagination<T>> & {
  // klass: C;
};

export { Types, State };

export default Types;
