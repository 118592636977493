import { Action } from "./actions";
import Alert from "@app/models/alert";
import Types from "./types";

const initialState: Alert[] = [];

const reducer = (state = initialState, action: Action): any => {
  const { type, alert } = action;
  switch (type) {
    case Types.ADD:
      return [...state, alert];

    case Types.REMOVE: {
      const newState: Alert[] = [...state];
      const index = newState.findIndex(a => a === alert);
      if (index > -1) {
        newState.splice(index, 1);
      }
      return newState;
    }

    default:
      return state;
  }
};

export { reducer };

export default reducer;
