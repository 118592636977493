import React from "react";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";

const cx = classNames.bind(styles);

type OwnProps = {
  header?: boolean;
  highlight?: boolean;
  lowlight?: boolean;
  center?: boolean;
  vertical?: boolean;
  footer?: boolean;
  value: any;
  className?: string;
} & any;

const Cell = ({
  header,
  highlight,
  lowlight,
  vertical,
  value,
  footer,
  className,
  center,
  ...rest
}: OwnProps) => {
  const Type = header ? "th" : "td";
  return (
    <Type
      className={cx({
        cell: true,
        highlight,
        header,
        vertical,
        center,
        lowlight,
        footer,
        [className]: !!className,
      })}
      {...rest}
    >
      {value}
    </Type>
  );
};

export { Cell };
export default Cell;
