import * as yup from "yup";

import { Input, Select } from "@bespohk/uikit/components";

import { default as Model } from "@app/models/hbar";
import { ModelForm } from "@app/containers";
import PlateFinish from "@app/models/plate-finish";
import React from "react";
import { lookup } from "@app/helpers/api";
import { paginatedPlateFinishOptions } from "@app/helpers/form";

type OwnProps = {};

const Hbar = (_: OwnProps) => {
  return (
    <ModelForm
      identifier="id"
      type={Model}
      endpoint="/hbars"
      initialTransform={yup.object().shape({
        id: yup.number().strip(true),
        description: yup.string(),
        plateFinish: yup.mixed().convert("."),
        partNumber: yup.string(),
        cost: yup.number(),
      })}
      preValidateTransform={yup.object().shape({
        plateFinish: yup.mixed().convert("id"),
        cost: yup.number(),
        description: yup.string(),
      })}
      validate={yup.object().shape({
        description: yup.string().required("Description is required."),
        partNumber: yup.string().required("Part Number is required."),
        cost: yup.number().required("Cost is required."),
        plateFinish: yup.number().required("Plate Finish is required."),
      })}
      fieldsets={[
        [
          {
            name: "description",
            renderer: Input,
            props: { required: true },
          },
        ],
        [
          { name: "partNumber", renderer: Input, props: { required: true } },
          {
            name: "cost",
            renderer: Input,
            props: { type: "number", required: true },
          },
        ],
        [
          {
            name: "plateFinish",
            renderer: Select,
            props: {
              required: true,
              openOnFocus: true,
              async: lookup(
                PlateFinish,
                "/plate-finishes",
                paginatedPlateFinishOptions,
                (q: string) => (q ? `(name:${q}){*}` : null),
              ),
            },
          },
        ],
      ]}
    />
  );
};

export { Hbar };
export default Hbar;
