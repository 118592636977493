import { Wrapper, useTheme } from "@bespohk/uikit/components";

import { Button } from "../menu";
import React from "react";
import State from "@app/state";
import styles from "./styles.modules.css";
import { useSelector } from "react-redux";

type OwnProps = {};

const Footer = (_: OwnProps) => {
  const cx = useTheme(Footer, styles);
  const user = useSelector((state: State) => state.auth.data.user);

  return (
    <div className={cx({ outer: true })}>
      <div className={cx({ menu: true })}>
        {(user.isAdmin || user.isOperations) && (
          <Wrapper>
            <Button
              label="Components"
              path="/components"
              className={styles.link}
            />
            <Button
              label="Component Data"
              path="/components/datas"
              className={styles.link}
            />
            <Button
              label="Wallboxes"
              path="/wallboxes"
              className={styles.link}
            />
            <Button label="Grids" path="/grids" className={styles.link} />
            <Button
              label="Plate Finishes"
              path="/plate-finishes"
              className={styles.link}
            />
            <Button
              label="Panel Types"
              path="/panel-types"
              className={styles.link}
            />
            <Button label="Hbars" path="/hbars" className={styles.link} />
          </Wrapper>
        )}
      </div>
      <Wrapper>
        <span className={cx({ copyright: true })}>
          &copy; {new Date().getFullYear()} ESCO Industries - An ESCO Group of
          Companies
        </span>
      </Wrapper>
    </div>
  );
};

export { Footer };
export default Footer;
