import Layout from "./layout";
import React from "react";

type OwnProps = {};

const Customer = (_: OwnProps) => {
  return <Layout type="customer" showCutout />;
};

export { Customer };
export default Customer;
