import { Lookups } from "./types";
import Project from "@app/models/project";
import { generate as generatePanelSet } from "./panel-set";

const generate = (project: Project, lookups: Lookups): Project => {
  const generatedProject: Project = project;

  project.panelSets = project.panels.map((panel, index) => {
    panel.index = index + 1;
    panel.salesOrderNumber = project.salesOrderNumber;
    return generatePanelSet(panel, lookups);
  });

  return generatedProject;
};

export { generate };
