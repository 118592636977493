import {
  Button,
  ButtonBar,
  Choice,
  Field,
  Form,
  Heading,
  Loader,
  Modal,
  Text,
} from "@bespohk/uikit/components";
import { Operations, operations } from "@app/state/ducks/resources/operations";
import {
  Operations as ProjectOperations,
  operations as projectOperations,
} from "@app/state/ducks/project/operations";
import React, { useEffect } from "react";

import { Offering } from "@app/models/project";
import State from "@app/state";
import { createSelector } from "reselect";
import { groupOfferings } from "@app/helpers/projects";
import styles from "./styles.modules.css";
import { toUpperCaseWords } from "@bespohk/lib";
import { useOperations } from "@app/helpers/redux";
import { useRouteMatch } from "react-router";
import { useSelector } from "react-redux";

type OwnProps = {
  show?: boolean;
  cancel: () => void;
};

const projectSelector = (state: State) => state.project.data;
const offeringSelector = (state: State) => state.resources.data.results;
const loadingSelector = (state: State) => state.resources.loading;
const inclusionSelector = createSelector(
  projectSelector,
  offeringSelector,
  loadingSelector,
  (project, offerings, loading) => ({
    project,
    offerings,
    loading,
  }),
);

const InclusionsPrompt = ({ show, cancel }: OwnProps) => {
  const { fetch, clear } = useOperations<Operations>(operations);
  const { setInclusionOfferings } = useOperations<ProjectOperations>(
    projectOperations,
  );
  const { project, offerings, loading } = useSelector(inclusionSelector);

  const route = useRouteMatch();

  useEffect(() => {
    if (show) {
      fetch(Offering, "/projects/options", 0, 500);
    }

    return () => {
      clear();
    };
  }, [show]);

  const groupedOfferings = groupOfferings(offerings);

  let { offerings: existingOfferings } = project;
  if (!existingOfferings.length && offerings) {
    existingOfferings = offerings.filter(
      (offering: Offering) => offering.default,
    );
  }
  const projectOfferings = groupOfferings(existingOfferings);

  return (
    <Modal when={show} onClose={() => cancel()} className={styles.window}>
      <Heading>Select offerings</Heading>
      <Form
        initial={projectOfferings}
        onSubmit={values => {
          const submittedOfferings = Object.values(values).reduce(
            (offerings, current) => {
              offerings = [...offerings, ...current.map(o => o.id)];
              return offerings;
            },
            [],
          );
          setInclusionOfferings(project, submittedOfferings).then(() => {
            window.open(`${route.url}/inclusions`);
            cancel();
          });
        }}
      >
        {loading && <Loader label="Retrieving offerings..." />}
        {!loading && (
          <>
            <Text>
              Please select all options that are applicable to the project.
            </Text>
            <div className={styles.offerings}>
              {Object.keys(groupedOfferings)
                .sort()
                .map(offering => (
                  <Field
                    label={toUpperCaseWords(offering)}
                    key={offering}
                    className={styles.group}
                  >
                    <Choice
                      name={offering}
                      options={groupedOfferings[offering].map(
                        (offering: Offering) => ({
                          label: offering.name,
                          value: offering,
                        }),
                      )}
                    />
                  </Field>
                ))}
            </div>
          </>
        )}
        <ButtonBar layout="right">
          <Button action={() => cancel()} label="Cancel" style="secondary" />
          <Button label="OK" type="submit" />
        </ButtonBar>
      </Form>
    </Modal>
  );
};

export { InclusionsPrompt };
export default InclusionsPrompt;
