import { Type } from "./type";

import Panel from "@app/models/panel";
import { gridRenderer } from "../helpers";

type OwnProps = {
  panel: Panel;
};

const ServiceTypes = ({ panel }: OwnProps) => {
  const types = panel.serviceTypes;

  return gridRenderer(types, Type, "type");
};

export { ServiceTypes };
export default ServiceTypes;
