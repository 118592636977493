import OEF, { Item } from "@app/models/oef";

import Project from "@app/models/project";

const generate = (project: Project): OEF => {
  const oef: OEF = new OEF();

  const { activePanelSets: panelSets } = project;

  oef.items = panelSets.reduce((items: Item[], panelSet) => {
    const { panel, backplate } = panelSet;
    items.push({
      partNumber: panelSet.panelPartNumber,
      reference: panel.mspReference,
      description: "AMAX PS TYPE",
      blankReference: panel.mspReference,
      pg: "PAMF",
      priceEach: panelSet.discountedTotalCosts.panel.unit,
      priceTotal: panelSet.discountedTotalCosts.panel.total,
      quantity: panel.quantity,
    });
    items.push({
      partNumber: backplate.partNumber,
      reference: panel.mspReference,
      description: "AMAX WB TYPE",
      blankReference: panel.mspReference,
      pg: "PAMF",
      priceEach: panelSet.discountedTotalCosts.backplate.unit,
      priceTotal: panelSet.discountedTotalCosts.backplate.total,
      quantity: panel.quantity,
    });

    return items;
  }, []);

  return oef;
};

export { generate };
