import { Image, Text } from "@bespohk/uikit/components";

import PanelSet from "@app/models/panel-set";
import Project from "@app/models/project";
import React from "react";
import { asset } from "@app/helpers/assets";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";

const cx = classNames.bind(styles);

type OwnProps = {
  project: Project;
  panelSet: PanelSet;
  isFactory?: boolean;
};

const Details = ({ project, panelSet, isFactory }: OwnProps) => {
  const { customer, salesOrderNumber, purchaseOrderNumber } = project;
  const { panel, backplate, panelPartNumber } = panelSet;
  const { drawingNumber, punchingLayout } = panel;
  const { partNumber: wallboxPartNumber } = backplate;

  return (
    <table className={cx({ outer: true, details: true, table: true })}>
      <thead>
        <tr>
          <th colSpan={5}>Copyright ESCO Industries</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td rowSpan={6} className={cx({ logo: true })}>
            <Image src={asset("/img/logo.svg")} />
          </td>
          <td>PART NUMBER</td>
          <td colSpan={3}>
            <Text className={styles.partNumber}>
              Panel Set Assembly Only: {panelPartNumber}
            </Text>
            <Text className={styles.partNumber}>
              Wall Box Assembly Only: {wallboxPartNumber}
            </Text>
            {isFactory && (
              <Text className={styles.partNumber}>
                Face Plate: {punchingLayout}
              </Text>
            )}
          </td>
        </tr>
        <tr>
          <td colSpan={3}>Customer Name: {customer.businessName}</td>
          <td>PO NUMBER: {purchaseOrderNumber}</td>
        </tr>
        <tr>
          <td className={cx({ large: true })}>SALES ORDER NUMBER</td>
          <td style={{ width: "15%" }}>{salesOrderNumber}</td>
          <td className={cx({ large: true })}>DRAWING NUMBER</td>
          <td style={{ width: "15%" }}>{drawingNumber}</td>
        </tr>
        <tr>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td colSpan={4}>
            ESCO INDUSTRIES - 89 EGERTON STREET, SILVERWATER, NSW 2128
          </td>
        </tr>
        <tr>
          <td colSpan={4}>
            P: 1300 879 363, F: 1300 879 364, E:
            customer.service@escoindustries.com.au
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export { Details };
export default Details;
