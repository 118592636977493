import { Button, Icons, Prompt, Text } from "@bespohk/uikit/dist/components";
import { Operations, operations } from "@app/state/ducks/project/operations";
import React, { useEffect, useState } from "react";

import Project from "@app/models/project";
import State from "@app/state";
import { TabularData } from "@app/containers";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";
import { toTitleCase } from "@bespohk/lib";
import { useOperations } from "@app/helpers/redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const cx = classNames.bind(styles);

const customerRenderer = (project: Project): string =>
  project.customer?.businessName;

const statusRenderer = (project: Project): string =>
  toTitleCase(project.status);

const Projects = () => {
  const [showPeek, setShowPeek] = useState<Project>(null);
  const [showDuplicate, setShowDuplicate] = useState<Project>(null);
  const { duplicate, listClear } = useOperations<Operations>(operations);
  const history = useHistory();
  const project = useSelector((state: State) => state.project.data);

  useEffect(() => {
    if (project) {
      listClear();
    }
  }, [project]);

  return (
    <>
      <TabularData
        type={Project}
        endpoint="/projects"
        query="{uuid,name,salesOrderNumber,customer,contact,status,updatedDate,enabled,revision,code,priority,totalPanels,purchaseOrderNumber}"
        filter={{
          placeholder: "Enter a project name or sales order to search...",
          builder: (q: string) => `(combined: ${q}, enabled:True)`,
        }}
        columns={[
          {
            key: "name",
            width: "20%",
            renderer: (project: Project) => (
              <Button
                label={project.name}
                type="text"
                action={() => setShowPeek(project)}
              />
            ),
          },
          { key: "salesOrderNumber", label: "S/O Number" },
          { key: "revision", width: "10%" },
          { key: "customer", renderer: customerRenderer },
          { key: "status", renderer: statusRenderer, width: "10%" },
          {
            key: "humanUpdatedAt",
            label: "Last Updated",
          },
        ]}
        actions={[
          {
            name: "History",
            icon: Icons.History,
            callback: (item: Project) => {
              history.push(`/projects/${item.uuid}/history`);
            },
          },
        ]}
      />
      <Prompt
        className={cx({ overview: true })}
        title="Overview"
        message={
          showPeek && (
            <>
              <Text className={cx({ text: true })}>
                Project Code:{" "}
                <span className={cx({ value: true })}>{showPeek.code}</span>
              </Text>
              <Text className={cx({ text: true })}>
                Purchase Order Number:{" "}
                <span className={cx({ value: true })}>
                  {showPeek.purchaseOrderNumber}
                </span>
              </Text>
              <Text className={cx({ text: true })}>
                Priority:{" "}
                <span className={cx({ value: true })}>{showPeek.priority}</span>
              </Text>
              <Text className={cx({ text: true })}>
                Contact:{" "}
                <span className={cx({ value: true })}>
                  {showPeek.contact ? showPeek.contact.name : null}
                </span>
              </Text>
              <Text className={cx({ text: true })}>
                Total Drawings:{" "}
                <span className={cx({ value: true })}>
                  {showPeek.totalPanels ? showPeek.totalPanels : null}
                </span>
              </Text>
            </>
          )
        }
        when={!!showPeek}
        positive={{
          label: "Duplicate",
          action: () => {
            setShowDuplicate(showPeek);
            setShowPeek(null);
          },
        }}
        negative={{ label: "Close", action: () => setShowPeek(null) }}
      />

      <Prompt
        className={cx({ overview: true })}
        title="Duplicate project"
        message={`Are you sure you wish to duplicate ${
          showDuplicate ? showDuplicate.name : null
        }?`}
        when={!!showDuplicate}
        positive={{
          label: "OK",
          action: () => {
            // eslint-disable-next-line
            // @ts-ignore TODO: resolve this
            duplicate(showDuplicate).then(dupe => {
              history.push(`/projects/${dupe.uuid}`);
            });
            setShowDuplicate(null);
          },
        }}
        negative={{ label: "Cancel", action: () => setShowDuplicate(null) }}
      />
    </>
  );
};

export { Projects };
export default Projects;
