import {
  Button,
  ButtonBar,
  Heading,
  Modal,
  Select,
  Text,
} from "@bespohk/uikit/components";
import React, { useState } from "react";

import Component from "@app/models/component";
import State from "@app/state";
import { dataCount } from "@app/helpers/components";
import styles from "./styles.modules.css";
import { useSelector } from "react-redux";

type OwnProps = {
  component: Component;
  show: boolean;
  confirm: (values?) => void;
  cancel: () => void;
};

const Data = ({ show, confirm, cancel, component }: OwnProps) => {
  const data = useSelector((state: State) => state.panelBuilder.lookups.data);

  const availableData: any[] = data
    .find(d => d.matches(component))
    .splitOptions.map(option => ({ value: option, label: option }));

  const count: number[] = dataCount(component);
  const [values, setValues] = useState(
    component.data ? component.data.split(",") : count.map(_ => null),
  );

  return (
    <Modal when={show} className={styles.modal}>
      <Heading size="smaller">Set Component Data</Heading>
      <Text className={styles.data}>
        Select component options below for {component.shortDescription}:
      </Text>

      <div className={styles.select}>
        {count.map(i => (
          <Select
            autoFocus
            key={i}
            name={`${i}`}
            value={values[i - 1]}
            placeholder="Select an option"
            typeThreshold={0}
            searchThreshold={0}
            options={availableData}
            onChange={(name, value) => {
              const newValues: number[] = values.map((v, index) =>
                index === i - 1 ? value : v,
              );
              setValues(newValues);
            }}
          />
        ))}
      </div>

      <ButtonBar>
        <Button label="Cancel" action={cancel} style="secondary" />
        <Button label="Confirm" action={() => confirm(values)} />
      </ButtonBar>
    </Modal>
  );
};

export { Data };
export default Data;
