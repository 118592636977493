import { BooleanColumnRenderer } from "@app/components";
import Contact from "@app/models/contact";
import React from "react";
import { TabularData } from "@app/containers";
import Territory from "@app/models/territory";

type OwnProps = {};

const enabledRenderer = (contact: Contact): React.ReactNode => (
  <BooleanColumnRenderer valid={contact.enabled} />
);

const stateRenderer = (contact: Contact): React.ReactNode =>
  Territory[contact.state];

const Contacts = (_: OwnProps) => (
  <TabularData
    type={Contact}
    endpoint="/contacts"
    filter={{
      builder: (q: string) => `(name: ${q})`,
    }}
    columns={[
      { key: "name" },
      { key: "suburb" },
      { key: "state", renderer: stateRenderer },
      { key: "email" },
      { key: "phoneNumber" },
      { key: "enabled", renderer: enabledRenderer },
    ]}
  />
);

export { Contacts };
export default Contacts;
