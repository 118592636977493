import React from "react";
import classNames from "classnames/bind";
import { rows as generateRows } from "@app/helpers/components";
import styles from "./styles.modules.css";

const cx = classNames.bind(styles);

type Row = {
  startsRow?: boolean;
};

const gridRenderer = (
  data: Row[],
  ItemRenderer: any,
  key: string,
  className: string = null,
  additionalProps: any = {},
): any => {
  const rows: any[][] = generateRows(data);

  return (
    <div className={cx({ grid: true, [className]: !!className })}>
      {rows.map((row, rowIndex) => (
        <div key={rowIndex} className={cx({ row: true })}>
          {row.map((item, itemIndex) => (
            <ItemRenderer
              key={`${item.id || item.uuid}-${itemIndex}`}
              {...{ [key]: item }}
              {...additionalProps}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export { gridRenderer, generateRows };
