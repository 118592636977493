const locale = "en-AU";

const defaults = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

const formatter = new Intl.NumberFormat(locale, {
  style: "currency",
  currencyDisplay: "narrowSymbol",
  currency: "AUD",
  ...defaults,
});

const roundingFormatter = new Intl.NumberFormat(locale, defaults);

const rounding = (amount: number): number => {
  return parseFloat(roundingFormatter.format(amount).replace(",", ""));
};

export { formatter, rounding };
