import { Error, Timestamps, mixin } from "./common";

import Component from "./component";
import PanelType from "./panel-type";
import Series from "./series";
import { hydrate } from "@bespohk/lib";

class Definition {
  readonly uuid: string;
  description: string;
  code: string;
  size: number;
  @hydrate
  panelType: PanelType;
  @hydrate(Series)
  series: Series[];
  gasOnly?: boolean;
  ncOnly?: boolean;
  merlonNcOnly?: boolean;
  @hydrate
  createdDate?: Date;
  @hydrate
  updatedDate?: Date;

  public hasSeries(series: Series): boolean {
    return this.series.reduce((p: boolean, s) => {
      if (series.id === s.id) {
        return true;
      }
      return p;
    }, false);
  }

  public toString(): string {
    return this.description;
  }
}

interface Grid extends Timestamps {} // eslint-disable-line @typescript-eslint/no-empty-interface

mixin(Definition, [Timestamps]);

class Grid extends Definition {
  components: Component[] = [];
  width = 0;

  public get humanWidth(): string {
    return `${this.width}mm`;
  }

  public get startsRow(): boolean {
    return this.components.length && this.components[0].startsRow;
  }

  public get isValid(): boolean {
    return this.width % 2 === 0;
  }
}

class Container {
  grids: Grid[];

  public errors(): Error[] {
    const errors: Error[] = [];

    this.grids.forEach((grid: Grid, index) => {
      if (!grid.isValid) {
        errors.push({
          message: `Grid must be a multiple of 50, ensure you've added spacers where required at grid ${index +
            1}`,
          skippable: false,
        });
      }
    });
    return errors;
  }
}

export { Grid, Definition, Container };

export default Grid;
