import React from "react";
import { lookup } from "@app/helpers/api";
import Component from "@app/models/component";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";
import { Component as ComponentEl } from "@app/components/preview/components";
import Series from "@app/models/series";
import { categoryOptions } from "@app/helpers/form";
import { Alert, Heading, Select } from "@bespohk/uikit/components";

type OwnProps = {
  series: Series;
  onClick: (component: Component) => any;
};

const cx = classNames.bind(styles);

const ComponentViewer = ({ onClick, series }: OwnProps) => {
  const [components, setComponents] = React.useState<Component[]>([]);
  const [category, setCategory] = React.useState<string>();

  React.useEffect(() => {
    if (category) {
      const seriesQuery = `{*,series(id:${series.id}){*}}`;
      const query = `(enabled:True,category:${category})${seriesQuery}&limit=1000`;
      lookup(
        Component,
        "/components",
        lookups => {
          const foundComponents = lookups.results;
          foundComponents.sort((a: Component, b: Component) =>
            a.description > b.description ? 1 : -1,
          );
          setComponents(foundComponents);
        },
        () => query,
      )();
    }
  }, [category]);

  return (
    <div
      style={{ minWidth: document.body.clientWidth / 2 }}
      className={cx("outer")}
    >
      <Heading size="small" value="Select component..." />
      <Select
        name="category"
        options={categoryOptions}
        openOnFocus
        onChange={(_, value) => setCategory(value)}
      />
      {!components.length && (
        <Alert
          type="info"
          message="Please select from the categories above to search for a specific component."
        />
      )}
      <div className={cx("wrapper")}>
        {components.map(component => (
          <div key={component.uuid} onClick={() => onClick(component)}>
            <ComponentEl component={component} />
          </div>
        ))}
      </div>
    </div>
  );
};

export { ComponentViewer };
export default ComponentViewer;
