import { ApiErrors, get, del as httpDel, post, put } from "@app/helpers/api";
import {
  clear as clearAction,
  create as createAction,
  createError,
  createSuccess,
  deleteError,
  deleteRequest,
  deleteSuccess,
  fetch as fetchAction,
  fetchError,
  fetchSuccess,
  update as updateAction,
  updateError,
  updateSuccess,
} from "./actions";

import { Dispatch } from "redux";
import { operations as alertOperations } from "../alerts/operations";

const { add } = alertOperations;

const fetch = (type: any, path: string): any => {
  return (dispatch: Dispatch<any>): any => {
    dispatch(fetchAction());

    return get(path, type)
      .then(data => {
        dispatch(fetchSuccess(data));
      })
      .catch(() => dispatch(fetchError()));
  };
};

const create = (path: string, data: any, type: any): any => {
  return (dispatch: Dispatch<any>): any => {
    dispatch(createAction());
    return post(path, data, type)
      .then(resource => {
        dispatch(createSuccess(resource));
        add("success", `Created ${resource} successfully`, 5)(dispatch);
      })
      .catch((errors: ApiErrors) => {
        dispatch(createError());
        add(
          "error",
          "Failed to create item, please correct any errors and try again",
          5,
        )(dispatch);
        throw errors;
      });
  };
};

const update = (path: string, data: any, type: any): any => {
  return (dispatch: Dispatch<any>): any => {
    dispatch(updateAction());
    return put(path, data, type)
      .then(resource => {
        dispatch(updateSuccess(resource));
        add("success", `Updated ${resource} successfully`, 5)(dispatch);
      })
      .catch((errors: ApiErrors) => {
        dispatch(updateError());
        add(
          "error",
          "Failed to update item, please correct any errors and try again",
          5,
        )(dispatch);
        throw errors;
      });
  };
};

const clear = () => {
  return (dispatch: Dispatch<any>): any => {
    dispatch(clearAction());
  };
};

const del = (path: string): any => (dispatch: Dispatch<any>) => {
  dispatch(deleteRequest());
  return httpDel(path)
    .then(() => {
      dispatch(deleteSuccess());
    })
    .catch(_ => {
      dispatch(deleteError());
    });
};

type Operations = {
  fetch: (type: any, path: string) => void;
  clear: () => void;
  update: (path: string, data: any, type: any) => void;
  create: (path: string, data: any, type: any) => void;
  del: (path: string) => void;
};

const operations: Operations = {
  fetch,
  clear,
  update,
  create,
  del,
};

export { fetch, create, update, clear, operations, Operations };
