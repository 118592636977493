import {
  clear as clearAction,
  fetch as fetchAction,
  fetchError,
  fetchSuccess,
} from "./actions";

import { Dispatch } from "redux";
import { Pagination } from "../types";
import { get } from "@app/helpers/api";

const fetch = <T>(type: any, path: string, offset = 0, limit = 10): any => {
  return (dispatch: Dispatch<any>): any => {
    dispatch(fetchAction());

    const joiner = path.indexOf("?") > -1 ? "&" : "?";

    path += `${joiner}offset=${offset}&limit=${limit}`;

    return get(path, type)
      .then((data: Pagination<any>) => {
        dispatch(fetchSuccess<T, any>(data));
      })
      .catch(() => dispatch(fetchError()));
  };
};

const exportCsv = (path: string): any => async (
  _: Dispatch<any>,
): Promise<string> => {
  const joiner = path.indexOf("?") > -1 ? "&" : "?";
  path += `${joiner}offset=0&limit=1000`;

  return get(path, null, { headers: { accept: "text/csv" } })
    .then(csv => {
      return csv;
    })
    .catch(() => {
      // Dispatch an error
      return null;
    });
};

const clear = (): any => {
  return (dispatch: Dispatch<any>): any => {
    dispatch(clearAction());
  };
};

type Operations = {
  fetch: (
    type: any,
    path: string,
    offset?: number,
    limit?: number,
  ) => Dispatch<any>;
  clear: () => void;
  exportCsv: (path: string) => Promise<string>;
};

const operations: Operations = {
  fetch,
  clear,
  exportCsv,
};

export { fetch, operations, Operations };
