import Panel from "@app/models/panel";
import Project from "@app/models/project";
import React from "react";
import classNames from "classnames/bind";
import { ifValue } from "@app/helpers/strings";
import styles from "./styles.modules.css";

const cx = classNames.bind(styles);

type OwnProps = {
  project: Project;
  panel: Panel;
};

const Title = ({ panel, project }: OwnProps) => {
  const { panelType, quantity, mspReference, plateFinish, location } = panel;
  const { name } = project;
  return (
    <>
      <table className={cx({ outer: true, title: true, table: true })}>
        <thead>
          <tr>
            <th colSpan={3}>
              {panelType.name} - {panelType.description}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>MSP REF: {mspReference}</td>
            <td>QTY: {quantity}</td>
            <td>REV: {panel.revision}</td>
          </tr>
          <tr>
            <td>PROJECT: {name}</td>
            <td colSpan={2}>PLATE FINISH: {plateFinish.name}</td>
          </tr>
          <tr>
            <td colSpan={3}>LOCATION: {ifValue(location, "N/A")}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export { Title };
export default Title;
