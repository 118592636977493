import PanelSet, { SubAssembly } from "@app/models/panel-set";

import { Container } from "@app/models/grid";
import Engraving from "@app/models/engraving";
import { Lookups } from "./types";
import Material from "@app/models/material";
import Panel from "@app/models/panel";
import Screw from "@app/models/screw";
import Wiring from "@app/models/wiring";
import { generate as generateBackplate } from "./backplate";
import { generate as generateGrids } from "./grid";
import { isPan } from "@app/helpers/components";
import { nearestMultiple } from "@app/helpers/maths";

const generateFrontPlateSubAssemblies = (
  panel: Panel,
  materials: Material[],
  engravings: Engraving[],
  screws: Screw[],
): SubAssembly[] => {
  const subAssemblies: SubAssembly[] = [];

  if (panel.hasComponents) {
    // Materials
    const material: Material = materials.find(
      material => material.hasFinish(panel.plateFinish) && material.enabled,
    );
    if (material) {
      const panelDimensions: number = panel.width * panel.height;
      subAssemblies.push({
        itemCode: material.partNumber,
        description: material.description,
        quantity: (panelDimensions + panelDimensions * 0.05) / 1000000,
      });
    }

    // Engravings
    const engraving: Engraving = engravings.find(
      engraving => engraving.enabled,
    );
    subAssemblies.push({
      itemCode: engraving.partNumber,
      description: engraving.description,
      quantity:
        1 + // Always have an additional 1
        1 + // Alphamax Logo
        panel.engravedLines.length + // Engraved position
        panel.components.filter(component => isPan(component, panel.series))
          .length *
          2, // GPO with Neon * 2
    });

    // Screws
    const screw: Screw = screws.find(
      screw => screw.enabled && screw.type === "front_plate",
    );
    if (screw) {
      const screwCount = nearestMultiple(panel.width / 75, 2);
      let requiredScrews = 0;
      if (panel.isVertical) {
        requiredScrews =
          screwCount +
          nearestMultiple((panel.rows.length - 1) * (panel.width / 150), 2);
      } else {
        requiredScrews = screwCount < 6 ? 6 : screwCount;
      }

      subAssemblies.push({
        itemCode: screw.partNumber,
        description: screw.description,
        quantity: requiredScrews,
      });
    }
  }

  return subAssemblies;
};

const generatePanelSubAssemblies = (
  panel: Panel,
  grids: Container,
  wirings: Wiring[],
): SubAssembly[] => {
  const subAssemblies: SubAssembly[] = [];
  if (panel.hasComponents) {
    // Grid kits
    grids.grids.forEach(grid => {
      subAssemblies.push({
        itemCode: `${grid.code}-KIT`,
        description: `${grid.code}-KIT`,
        quantity: 1,
      });
    });

    // Wirings
    /*
    Quantity equals – Number of GPO’s and RCD for each circuit (if RCD present) minus 1
    Example 1 - if we have 3 x GPO’s and 1 x RCD in one circuit and 4 x GPO’s and 1 x RCD in another circuit total quantity will be 7
    Example 2 – if we have two circuits, both with 3 x GPO but no RCD’s, qty will be 4 (3 x GPO – 1, 3 x GPO – 1)
    Example 3 – if we have standalone RCD/RCD’s with no GPO to its left or right, qty will be 0. These parts only applicable when GPO is present
    */

    const { circuits } = panel;
    const activeNeutralWiringQuantity = Object.values(circuits).reduce(
      (total, current) => {
        total += current.total;
        return total;
      },
      0,
    );
    // Wirings: Active
    const activeWiring = wirings.find(
      wiring =>
        wiring.type === "active" &&
        wiring.gpoCentres.indexOf(panel.gpoCentre) > -1,
    );
    if (activeWiring) {
      subAssemblies.push({
        itemCode: activeWiring.partNumber,
        description: activeWiring.description,
        quantity: activeNeutralWiringQuantity,
      });
    } else {
      // warn("There is no active wiring available");
    }

    // Wirings: Neutral
    const neutralWiring = wirings.find(
      wiring =>
        wiring.type === "neutral" &&
        wiring.gpoCentres.indexOf(panel.gpoCentre) > -1,
    );
    if (neutralWiring) {
      subAssemblies.push({
        itemCode: neutralWiring.partNumber,
        description: neutralWiring.description,
        quantity: activeNeutralWiringQuantity,
      });
    } else {
      // warn("There is no neural wiring available");
    }

    const bodyProtected = panel.protectionOther.toString() === "body_protected";
    const cardiacProtected =
      panel.protectionOther.toString() === "cardiac_protected";
    // Wirings: Earth
    /*
    One earth wire needed per group of GPO’s (a group can be identified as a circuit). Earth wire differs between body protected and cardiac protected, so Morris will need to first identify what protection it is and then nominate the correct part number below
    Example 1 – The panel has 4 x GPO’s at 50mm centres and is Body Protected – the 4 way earth loop should be added to the BOM - Z04B074
    Example 2 – The panel has 4 x Red GPO’s and 4 x White GPO’s at 75mm centres (two groups/circuits) and is Body Protected – the 4 way earth loop should be added to the BOM with a qty of 2 - Z04B074
    Example 3 – The panel has 6 x GPO’s at 50mm centres and is Cardiac Protected – the 6 way earth loop should be added to the BOM - Z04B197
    RCD’s don’t need to be factored in to any rule/formula
    */
    // TODO: Fix this calculation
    const way = 0;
    const earthWiringQuantity = 1;

    const earthWiring = wirings.find(
      wiring =>
        wiring.type === "earth" &&
        wiring.bodyProtected === bodyProtected &&
        wiring.cardiacProtected === cardiacProtected &&
        wiring.way === way &&
        wiring.gpoCentres.indexOf(panel.gpoCentre) > -1,
    );
    if (earthWiring) {
      subAssemblies.push({
        itemCode: earthWiring.partNumber,
        description: earthWiring.description,
        quantity: earthWiringQuantity,
      });
    } else {
      // warn("There was no earth wiring found");
    }
  }

  return subAssemblies;
};

const generate = (panel: Panel, lookups: Lookups): PanelSet => {
  const {
    hbars,
    wallboxes,
    grids,
    materials,
    engravings,
    wirings,
    clampRails,
    screws,
    strappings,
    popRivets,
    dividers,
  } = lookups;
  const panelSet = new PanelSet();
  if (panel.plateFinish) {
    panel.hbar = hbars.find(
      hbar => hbar.plateFinish.id === panel.plateFinish.id,
    );
  }
  panelSet.panel = panel;
  panel.engravedLines; // Generate the engraved lines, should probably have this done elsewhere
  panelSet.frontPlateSubAssemblies = generateFrontPlateSubAssemblies(
    panel,
    materials,
    engravings,
    screws,
  );
  panelSet.backplate = generateBackplate(
    panel,
    wallboxes,
    clampRails,
    screws,
    strappings,
    popRivets,
    dividers,
  );
  panelSet.gridContainer = generateGrids(panelSet.backplate, grids, wallboxes);
  panelSet.panelSubAssemblies = generatePanelSubAssemblies(
    panel,
    panelSet.gridContainer,
    wirings,
  );

  return panelSet;
};

export { generate };
