import {
  Button,
  Field,
  Form,
  Icons,
  Input,
  Pagination,
  Prompt,
  Table,
} from "@bespohk/uikit/components";
import { InputSuffixButton, NavigationButton } from "@app/components";
import { Operations, operations } from "@app/state/ducks/resources/operations";
import React, { Fragment, useEffect, useState } from "react";
import {
  Operations as ResourceOperations,
  operations as resourceOperations,
} from "@app/state/ducks/resource/operations";
import { download } from "@app/helpers/browser";

import { FilterBar } from "@app/components";
import State from "@app/state";
import { createSelector } from "reselect";
import { useHistory } from "react-router-dom";
import { useOperations } from "@app/helpers/redux";
import { useSelector } from "react-redux";

type Data<T> = T;

type Column = {
  label?: string;
  key: string;
  width?: string;
  renderer?: (data: Data<any>) => React.ReactNode | string;
};

type Action = {
  name: string;
  icon: Icons;
  callback: (item: any) => void;
};

type Filter = {
  placeholder?: string;
  export?: boolean;
  builder?: (q: string) => string;
};

type OwnProps = {
  filter?: Filter | boolean;
  columns: Column[];
  actions?: Action[];
  type: any;
  endpoint: string;
  identifier?: string;
  path?: string;
  query?: string;
  perPage: number;
};

const resultsSelector = (state: State) => state.resources;

const tabularDataSelector = createSelector(
  resultsSelector,
  resources => resources,
);

const columnKeysInData = (columns: Column[], data: any[]): boolean => {
  if (!data.length) {
    return false;
  }
  return columns.reduce((exists: boolean, column) => {
    if (column.key === "actions") {
      return exists;
    }
    if (!(column.key in data[0])) {
      exists = false;
    }
    return exists;
  }, true);
};

const TabularData = ({
  filter,
  columns,
  actions,
  type,
  endpoint,
  query,
  path,
  identifier,
  perPage,
}: OwnProps) => {
  const { fetch, exportCsv } = useOperations<Operations>(operations);
  const { del } = useOperations<ResourceOperations>(resourceOperations);
  const { data, loading } = useSelector(tabularDataSelector);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [askDelete, setAskDelete] = useState(false);
  if (!columnKeysInData(columns, data.results)) {
    // Required due to shared resources duck, lifecycle doesn't apply
    // switching between pages causes the table to be populated with pre-existing
    // data as the resources data is a single duck.
    // Requires that the keys for the columns DO exist on the data
    data.results = [];
  }
  const fetchEndpoint = `${endpoint}${query ? `?query=${query}` : ""}`;

  useEffect(() => {
    fetch(type, fetchEndpoint, 0, perPage);
    return () => {
      setCurrentPage(0);
    };
  }, [endpoint]);

  const { builder } = filter as Filter;

  const pagination = filter => {
    const url = filter
      ? `${endpoint}?query=${builder(filter)}${query ? query : "{*}"}`
      : fetchEndpoint;

    return (
      <Pagination
        total={data.count}
        current={currentPage}
        maxItems={perPage}
        maxPages={2}
        useIcons
        onChangePage={page => {
          if (page === currentPage) {
            return;
          }
          setCurrentPage(page);
          fetch(type, url, (page - 1) * perPage, perPage);
        }}
      />
    );
  };
  const pathOrEndpoint: string = path || endpoint;
  const defaultActions: Action[] = [
    {
      name: "Edit",
      icon: Icons.Edit,
      callback: item => history.push(`${pathOrEndpoint}/${item[identifier]}`),
    },
    {
      name: "Delete",
      icon: Icons.Trash,
      callback: item => setAskDelete(item),
    },
  ];

  const combinedActions: Action[] = [...defaultActions, ...(actions || [])];

  if (!columns.find(column => column.key === "actions")) {
    columns.push({
      key: "actions",
      label: " ",
      width: "15%",
      renderer: data => {
        return (
          <Fragment>
            {combinedActions
              .filter(action => {
                if (
                  action.name === "Delete" &&
                  columns.find(column => column.key === "isActive") &&
                  !data["isActive"]
                ) {
                  return false;
                }

                return true;
              })
              .map(action => (
                <NavigationButton
                  key={action.name}
                  size="small"
                  icon={action.icon}
                  label={action.name}
                  path={() => action.callback(data)}
                />
              ))}
          </Fragment>
        );
      },
    });
  }

  return (
    <>
      <Form
        onSubmit={values => {
          const url = values.filter
            ? `${endpoint}?query=${builder(values.filter)}${
                query ? query : "{*}"
              }`
            : endpoint;
          fetch(type, url);
        }}
      >
        {({ values }) => (
          <>
            <FilterBar>
              <Field label={null}>
                <Input
                  name="filter"
                  placeholder={filter ? (filter as Filter).placeholder : null}
                  autoFocus
                  prefix="Filter"
                  suffix={<InputSuffixButton icon={Icons.Search} />}
                />
              </Field>
              {!!filter && (
                <>
                  <Button
                    label="Create"
                    action={() => history.push(`${endpoint}/new`)}
                  />
                  {(filter as Filter).export && (
                    <Button
                      label="Export"
                      style="tertiary"
                      action={async () => {
                        const output = await exportCsv(endpoint);
                        if (output) {
                          download(
                            output,
                            `${type.name.lower()}-export-${Date.now()}.csv`,
                            "text/csv",
                          );
                        }
                      }}
                    />
                  )}
                </>
              )}
            </FilterBar>
            {pagination(values.filter)}
            <Table loading={loading} columns={columns} data={data.results} />
            {pagination(values.filter)}
          </>
        )}
      </Form>
      <Prompt
        title="Delete?"
        message="Are you sure you want to delete this record?"
        when={askDelete}
        positive={{
          label: "Yes",
          action: async () => {
            await del(`${endpoint}/${askDelete[identifier]}`);
            fetch(type, fetchEndpoint, currentPage, perPage);
            setAskDelete(null);
          },
        }}
        negative={{
          label: "No",
          action: () => setAskDelete(null),
        }}
      />
    </>
  );
};

TabularData.defaultProps = {
  filter: {
    placeholder: "Enter the value to filter by...",
    export: false,
  },
  identifier: "uuid",
  perPage: 10,
} as OwnProps;

export { TabularData };
export default TabularData;
