import Component from "@app/models/component";
import React from "react";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";
import { ServiceType } from "@app/models/type";

const cx = classNames.bind(styles);

const componentsWidth = (components: Component[]): number =>
  components.reduce((width, component) => {
    width += component.width;
    return width;
  }, 0);

type OwnProps = {
  type: ServiceType;
};

const Type = ({ type }: OwnProps) => {
  const style = {
    width: `${componentsWidth(type.components)}px`,
  };
  return (
    <div style={style} className={cx({ type: true })}>
      {type.name}
    </div>
  );
};

export { Type };
export default Type;
