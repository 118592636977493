import { BooleanColumnRenderer } from "@app/components";
import React from "react";
import { TabularData } from "@app/containers";
import { Wallbox } from "@app/models/wallbox";

const gasOnlyRenderer = (wallbox: Wallbox): React.ReactNode => (
  <BooleanColumnRenderer valid={wallbox.gasOnly} />
);

const Wallboxes = () => (
  <TabularData
    type={Wallbox}
    endpoint="/wallboxes"
    filter={{
      placeholder: "Enter a code or description to search for...",
      builder: (q: string) => `(name: ${q})`,
    }}
    columns={[
      { key: "code" },
      { key: "humanCost", label: "Cost" },
      { key: "gang" },
      { key: "width" },
      { key: "gasOnly", renderer: gasOnlyRenderer, width: "10%" },
      { key: "humanUpdatedAt", label: "Last Updated" },
    ]}
  />
);

export { Wallboxes };
export default Wallboxes;
