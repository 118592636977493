import { BooleanColumnRenderer } from "@app/components";
import Customer from "@app/models/customer";
import React from "react";
import { TabularData } from "@app/containers";

type OwnProps = {};

const enabledRenderer = (customer: Customer): React.ReactNode => (
  <BooleanColumnRenderer valid={customer.enabled} />
);

const Customers = (_: OwnProps) => (
  <TabularData
    type={Customer}
    endpoint="/customers"
    filter={{
      builder: (q: string) => `(businessName: ${q})`,
    }}
    columns={[
      { key: "businessName", label: "Name" },
      { key: "accountCode" },
      { key: "enabled", renderer: enabledRenderer, width: "10%" },
      {
        key: "humanUpdatedAt",
        label: "Last Updated",
      },
    ]}
  />
);

export { Customers };
export default Customers;
