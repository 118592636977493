import { Image, Text, Wrapper } from "@bespohk/uikit/components";
import { Operations, operations } from "@app/state/ducks/resources/operations";
import React, { useEffect } from "react";

import Inclusion from "@app/models/inclusion";
import { Offering } from "@app/models/project";
import Section from "../section";
import State from "@app/state";
import { asset } from "@app/helpers/assets";
import classNames from "classnames/bind";
import { groupOfferings } from "@app/helpers/projects";
import styles from "./styles.modules.css";
import { toUpperCaseWords } from "@bespohk/lib";
import { useOperations } from "@app/helpers/redux";
import { useSelector } from "react-redux";
import { valueExistsInArrayOfObjects } from "@app/helpers/objects";

const cx = classNames.bind(styles);

type OwnProps = {
  inclusion: Inclusion;
};

const Cover = ({ inclusion }: OwnProps) => {
  const { fetch } = useOperations<Operations>(operations);

  useEffect(() => {
    fetch(Offering, "/projects/options", 0, 500);
  }, []);

  const offerings = useSelector((state: State) =>
    state.resources.loading ? [] : state.resources.data.results,
  );

  if (!offerings.length) {
    return null;
  }

  const groupedOfferings = groupOfferings(offerings);

  return (
    <div className={cx({ outer: true })}>
      <Image src={asset("/img/logo.svg")} className={cx({ logo: true })} />
      <Wrapper className={styles.wrapper} grid={[1, 1]}>
        <div>
          <Section label="Our product offering and what is included in this quotation:" />
          {Object.keys(groupedOfferings)
            .sort()
            .map(type => (
              <Wrapper
                key={type}
                grid={[3, 1]}
                className={cx({ offerings: true })}
              >
                <div>
                  {groupedOfferings[type].map(offering => (
                    <div
                      key={offering.id}
                      className={cx({
                        offering: true,
                        enabled: valueExistsInArrayOfObjects(
                          "id",
                          offering,
                          inclusion.project.offerings,
                        ),
                      })}
                    >
                      {offering.name}
                      <div className={cx({ check: true })}>
                        {valueExistsInArrayOfObjects(
                          "id",
                          offering,
                          inclusion.project.offerings,
                        )
                          ? "✔"
                          : null}
                      </div>
                    </div>
                  ))}
                </div>
                <div className={cx({ lowlight: true, type: true })}>
                  {toUpperCaseWords(type)}
                </div>
              </Wrapper>
            ))}
        </div>
        <div>
          <Section label="Your quote explained:" />
          <Wrapper className={cx({ explainDetails: true })}>
            <Text>Quote No: {inclusion.project.salesOrderNumber}</Text>
            <Text>Rev: REV {inclusion.project.revision}</Text>
            <Text>
              ESCO point of contact for this quote:{" "}
              {inclusion.project.point.fullName}
            </Text>
          </Wrapper>
          <Text className={cx({ explanation: true })}>
            * THIS PAGE - summarises the following documents and displays an
            exploded view of a recessed medical services panel (MSP) to help
            illustrate the differentiation of part numbers listed on the
            following page - INCLUSIONS. To the left shows you what product
            categories ESCO / MediTek can offer and what has been included in
            this quotation. The more product we can bundle the more competitive
            our offer
          </Text>

          <Text className={cx({ explanation: true })}>
            * INCLUSIONS - This sheet highlights what has been included in the
            price of our MSP's. It also provides the part numbers that make up
            the complete assembly - panel set assembly only and wall box
            assembly only as highlighted in the exploded view below. ESCO
            Industries will supply and invoice in parts as per the inclusion
            sheet (not as a complete assembly) to fit in with construction
            programs and installation practicality. We prefer purchase orders to
            reference the part numbers listed in the inclusion sheet and
            quotation, but we will accept purchase orders referencing the
            'Customer Reference' only, however reconciliation of invoices will
            be the responsibility of the buyer
          </Text>

          <Text className={cx({ explanation: true })}>
            * QUOTATION - ESCO - Our system generated quotation including key
            information like quote reference number, validity, and pricing for
            all product priced for the project. This will include all product
            selected to the left under the ESCO brand. Please note, the MSP's
            listed on the inclusion sheet are also shown in this quotation.
          </Text>

          <Text className={cx({ explanation: true })}>
            * QUOTATION - MEDITEK (if applicable) - Our system generated
            quotation including key information like quote reference number,
            validity, and pricing for all product priced for the project. This
            will include all product selected to the left under the MediTek
            brand. Please note, all product listed in the MediTek quotation is
            IN ADDITION to what has been priced by ESCO and required for your
            project
          </Text>
          <Image
            src={`${process.env.ASSET_BASE}/img/exploded-view.svg`}
            className={cx({ explodedView: true })}
          />
        </div>
      </Wrapper>
      <div className={cx({ footer: true })}>
        ESCO INDUSTRIES - 89 EGERTON STREET, SILVERWATER, NSW 2128 - P: 1300 879
        363, F: 1300 879 364, E: customer.service@escoindustries.com.au
      </div>
    </div>
  );
};

export { Cover };
export default Cover;
