import { Data } from "@app/models/component";
import React from "react";
import { TabularData } from "@app/containers";

const ComponentDatas = () => (
  <TabularData
    type={Data}
    endpoint="/components/datas"
    identifier="id"
    columns={[
      { key: "typeOrEquipmentCode", label: "Type", width: "20%" },
      { key: "options" },
    ]}
    filter={{
      export: true,
      placeholder: "Enter a type to search for...",
      builder: (q: string) => `(typeOrEquipmentCode: ${q})`,
    }}
  />
);

export { ComponentDatas };
export default ComponentDatas;
