import { Button, Icon, Icons, Select } from "@bespohk/uikit/components";
import {
  Operations,
  operations,
} from "@app/state/ducks/panel-builder/operations";
import React, { useState } from "react";

import Component from "@app/models/component";
import { default as DataSelection } from "./data";
import State from "@app/state";
import classNames from "classnames/bind";
import { requiresData as needsData } from "@app/helpers/components";
import styles from "./styles.modules.css";
import { useOperations } from "@app/helpers/redux";
import { useSelector } from "react-redux";

const cx = classNames.bind(styles);

type OwnProps = {
  order: number;
  show?: boolean;
  component: Component;
  coords: number[];
};

type ButtonProps = {
  icon: Icons;
  action: any;
  active?: boolean;
  label: string;
};

const OptionButton = ({ icon, action, active, label }: ButtonProps) => (
  <div className={styles.buttonWrapper}>
    <Button
      className={styles.button}
      label={
        <Icon name={icon} className={cx({ icon: true, active: active })} />
      }
      style="custom"
      action={action}
    />
    <div className={styles.tooltip}>{label}</div>
  </div>
);

const Options = ({ order, component, show, coords }: OwnProps) => {
  const [dataIsOpen, setDataIsOpen] = useState(false);
  const { spacers, data } = useSelector(
    (state: State) => state.panelBuilder.lookups,
  );
  const panel = useSelector((state: State) => state.panelBuilder.panel);
  const {
    addComponent,
    removeComponent,
    modifyComponent,
    replaceComponent,
  } = useOperations<Operations>(operations);

  const requiresData = needsData(component, data);

  return (
    <>
      <div
        className={cx({ options: true, show })}
        style={{ left: coords[0], top: coords[1] }}
      >
        <div className={styles.arrow} />
        <div className={styles.description}>
          {component.shortDescription} ({component.width}mm)
        </div>
        <div className={styles.actions}>
          <OptionButton
            icon={Icons.Plus}
            label="Add spacer to left"
            action={() =>
              addComponent(
                spacers[0],
                panel.series,
                panel.panelType,
                panel.plateFinish,
                order,
              )
            }
          />
          <OptionButton
            label="Remove"
            icon={Icons.Trash}
            action={() => removeComponent(order)}
          />
          {(order > 0 || component.startsRow) && (
            <OptionButton
              icon={Icons.SortAmountDown}
              label="Start row"
              active={component.startsRow}
              action={() =>
                modifyComponent(component, order, order, {
                  rowStart: !component.rowStart,
                })
              }
            />
          )}
          {order > 0 && (
            <OptionButton
              icon={Icons.Expand}
              label="Toggle Wallbox End"
              action={() =>
                modifyComponent(component, order, order, {
                  forcedWallboxEnd: !component.forcedWallboxEnd,
                })
              }
              active={component.forcedWallboxEnd}
            />
          )}
          <OptionButton
            icon={Icons.Pause}
            label="Hbar"
            action={() =>
              modifyComponent(component, order, order, {
                showHbar: !component.showHbar,
              })
            }
            active={component.showHbar}
          />
          <OptionButton
            icon={Icons.Braille}
            label="Divider"
            action={() =>
              modifyComponent(component, order, order, {
                forcedDivider: !component.forcedDivider,
              })
            }
            active={component.forcedDivider}
          />
          <OptionButton
            icon={Icons.Film}
            label="Toggle Engraved Line"
            action={() => {
              modifyComponent(component, order, order, {
                showEngravedLineBefore: !component.showEngravedLineBefore,
              });
            }}
            active={component.showEngravedLineBefore}
          />

          {(panel.isVertical || component.showEngravedLineTop) && (
            <OptionButton
              icon={Icons.Water}
              label="Toggle Engraved Line On Top"
              action={() => {
                modifyComponent(component, order, order, {
                  showEngravedLineTop: !component.showEngravedLineTop,
                });
              }}
              active={component.showEngravedLineTop}
            />
          )}
          {requiresData && (
            <OptionButton
              label="Edit Data"
              icon={Icons.Edit}
              action={() => setDataIsOpen(true)}
            />
          )}
          {requiresData && (
            <DataSelection
              show={dataIsOpen}
              confirm={values => {
                setDataIsOpen(false);
                modifyComponent(component, order, order, {
                  data: values.join(","),
                });
              }}
              cancel={() => setDataIsOpen(false)}
              component={component}
            />
          )}
          <OptionButton
            icon={Icons.Plus}
            label="Add Spacer"
            action={() =>
              addComponent(
                spacers[0],
                panel.series,
                panel.panelType,
                panel.plateFinish,
                order + 1,
              )
            }
          />
        </div>
        {component.isSpacer && (
          <Select
            openOnFocus
            className={styles.spacers}
            name="spacer"
            value={component}
            equalityCheck={(comp1: Component, comp2: Component) =>
              comp1.uuid === comp2.uuid
            }
            onChange={(_, value) =>
              replaceComponent(
                value,
                order,
                panel.series,
                panel.panelType,
                panel.plateFinish,
              )
            }
            options={spacers.map(c => ({
              label: c.shortDescription,
              value: c,
            }))}
          />
        )}
      </div>
    </>
  );
};

export { Options };
export default Options;
