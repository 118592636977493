import * as yup from "yup";

import {
  Alert,
  Button,
  ButtonBar,
  Choice,
  Field,
  Form,
  Heading,
  Input,
  Loader,
  Prompt,
  Modal,
  Select,
  Wrapper,
} from "@bespohk/uikit/components";
import {
  Definition,
  Panel as Model,
  Protection,
  ProtectionOther,
  WritePanel,
} from "@app/models/panel";
import {
  Operations as PanelBuilderOperations,
  operations as panelBuilderOperations,
} from "@app/state/ducks/panel-builder/operations";
import {
  Operations as ProjectOperations,
  operations as projectOperations,
} from "@app/state/ducks/project/operations";
import React, { useEffect, useState } from "react";
import {
  paginatedComponentOptions,
  paginatedPanelTypeOptions,
  paginatedPlateFinishOptions,
  paginatedSeriesOptions,
  protectionOptions,
  protectionOtherOptions,
} from "@app/helpers/form";
import { useHistory, useParams } from "react-router-dom";

import Component from "@app/models/component";
import { Components } from "./component-selection";
import { Overview } from "./overview";
import { PanelModifier } from "@app/state/ducks/panel-builder/types";
import PanelType from "@app/models/panel-type";
import PlateFinish from "@app/models/plate-finish";
import { Preview } from "@app/components";
import Project from "@app/models/project";
import Series from "@app/models/series";
import State from "@app/state";
import classNames from "classnames/bind";
import { createSelector } from "reselect";
import { lookup } from "@app/helpers/api";
import { scroll } from "@app/helpers/browser";
import styles from "./styles.modules.css";
import { useOperations } from "@app/helpers/redux";
import { useSelector } from "react-redux";
import { ComponentViewer } from "@app/containers/component-viewer";

const cx = classNames.bind(styles);

type OwnProps = {};
type StateProps = {
  project?: Project;
  panel?: Model;
};

type PanelProps = OwnProps & StateProps;

enum SaveType {
  Default,
  Finish,
  Mirror,
  Copy,
  New,
}

const newIdentifier = "new";

const panelSelector = (state: State) => state.panelBuilder.panel;
const panelSetSelector = (state: State) => state.panelBuilder.panelSet;
const projectSelector = (state: State) => state.project.data;
const refreshingSelector = (state: State) => state.panelBuilder.loading;
const lookupSelector = (state: State) => state.panelBuilder.lookups;

const dataSelector = createSelector(
  panelSelector,
  panelSetSelector,
  projectSelector,
  refreshingSelector,
  lookupSelector,
  (panel, panelSet, project, refreshing, lookups) => ({
    panel,
    panelSet,
    project,
    refreshing,
    lookups,
  }),
);

const Panel = (_: PanelProps) => {
  const params = useParams();
  const history = useHistory();
  const id = params["uuid"];
  const projectUuid = params["projectUuid"];
  const isNew = id === newIdentifier;

  const { panel, panelSet, project, refreshing, lookups } = useSelector(
    dataSelector,
  );
  const [showAllComponents, setShowAllComponents] = useState(false);
  const [query, setQuery] = useState(null);

  const fetchedProjectUuid = project ? project.uuid : null;
  const fetchedPanelUuid = panel ? panel.uuid : null;

  const operations = useOperations<{
    project: ProjectOperations;
    builder: PanelBuilderOperations;
  }>({ project: projectOperations, builder: panelBuilderOperations });

  const [factoryView, setFactoryView] = useState(false);
  const [showCosts, setShowCosts] = useState(false);
  const [saveType, setSaveType] = useState(SaveType.Default);
  const [addingScav, setAddingScav] = useState(false);
  const [changedGPOCentre, setChangedGPOCentre] = useState(false);
  const [panelHbar, setPanelHbar] = useState(null);
  const scavSpacer = useSelector((state: State) => {
    const spacers = state.panelBuilder.lookups.spacers.filter(
      spacer => spacer.width === 100,
    );
    if (spacers) {
      return spacers[0];
    }

    return null;
  });

  const url = new URL(window.location.href);
  const viewingHistory = url.searchParams.get("history") !== null;

  const loadProject = () => {
    operations.project.fetch(
      _,
      `/projects/${projectUuid}${viewingHistory ? "?include=all" : ""}`,
    );
  };

  useEffect(() => {
    if (!fetchedProjectUuid) {
      // Haven't got a project yet, get the project
      loadProject();
    }
  }, [fetchedProjectUuid]);

  useEffect(() => {
    if (
      (!fetchedPanelUuid || (fetchedPanelUuid && fetchedPanelUuid !== id)) &&
      project
    ) {
      if (!isNew) {
        const workingPanel = project.panels.find(panel => panel.uuid === id);
        // setPanelHbar(workingPanel.hbar);
        operations.builder.setWorking(workingPanel);
      } else {
        const modifier: PanelModifier =
          saveType === SaveType.Mirror
            ? "mirror"
            : saveType === SaveType.Copy
            ? "copy"
            : saveType === SaveType.New
            ? "new"
            : null;
        const newPanel = modifier ? panel : null; // Use the existing panel, we're going to modify it in the reducer
        operations.builder.setWorking(newPanel, modifier);
      }
    }
    return () => {
      operations.builder.clear();
    };
  }, [fetchedProjectUuid, id]);

  const loaded = panelSet && project;
  const editable = panelSet && project && !panel.deleted && !viewingHistory;

  return (
    <>
      {viewingHistory && panelSet && panelSet.panel.revision && (
        <Alert type={panelSet.panel.deleted ? "error" : "warning"}>
          You are viewing REV {panelSet.panel.revision} of{" "}
          {panelSet.panel.mspReference}. Do not try to edit this panel.{" "}
          {panelSet.panel.deleted ? "This panel has been deleted" : ""}
        </Alert>
      )}
      <Form
        initial={panel}
        initialTransform={yup.object().shape({
          uuid: yup.string().strip(true),
          originalRevisionDate: yup.date().strip(true),
          createdDate: yup.mixed().strip(true),
          updatedDate: yup.mixed().strip(true),
          discount: yup.number().default(0),
          quantity: yup.number().default(1),
          // gpoCentre: yup.number().default(50),
          protection: yup.string().default(Protection.rcd_protected),
          protectionOther: yup.string().default(ProtectionOther.body_protected),
        })}
        preValidateTransform={yup.object().shape({
          series: yup.mixed().convert("id"),
          panelType: yup.mixed().convert("id"),
          plateFinish: yup.mixed().convert("id"),
          protection: yup.string(),
          protectionOther: yup.string(),
          expectedPrice: yup
            .mixed()
            .convert(expectedPrice => parseFloat(expectedPrice) || null),
          quantity: yup.mixed().convert(quantity => parseInt(quantity) || 0),
          discount: yup.mixed().convert(discount => parseFloat(discount) || 0),
          component: yup.mixed().strip(true),
          project: yup.string().default(projectUuid),
          components: yup.mixed().convert((components: Component[]) =>
            components.map((component, order) => {
              const {
                uuid,
                showHbar,
                data,
                startsRow,
                width,
                forcedWallboxEnd,
                showEngravedLineBefore,
              } = component;
              return {
                uuid,
                order,
                showHbar: showHbar ? true : false,
                rowStart: startsRow ? true : false,
                forcedWallboxEnd: forcedWallboxEnd ? true : false,
                showEngravedLineBefore,
                data,
                gridSize: width, // TODO: Rename this to width
              };
            }),
          ),
        })}
        validate={yup.object().shape({
          mspReference: yup
            .string()
            .nullable()
            .required("MSP Reference is required."),
          protection: yup.string().required("Protection is required."),
          protectionOther: yup
            .string()
            .required("Protection Other is required."),
          series: yup.number().required("Series is required."),
          expectedPrice: yup.number().nullable(),
          quantity: yup.number().moreThan(-1, "Quantity must be 0 or above"),
          panelType: yup.number().required("Panel Type is required."),
          plateFinish: yup.number().required("Plate Finish is required."),
          discount: yup.number().moreThan(-1, "Discount must be above 0"),
        })}
        onChange={(oldValues, newValues) => {
          if (
            oldValues.gpoCentre &&
            oldValues.gpoCentre !== newValues.gpoCentre
          ) {
            setChangedGPOCentre(true);
          }
          if (newValues.plateFinish) {
            setPanelHbar(
              lookups.hbars.find(
                hbar => hbar.plateFinish.id === newValues.plateFinish.id,
              ),
            );
          }
        }}
        onError={() => scroll()}
        onSubmit={values => {
          const { update, create, validate } = operations.builder;

          const redirect = (saved: Definition) => {
            if (saveType === SaveType.Finish) {
              history.push(`/projects/${projectUuid}`);
              scroll();
            } else {
              if (isNew && saveType !== SaveType.New) {
                history.push(`/projects/${projectUuid}/panels/${saved.uuid}`);
              }
              if (
                saveType === SaveType.Mirror ||
                saveType === SaveType.Copy ||
                saveType === SaveType.New
              ) {
                history.push(`/projects/${projectUuid}/panels/new`);
              }
              scroll();
            }
          };

          return validate(panelSet, lookups).then(() => {
            // TODO: Restructure how this is being pulled from the reducer
            // currently removed as discount is no longer being sent
            delete values.discount;
            if (isNew) {
              return create(values as WritePanel, project, lookups).then(
                redirect,
              );
            } else {
              return update(panel, values as WritePanel, project, lookups).then(
                redirect,
              );
            }
          });
        }}
      >
        {({ values }) => {
          return (
            <>
              <Wrapper grid={[2, 1]}>
                <div className={styles.form}>
                  <div className={cx({ formLoader: true, refreshing })}>
                    <Loader label="Retrieving panel data..." />
                  </div>
                  {panelHbar !== null && !panelHbar && (
                    <Alert
                      when
                      message="The selected plate finish does not have an associated Hbar, this must be configured under Hbars."
                      type="info"
                    />
                  )}
                  <Wrapper grid={[1, 1]}>
                    <Field required label="MSP Reference">
                      <Input name="mspReference" />
                    </Field>
                    <Field required>
                      <Select
                        name="panelType"
                        openOnFocus
                        async={lookup(
                          PanelType,
                          "/panel-types",
                          paginatedPanelTypeOptions,
                          (q: string) =>
                            q ? `(name:${q}){*}&limit=500` : null,
                        )}
                      />
                    </Field>
                    <Field required>
                      <Select
                        name="series"
                        openOnFocus
                        async={lookup(
                          Series,
                          "/series?limit=500",
                          paginatedSeriesOptions,
                        )}
                      />
                    </Field>
                    <Field required label="GPO Centre">
                      <Select
                        disabled={!values.series}
                        name="gpoCentre"
                        openOnFocus
                        typeThreshold={0}
                        searchThreshold={0}
                        options={
                          values.series
                            ? values.series.gpoCentres.map((value: number) => ({
                                value,
                                label: value,
                              }))
                            : []
                        }
                      />
                    </Field>
                    <Field required>
                      <Select
                        name="plateFinish"
                        openOnFocus
                        async={lookup(
                          PlateFinish,
                          "/plate-finishes",
                          paginatedPlateFinishOptions,
                          (q: string) =>
                            q ? `(name:${q}){*}&limit=500` : null,
                        )}
                      />
                    </Field>
                    <Field label="Expected Price (unit)">
                      <Input name="expectedPrice" type="number" />
                    </Field>
                    <div>
                      <Field required>
                        <Select
                          name="protection"
                          options={protectionOptions}
                          openOnFocus
                        />
                      </Field>
                      <Field label={null} required>
                        <Select
                          openOnFocus
                          name="protectionOther"
                          options={protectionOtherOptions}
                        />
                      </Field>
                    </div>
                    <Field required>
                      <Input name="quantity" type="number" />
                    </Field>
                  </Wrapper>
                  <Field>
                    <Input name="location" />
                  </Field>
                  <Field>
                    <Input name="specialNotes" lines={5} />
                  </Field>
                  {editable &&
                    values.series &&
                    values.panelType &&
                    values.gpoCentre &&
                    values.plateFinish && (
                      <div className={styles.components}>
                        <Wrapper className={cx("componentsHeading")}>
                          <Heading value="Components" size="medium" />
                          <Button
                            label="Show all components"
                            style="secondary"
                            type="text"
                            size="small"
                            action={() => setShowAllComponents(true)}
                          />
                        </Wrapper>

                        <Wrapper grid={[4, 1]}>
                          <Field label={null} className={styles.component}>
                            <Select
                              openOnFocus
                              ignoreInternalOnOpen
                              name="component"
                              placeholder="Search for a component by description or equipment code..."
                              searchThreshold={2}
                              typeThreshold={2}
                              renderer={{
                                option: option => (
                                  <div>
                                    <div className={styles.option}>
                                      {option.label}{" "}
                                      <div className={styles.code}>
                                        {option.value.code} (
                                        {option.value.width}
                                        mm)
                                      </div>
                                    </div>
                                  </div>
                                ),
                              }}
                              async={lookup(
                                Component,
                                "/components",
                                resources => {
                                  const filteredResources: Component[] = resources.results
                                    .map((component: Component) => {
                                      const divWidth =
                                        component.width / values.gpoCentre;
                                      return divWidth === 0 ||
                                        divWidth === component.quantity ||
                                        !component.isGpo
                                        ? component
                                        : null;
                                    })
                                    .filter(Boolean);
                                  return paginatedComponentOptions(
                                    filteredResources,
                                  );
                                },
                                (q: string) => {
                                  const name = q || query;
                                  const series = `{*,series(id:${values.series.id}){*}}`;
                                  setQuery(name);
                                  return `(enabled:True${
                                    name ? `,name:${name}` : ""
                                  })${series}`;
                                },
                              )}
                            />
                          </Field>
                          <ButtonBar layout="left">
                            <Button
                              label="+"
                              style="tertiary"
                              disabled={!values.component}
                              action={() => {
                                const {
                                  component,
                                  series,
                                  panelType,
                                  plateFinish,
                                } = values;
                                operations.builder.addComponent(
                                  component,
                                  series,
                                  panelType,
                                  plateFinish,
                                );
                                if (component.isScav) {
                                  setAddingScav(true);
                                }
                              }}
                            />
                            <Button
                              label="Clear"
                              style="tertiary"
                              action={() => {
                                operations.builder.clearComponents();
                              }}
                            />
                          </ButtonBar>
                        </Wrapper>
                      </div>
                    )}
                  <Heading value="Preview" size="medium" />
                  <Choice
                    name="factoryView"
                    onChange={() => {
                      setFactoryView(!factoryView);
                    }}
                    value={[factoryView]}
                    options={[{ label: "Factory View", value: true }]}
                  />
                  <Choice
                    name="costs"
                    onChange={() => {
                      setShowCosts(!showCosts);
                    }}
                    value={[showCosts]}
                    options={[{ label: "Toggle Costs", value: true }]}
                  />
                  {loaded && panelSet.panel.hasComponents && (
                    <Preview
                      editing
                      className={styles.preview}
                      factoryView={factoryView}
                      fullView={factoryView}
                      showCosts={showCosts}
                      panelSet={panelSet}
                      sizingMethod="viewport"
                      componentRenderer={Components}
                      componentsLength={panelSet.panel.components.length}
                    />
                  )}
                </div>
                {loaded && <Overview />}
              </Wrapper>
              {editable && (
                <ButtonBar>
                  <Button
                    label="Save"
                    type="submit"
                    action={() => {
                      setSaveType(SaveType.Default);
                    }}
                  />
                  <Button
                    label="Save & Mirror"
                    style="tertiary"
                    type="submit"
                    action={() => {
                      setSaveType(SaveType.Mirror);
                    }}
                  />
                  <Button
                    label="Save & Copy"
                    style="tertiary"
                    type="submit"
                    action={() => {
                      setSaveType(SaveType.Copy);
                    }}
                  />
                  <Button
                    label="Save & Add New"
                    style="tertiary"
                    type="submit"
                    action={() => {
                      setSaveType(SaveType.New);
                    }}
                  />
                  <Button
                    type="submit"
                    label="Save & Finish"
                    style="tertiary"
                    action={() => {
                      setSaveType(SaveType.Finish);
                    }}
                  />
                </ButtonBar>
              )}
              <Prompt
                when={addingScav}
                title="Scavenge Spacers"
                message="When scavenge/venturi outlet on left hand side a 100mm space is required to the left, do you want to add?"
                negative={{
                  action: () => setAddingScav(false),
                  label: "No",
                }}
                positive={{
                  action: () => {
                    const { series, panelType, plateFinish } = values;
                    operations.builder.addComponent(
                      scavSpacer,
                      series,
                      panelType,
                      plateFinish,
                      panel.components.length - 1,
                      { forcedWallboxEnd: true },
                    );
                    setAddingScav(false);
                  },
                  label: "Yes",
                }}
              />
              <Prompt
                when={changedGPOCentre}
                title="GPO center changed"
                message="You have changed the GPO Center for this drawing, not all components may be available for this setting. You may need to save this panel or add new components before visible changes are made."
                negative={{
                  label: "OK",
                  action: () => {
                    setChangedGPOCentre(false);
                    operations.builder.changeGPOCenter(
                      values.gpoCentre,
                      panel.components
                        .filter(component => component.isGpo)
                        .map(component => component.uuid),
                    );
                  },
                }}
              />
              {values.series && showAllComponents && (
                <Modal when onClose={() => setShowAllComponents(false)}>
                  <ComponentViewer
                    series={values.series}
                    onClick={component => {
                      const { series, panelType, plateFinish } = values;
                      operations.builder.addComponent(
                        component,
                        series,
                        panelType,
                        plateFinish,
                      );
                      if (component.isScav) {
                        setAddingScav(true);
                      }
                      setShowAllComponents(false);
                    }}
                  />
                  <ButtonBar>
                    <Button
                      label="Close"
                      action={() => setShowAllComponents(false)}
                    />
                  </ButtonBar>
                </Modal>
              )}
            </>
          );
        }}
      </Form>
    </>
  );
};

export { Panel };
export default Panel;
