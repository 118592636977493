import Action from "../types";
import Document from "@app/models/bom";
import Types from "./types";

const generateAttempt = (): Action<null> => ({
  type: Types.GENERATE_ATTEMPT,
  payload: null,
});

const generateSuccess = (bom: Document): Action<Document> => ({
  type: Types.GENERATE_SUCCESS,
  payload: bom,
});

const exportRequest = (bom: Document): Action<Document> => ({
  type: Types.EXPORT_REQUEST,
  payload: bom,
});

const exportSuccess = (bom: Document): Action<Document> => ({
  type: Types.EXPORT_SUCCESS,
  payload: bom,
});

const exportError = (bom: Document): Action<Document> => ({
  type: Types.EXPORT_ERROR,
  payload: bom,
});

export {
  generateAttempt,
  generateSuccess,
  exportRequest,
  exportSuccess,
  exportError,
};
