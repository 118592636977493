import * as yup from "yup";

import {
  Button,
  Choice,
  Input,
  Loader,
  Select,
  Wrapper,
} from "@bespohk/uikit/components";
import { default as Model, Status } from "@app/models/project";
import { Operations, operations } from "@app/state/ducks/project/operations";
import React, { useEffect, useState } from "react";
import {
  choiceFromBoolean,
  choiceToBoolean,
  paginatedContactOptions,
  paginatedCustomerOptions,
  paginatedUserOptions,
  statusOptions,
  territoryOptions,
} from "@app/helpers/form";

import Contact from "@app/models/contact";
import Customer from "@app/models/customer";
import Drawings from "./drawings";
import Exports from "./exports";
import { ModelForm } from "@app/containers";
import { SendForApproval } from "./send-for-approval";
import State from "@app/state";
import User from "@app/models/user";
import { get, lookup } from "@app/helpers/api";
import styles from "./styles.modules.css";
import { useOperations } from "@app/helpers/redux";
import { useSelector } from "react-redux";

type OwnProps = {};

const Project = (_: OwnProps) => {
  const user: User = useSelector((state: State) => state.auth.data.user);
  const project = useSelector((state: State) => state.project.data);
  const [showApprovalPrompt, setShowApprovalPrompt] = useState<Model>(null);
  const { refreshPricing } = useOperations<Operations>(operations);
  const [defaultFormValue, setDefaultFormValue] = useState<any>({
    status: "quote",
    priority: 1,
  });
  const [customer, setCustomer] = useState<Customer>(null);
  const [contact, setContact] = useState<Contact>(null);

  const url = new URL(window.location.href);
  const customerId = url.searchParams.get("customerId");
  const contactId = url.searchParams.get("contactId");
  useEffect(() => {
    if (customerId && contactId) {
      Promise.all([
        get(`/customers/${customerId}`, Customer),
        get(`/contacts/${contactId}`, Contact),
      ]).then(([customer, contact]) => {
        setCustomer(customer);
        setContact(contact);
        setDefaultFormValue({ ...defaultFormValue, customer, contact });
      });
    }
  }, []);

  if (customerId && contactId && !customer && !contact) {
    return <Loader label="Retrieving customer and contact data..." />;
  }

  return (
    <Wrapper grid className={styles.wrapper}>
      <ModelForm
        def={defaultFormValue}
        actionBarLayout="left"
        identifier="uuid"
        endpoint="/projects"
        type={Model}
        redux={{
          operations,
          reducer: "project",
        }}
        initialTransform={yup.object().shape({
          createdDate: yup.mixed().strip(true),
          updatedDate: yup.mixed().strip(true),
          contact: yup.mixed().convert("."),
          bdm: yup.mixed().convert("."),
          point: yup.mixed().convert("."),
          customer: yup.mixed().convert("."),
          uuid: yup.mixed().strip(true),
          isCad: choiceFromBoolean(),
        })}
        validate={yup.object().shape({
          customer: yup.string().required(),
          contact: yup.string().required(),
          bdm: yup.string().required(),
          point: yup.string().required(),
          salesOrderNumber: yup.string().required(),
          name: yup.string().required(),
          priority: yup.string().required(),
          revision: yup.string().required(),
          territory: yup.string().required(),
          drawingReference: yup.string().required(),
          status: yup.string().required(),
          isCad: choiceToBoolean(),
        })}
        preValidateTransform={yup.object().shape({
          customer: yup.mixed().convert("uuid"),
          contact: yup.mixed().convert("uuid"),
          point: yup.mixed().convert("uuid"),
          bdm: yup.mixed().convert("uuid"),
          panels: yup.mixed().strip(true),
          panelSets: yup.mixed().strip(true),
          offerings: yup.mixed().strip(true),
          isCad: choiceToBoolean(),
        })}
        fieldsets={[
          [
            {
              name: "revision",
              label: "Quote revision",
              renderer: Input,
              props: { required: true, type: "number" },
            },
          ],
          [
            {
              name: "customer",
              renderer: Select,
              props: {
                autoFocus: true,
                openOnFocus: true,
                ignoreInternalOnOpen: true,
                required: true,
                async: lookup(
                  Customer,
                  "/customers",
                  paginatedCustomerOptions,
                  (q: string) => (q ? `(businessName:${q}){*}` : null),
                ),
                renderer: {
                  option: option => (
                    <div>
                      <div className={styles.option}>
                        {option.value.businessName}{" "}
                        <div className={styles.code}>
                          {option.value.accountCode}
                        </div>
                      </div>
                    </div>
                  ),
                },
              },
              additional: (
                <Button
                  className={styles.additional}
                  label="Add new"
                  type="text"
                  action={"/customers/new"}
                />
              ),
            },
          ],
          [
            {
              name: "contact",
              renderer: Select,
              props: values => {
                const { customer } = values;
                const query = customer ? `,customer:${customer.uuid}` : "";

                return {
                  required: true,
                  openOnFocus: true,
                  ignoreInternalOnOpen: true,
                  async: lookup(
                    Contact,
                    "/contacts",
                    paginatedContactOptions,
                    (q: string) =>
                      q
                        ? `(name:${q}${query}){*}`
                        : `(${query.replace(",", "")}){*}`,
                  ),
                  renderer: {
                    option: option => (
                      <div>
                        <div className={styles.option}>
                          {option.value.name}{" "}
                          <div className={styles.code}>
                            {option.value.email}
                          </div>
                        </div>
                      </div>
                    ),
                  },
                };
              },
              additional: values => {
                const { customer } = values;
                const query = customer
                  ? `?customerId=${customer.uuid}&redirect=/projects/new`
                  : "";
                return (
                  <Button
                    className={styles.additional}
                    label="Add new"
                    type="text"
                    action={`/contacts/new${query}`}
                  />
                );
              },
            },
          ],
          [
            {
              name: "bdm",
              label: "BDM",
              renderer: Select,
              props: {
                required: true,
                openOnFocus: true,
                async: lookup(
                  User,
                  "/users",
                  paginatedUserOptions,
                  (q: string) =>
                    q
                      ? `(name:${q},role:bdm,enabled:true){*}`
                      : "(role:bdm,enabled:true){*}",
                ),
                renderer: {
                  option: option => (
                    <div>
                      <div className={styles.option}>
                        {option.value.fullName}{" "}
                        <div className={styles.code}>{option.value.email}</div>
                      </div>
                    </div>
                  ),
                },
              },
            },
          ],
          [
            {
              name: "point",
              label: "Point of contact for this quotation",
              renderer: Select,
              props: {
                required: true,
                openOnFocus: true,
                async: lookup(
                  User,
                  "/users",
                  paginatedUserOptions,
                  (q: string) =>
                    q
                      ? `(name:${q},role:sales,enabled:true){*}`
                      : "(role:sales,enabled:true){*}",
                ),
                renderer: {
                  option: option => (
                    <div>
                      <div className={styles.option}>
                        {option.value.fullName}{" "}
                        <div className={styles.code}>{option.value.email}</div>
                      </div>
                    </div>
                  ),
                },
              },
            },
          ],
          [
            {
              name: "salesOrderNumber",
              renderer: Input,
              props: { required: true },
            },
          ],
          [
            {
              name: "name",
              label: "Project Name",
              renderer: Input,
              props: { required: true },
            },
          ],
          [
            {
              name: "code",
              label: "Project Code",
              renderer: Input,
              props: {
                disabled: true,
                placeholder: "Generated automatically...",
              },
            },
          ],
          [
            {
              name: "priority",
              renderer: Input,
              props: { type: "number", required: true },
            },
          ],
          [{ name: "purchaseOrderNumber", renderer: Input }],
          [
            {
              name: "territory",
              renderer: Select,
              props: {
                required: true,
                openOnFocus: true,
                typeThreshold: 0,
                searchThreshold: 0,
                options: territoryOptions,
              },
            },
          ],
          [
            {
              name: "drawingReference",
              renderer: Input,
              props: { required: true },
            },
          ],
          [{ name: "notes", renderer: Input, props: { lines: 6 } }],
          [
            {
              name: "isCad",
              label: null,
              renderer: Choice,
              props: {
                disabled: !(user.isAdmin || user.isOperations),
                options: [
                  { value: true, label: "Drawings are overridden by CAD" },
                ],
              },
            },
          ],
          [
            {
              name: "status",
              renderer: Select,
              props: {
                required: true,
                openOnFocus: true,
                options: statusOptions,
              },
            },
            null,
            null,
          ],
        ]}
        actions={
          !!project && (
            <>
              <Button
                label="Refresh pricing"
                style="tertiary"
                action={() => refreshPricing(project)}
              />
              <Button
                label={
                  project.isStatus(Status.out_for_approval)
                    ? "Send for re-approval"
                    : "Send for approval"
                }
                style="tertiary"
                action={() => setShowApprovalPrompt(project)}
              />
            </>
          )
        }
      />
      <div>
        <SendForApproval
          project={showApprovalPrompt}
          close={() => setShowApprovalPrompt(null)}
        />
        <Exports />
        <Drawings />
      </div>
    </Wrapper>
  );
};

export { Project };
export default Project;
