import Alert from "./alert";
import { default as AlertModel } from "@app/models/alert";
import React from "react";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";

const cx = classNames.bind(styles);

type OwnProps = {
  alerts?: AlertModel[];
};

const Alerts = ({ alerts }: OwnProps) => {
  return (
    <div className={cx({ outer: true })}>
      {alerts.map((alert, index) => (
        <Alert key={index} alert={alert} />
      ))}
    </div>
  );
};

export { Alerts };
export default Alerts;
