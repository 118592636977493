import Action, { State } from "../types";

import Inclusion from "@app/models/inclusion";
import Types from "./types";

const initialState: State<Inclusion> = {
  loading: false,
  data: null,
};

const reducer = (
  state = initialState,
  action: Action<Inclusion>,
): State<Inclusion> => {
  const { type } = action;
  let { payload } = action;
  let loading = false;

  switch (type) {
    case Types.GENERATE_ATTEMPT:
      loading = true;
      payload = state.data;
      break;

    case Types.GENERATE_SUCCESS:
      loading = false;
      break;

    default:
      return state;
  }
  return { loading, data: payload };
};

export { reducer };

export default reducer;
