import React, { useEffect, useRef, useState } from "react";

import { Backplate } from "./backplate";
import { Components } from "./components";
import { Grids } from "./grids";
import { Panel } from "./panel";
import PanelSet from "@app/models/panel-set";
import { ServiceTypes } from "./service-types";
import classNames from "classnames/bind";
import styles from "./styles.modules.css";
import { log } from "@bespohk/lib";

const cx = classNames.bind(styles);

type SizingMethod = "parent" | "viewport" | "print" | "a4" | "a3";

type OwnProps = {
  factoryView?: boolean;
  fullView?: boolean;
  panelSet: PanelSet;
  componentRenderer?: any;
  sizingMethod?: SizingMethod;
  className?: string;
  editing?: boolean;
  componentsLength: number;
  showCosts?: boolean;
  isDrawing?: boolean;
};

let timeoutId;

const Preview = ({
  panelSet,
  componentRenderer,
  fullView,
  factoryView,
  sizingMethod,
  className,
  editing,
  showCosts,
  componentsLength,
  isDrawing,
}: OwnProps) => {
  const { panel, backplate, gridContainer } = panelSet;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const groupRef = useRef<HTMLDivElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);

  const Renderer = componentRenderer;
  const [minHeight, setMinHeight] = useState(100);
  const [transform, setTransform] = useState(null);
  const [horizontal, setHorizontal] = useState(false);
  const [longHorizontal, setLongHorizontal] = useState(false);

  const resize = () => {
    if (!panelRef.current || !groupRef.current || !wrapperRef.current) {
      return;
    }
    const { clientHeight, clientWidth } = document.body;
    const ARROW_OFFSETS = 100;
    const containingDimensions = { width: clientWidth, height: clientHeight };
    const previewDimensions = {
      width: groupRef.current.clientWidth + ARROW_OFFSETS,
      height: groupRef.current.clientHeight + ARROW_OFFSETS,
    };
    if (sizingMethod === "print") {
      containingDimensions.width = 1400;
      containingDimensions.height = 595;
    } else if (
      sizingMethod === "parent" ||
      sizingMethod === "a3" ||
      sizingMethod === "a4"
    ) {
      const { clientHeight, clientWidth } = wrapperRef.current
        .parentNode as HTMLDivElement;
      containingDimensions.width = clientWidth;
      containingDimensions.height = clientHeight;
    }

    let maxDimension = Math.max(
      previewDimensions.width,
      previewDimensions.height,
    );
    let largestSide: "width" | "height" =
      maxDimension === previewDimensions.width ? "width" : "height";
    if (sizingMethod === "viewport") {
      maxDimension = previewDimensions.width;
      maxDimension += 100;
      largestSide = "width";
    }
    const containingDimension = containingDimensions[largestSide];

    const scale = panel.hasComponents ? containingDimension / maxDimension : 1;

    log(
      panel.mspReference,
      sizingMethod,
      containingDimensions,
      previewDimensions,
      largestSide,
      containingDimension,
      scale,
    );

    if (scale < 1) {
      setTransform(`scale(${scale}) perspective(1px)`);
    } else {
      setTransform(null);
    }
  };

  useEffect(() => {
    const resizeHandler = () => {
      if (sizingMethod === "viewport") {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(resize, 500);
      } else {
        resize();
      }
    };
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  useEffect(() => {
    resize();
  }, [minHeight, sizingMethod, componentsLength]);

  useEffect(() => {
    if (!groupRef.current) {
      return;
    }
    setMinHeight(groupRef.current.clientHeight + 100);
    const { clientWidth, clientHeight } = panelRef.current;
    if (clientHeight > clientWidth && !editing && panel.isVertical) {
      setHorizontal(true);
      if (clientHeight / clientWidth > 5) {
        // If the height is 5x the width, just show
        setLongHorizontal(true);
      }
    }
  });

  console.log(isDrawing);
  return (
    <div
      className={cx({
        wrapper: true,
        [className]: !!className,
        [sizingMethod]: true,
      })}
      style={{ minHeight: `${minHeight}px`, transform }}
    >
      <div className={styles.wrapperInternal} ref={wrapperRef}>
        <div
          className={cx({
            outer: true,
            factory: !!factoryView,
            horizontal,
            longHorizontal,
          })}
          ref={groupRef}
        >
          {fullView && factoryView && <ServiceTypes panel={panel} />}
          {fullView && <Grids grids={gridContainer.grids} />}
          <div className={styles.align} ref={panelRef}>
            <Panel panel={panel} isDrawing={isDrawing}>
              <Renderer components={panel.components} showCosts={showCosts} />
            </Panel>
          </div>
          <div className={styles.align}>
            <Backplate
              backplate={backplate}
              showCosts={showCosts}
              isDrawing={isDrawing}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Preview.defaultProps = {
  factoryView: false,
  fullView: false,
  componentRenderer: Components,
  sizingMethod: "viewport",
} as OwnProps;

export { Preview, SizingMethod };
export default Preview;
