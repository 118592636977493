import { Loader } from "@bespohk/uikit/components";
import React from "react";

type OwnProps = {};

const Loading = (_: OwnProps) => {
  return <Loader label="Retrieving drawings..." />;
};

export { Loading };
export default Loading;
