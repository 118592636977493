import Project from "@app/models/project";
import React from "react";
import styles from "./styles.modules.css";

type OwnProps = {
  project: Project;
};

const Totals = ({ project }: OwnProps) => {
  return (
    <div className={styles.totals}>
      <div>Total Trade (ex GST)</div>
      <div className={styles.value}>{project.humanTotalCost}</div>
      <div>Total Net (ex GST)</div>
      <div className={styles.value}>{project.humanNetCost}</div>
    </div>
  );
};

export { Totals };
export default Totals;
