import Action, { State } from "../types";

import Document from "@app/models/bom";
import Types from "./types";

const initialState: State<Document> = {
  loading: false,
  data: null,
};

const reducer = (
  state = initialState,
  action: Action<Document>,
): State<Document> => {
  const { type } = action;
  let { payload } = action;
  let loading = false;

  switch (type) {
    case Types.GENERATE_ATTEMPT:
    case Types.EXPORT_REQUEST:
      loading = true;
      payload = state.data;
      break;

    case Types.GENERATE_SUCCESS:
    case Types.EXPORT_SUCCESS:
      loading = false;
      break;

    case Types.EXPORT_ERROR:
      loading = false;
      payload = null;
      break;

    default:
      return state;
  }
  return { loading, data: payload };
};

export { reducer };

export default reducer;
