import { Grid as Model } from "@app/models/grid";
import React from "react";
import classNames from "classnames/bind";
import { range } from "@bespohk/lib";
import styles from "./styles.modules.css";

const cx = classNames.bind(styles);

type OwnProps = {
  grid: Model;
};

const spacing = 12;
const inset = 10;

type CircleProps = {
  hidden?: boolean;
};

// TODO: Circle should be hidden on gas grid with spacer

const Circle = ({ hidden }: CircleProps) => (
  <div className={cx({ circle: true, hidden: hidden })}></div>
);

const outlets = (grid: Model) => {
  const { components } = grid;

  return components.map((component, index) => (
    <div
      key={index}
      className={styles.gasComponent}
      style={{ width: `${component.width}px` }}
    >
      {component.isGas && (
        <div className={cx({ outlets: true })}>
          {range(1, component.quantity).map(i => (
            <Circle key={i} />
          ))}
        </div>
      )}
    </div>
  ));
};

const screws = (grid: Model) => {
  return (
    <div className={cx({ screws: true })}>
      {range(1, Math.floor(grid.width / spacing)).map(index => (
        <div
          className={cx({ screw: true })}
          key={index}
          style={{
            left: `${inset / 2 + (index - 1) * spacing}px`,
          }}
        ></div>
      ))}
    </div>
  );
};

const Grid = ({ grid }: OwnProps) => {
  const style = {
    width: `${grid.width}px`,
  };

  const screwHoles = grid.uuid ? (grid.gasOnly ? null : screws(grid)) : null;

  return (
    <div className={cx({ grid: true, gas: grid.gasOnly })} style={style}>
      {grid.gasOnly ? (
        outlets(grid)
      ) : (
        <div className={cx({ screwHoles: true })}>
          {screwHoles}
          {screwHoles}
        </div>
      )}
      <div className={cx({ label: true })}>
        {grid.uuid ? grid.humanWidth : grid.description}
      </div>
    </div>
  );
};

export { Grid };
export default Grid;
