import {
  Dimensions,
  Error,
  handlingFeesForQuantity,
  Timestamps,
  mixin,
} from "./common";
import {
  isPan,
  missingData,
  offset,
  requiresData,
  width,
  prev,
} from "@app/helpers/components";

import Component from "./component";
import Hbar from "./hbar";
import PanelType from "./panel-type";
import PlateFinish from "./plate-finish";
import Series from "./series";
import { generateRows } from "@app/components/preview/helpers";
import { hydrate, warn, last } from "@bespohk/lib";
import { rounding } from "@app/helpers/currency";
import { zerofill } from "@app/helpers/strings";
import { HEIGHT_OFFSET } from "@app/helpers/constants";
import { ServiceType, default as TypeModel } from "@app/models/type";

// TODO: Fix the type on ilsodation
enum Protection {
  rcd_protected = "RCD Protected", // eslint-disable-line @typescript-eslint/camelcase
  isoldation_transformer_protected = "Isolation Transformer Protected", // eslint-disable-line @typescript-eslint/camelcase
  rcd_iso_trans_protected = "RCD ISO Transformer Protected", // eslint-disable-line @typescript-eslint/camelcase
  neither = "Neither",
}

enum ProtectionOther {
  body_protected = "Body Protected", // eslint-disable-line @typescript-eslint/camelcase
  cardiac_protected = "Cardiac Protected", // eslint-disable-line @typescript-eslint/camelcase
}

type Circuit = {
  rcd: number;
  gpo: number;
  total: number;
};

type Circuits = {
  [color: string]: Circuit;
};

class Definition {
  readonly uuid: string;
  mspReference: string;
  @hydrate
  panelType: PanelType;
  @hydrate
  series: Series;
  gpoCentre: number;
  @hydrate
  plateFinish: PlateFinish;
  quantity = 1;
  discount?: number;
  protection: Protection;
  protectionOther: ProtectionOther;
  location: string;
  expectedPrice?: number;
  specialNotes: string;
  @hydrate(Component)
  components: Component[];
  approved?: boolean;
  deleted?: boolean;
  manufacturingApproved?: boolean;
  @hydrate
  originalRevisionDate?: Date;
  @hydrate
  updatedDate?: Date;
  @hydrate
  createdDate?: Date;

  readonly revision: number;

  public get humanRevision(): string {
    return `REV ${this.revision}`;
  }

  public toString(): string {
    return this.mspReference;
  }
}

type WritePanelComponent = {
  uuid: string;
  gridSize: number;
  showHbar: boolean;
  forcedWallboxEnd: boolean;
  showEngravedLineBefore: boolean;
  rowStart: boolean;
  data?: string;
};

type WritePanel = {
  project: string;
  mspReference: string;
  protection: Protection;
  protectionOther: ProtectionOther;
  panelType: number;
  plateFinish: number;
  series: number;
  quantity?: number;
  location?: string;
  specialNotes?: string;
  discount?: number;
  components?: WritePanelComponent[];
};

interface Panel extends Timestamps {} // eslint-disable-line @typescript-eslint/no-empty-interface

mixin(Definition, [Timestamps]);

const Errors = {
  pan: {
    message:
      "GPO with Neon can't be at the start/end of a panel without another GPO, either move location or add a spacer to the start/end of panel.",
    skippable: false,
  },
  rcd: {
    message:
      "This panel requires either an RCD or a GPO with Power Available Neon to be added.",
    skippable: false,
  },
};

class Panel extends Definition {
  salesOrderNumber?: string;
  index?: number;
  hbar?: Hbar;

  public equals(panel: Panel): boolean {
    return panel.uuid === this.uuid;
  }

  public get yyNumber(): string {
    return `YY${this.drawingNumber}`;
  }

  public get partNumber(): string {
    return `${this.yyNumber}-PS`;
  }

  public get frontPlatePartNumber(): string {
    return `${this.yyNumber}-PL`;
  }

  public get drawingNumber(): string {
    if (!this.salesOrderNumber) {
      return "";
    }
    return `${this.salesOrderNumber}-${zerofill(`${this.index}`, 2)}`;
  }

  public get isVertical(): boolean {
    return !!this.components.find(component => component.startsRow);
  }

  public get hasGas(): boolean {
    return !!this.components.find(component => component.isGas);
  }

  public get hasComponents(): boolean {
    return !!this.components.length;
  }

  public get strappingSize(): number {
    let strapping = 160;
    if (this.isVertical && this.hasGas) {
      strapping = 200;
    }
    return strapping;
  }

  public get hbars(): Hbar[] {
    return this.components.reduce((hbars, component) => {
      if (component.showHbar) {
        hbars.push(this.hbar);
      }

      return hbars;
    }, []);
  }

  public get cost(): number {
    const handlingFee = handlingFeesForQuantity(this.quantity) / 2;

    let costs: number = this.components.reduce((cost, component) => {
      cost += component.cost;
      return cost;
    }, handlingFee);
    const { length: totalHbars } = this.hbars;
    if (totalHbars) {
      if (this.hbar) {
        costs += this.hbar.cost * totalHbars;
      } else {
        warn(
          `There is no associated hbar for this panels (${this.mspReference}) plate finish ${this.plateFinish}`,
        );
      }
    }
    return rounding(costs);
  }

  public get totalCost(): number {
    return this.cost * this.quantity;
  }

  public get dimensions(): Dimensions {
    const { rows } = this;

    return {
      width: width(rows[0]) + 30,
      height:
        rows.length * this.strappingSize -
        (this.hasGas && this.isVertical ? HEIGHT_OFFSET : 0),
    };
  }

  public get rows(): Component[][] {
    return generateRows<Component>(this.components);
  }

  public get width(): number {
    return this.dimensions.width;
  }

  public get height(): number {
    return this.dimensions.height;
  }

  public get punchingLayout(): string[] {
    const codes: string[] = [];
    let lastPunchCode = null;
    let lastCount = 0;
    let added = false;
    const generateCode = (count: number, code: string) =>
      (count > 1 ? count : "") + code;
    const hbarJoinerCode = "+";
    const rowJoinerCode = "#";

    this.components.forEach(component => {
      const count = component.quantity;
      const punchCode = component.punchCode;
      if (lastPunchCode && lastPunchCode !== punchCode) {
        codes.push(generateCode(lastCount, lastPunchCode));
        added = true;
      }
      if (component.startsRow && !!codes.length) {
        codes.push(rowJoinerCode);
      }
      if (component.showHbar) {
        if (!added) {
          codes.push(generateCode(lastCount, lastPunchCode));
        }
        codes.push(hbarJoinerCode);
        lastCount = 0;
      }
      lastPunchCode = punchCode;
      lastCount += count;
      added = false;
    });
    codes.push(generateCode(lastCount, lastPunchCode));

    const punchingLayout: string[] = codes.join("").split(hbarJoinerCode);

    for (let i = 0; i < punchingLayout.length; i++) {
      let plate = punchingLayout[i];
      if (punchingLayout.length > 1) {
        if (i === 0) {
          plate = `${plate}${hbarJoinerCode}`;
        } else if (i === punchingLayout.length - 1) {
          plate = `${hbarJoinerCode}${plate}`;
        } else {
          plate = `${hbarJoinerCode}${plate}${hbarJoinerCode}`;
        }
      }
      punchingLayout[i] = plate;
    }

    return punchingLayout;
  }

  public get punchingLayoutJoined(): string {
    return this.punchingLayout.join(", ");
  }

  public get circuits(): Circuits {
    const colors = ["white", "red", "blue"];

    const circuits = this.components.reduce(
      (circuits, component) => {
        if (component.isGpo) {
          const description = component.shortDescription.toLowerCase();
          colors.forEach(color => {
            const type = component.isRcd ? "rcd" : "gpo";
            if (description.indexOf(color) > -1) {
              circuits[color][type]++;
              circuits[color].total++;
              if (circuits[color].gpo && component.isRcd) {
                circuits[color].total--;
              }
            }
          });
        }

        return circuits;
      },
      {
        red: {
          rcd: 0,
          gpo: 0,
          total: 0,
        },
        blue: {
          rcd: 0,
          gpo: 0,
          total: 0,
        },
        white: {
          rcd: 0,
          gpo: 0,
          total: 0,
        },
      },
    );

    return circuits;
  }

  public get engravedLines(): number[] {
    // Returns the component index that should show engraved lines
    const lines: number[] = [];
    if (!this.series) {
      return lines;
    }

    const extractColor = (component: Component) =>
      component.equipmentCode.substring(0, 1);

    const { components } = this;
    components.forEach((component, index) => {
      const [, prevComponent] = prev(components, index);
      let skip = false;

      if (prevComponent && (prevComponent.isGpo || prevComponent.isRcd)) {
        skip = true;
      }

      if (!prevComponent || !component.isGpo || !skip) {
        return;
      }

      const currentColor = extractColor(component);
      let componentToAddLine = component;
      const prevColor = extractColor(prevComponent);
      const addEngravedLine = currentColor !== prevColor;

      if (addEngravedLine) {
        if (prevComponent && prevComponent.isRcd) {
          index--;
          /*
          Technically it doesn't need one as the RCD separates it but more and more they're
          asking for the line to be there between the last GPO of the first group and the
          2nd RCD. This provides clear delineation between the 2 circuits this way and make
          it clear that the 2nd RCD protects the last 3 GPO's and not the first 3 GPOs.
          */
          componentToAddLine = prevComponent;
        }

        if (componentToAddLine.showEngravedLineBefore !== false) {
          componentToAddLine.showEngravedLineBefore = true;
          lines.push(index);
        }
      }
    });

    return lines;
  }

  public get serviceTypes(): ServiceType[] {
    return this.components.reduce((types, component) => {
      const lastType: ServiceType = last(types);
      const type: TypeModel = component.type;
      if (
        !lastType ||
        (lastType && lastType.name !== type.name) ||
        component.startsRow
      ) {
        types.push({
          name: type.name,
          components: [component],
          startsRow: component.startsRow,
        });
      } else {
        lastType.components.push(component);
      }

      return types;
    }, []);
  }

  public errors(lookups): Error[] {
    const errors: Error[] = [];
    const { data } = lookups;
    const { components, width } = this;
    const MAX_HBAR_GAP = 1180;
    const MAX_WIDTH = 2350;

    if (width > MAX_WIDTH) {
      errors.push({
        message: `This panel can not be more than ${MAX_WIDTH}mm wide`,
        skippable: false,
      });
    }

    if (width > MAX_HBAR_GAP && !this.hbars.length) {
      errors.push({
        message: "This panel requires a Hbar, please add additional Hbars.",
        skippable: false,
      });
    }
    let hasGpo = false;
    let hasRcd = false;
    const hbarPositions = [];
    components.forEach((component, index) => {
      if (component.isRcd || isPan(component, this.series)) {
        hasRcd = true;
      }
      if (component.isGpo) {
        hasGpo = true;
      }
      if (
        component.showHbar ||
        (this.hbars.length && index === components.length - 1) ||
        component.rowStart
      ) {
        const currentOffset = offset(components, index);
        const currentOffsetEnd = currentOffset + component.width;
        hbarPositions.push(currentOffsetEnd);
      }
      if (requiresData(component, data) && missingData(component)) {
        errors.push({
          message: `You must supply component data for ${
            component.name
          } at position ${index + 1}`,
          skippable: false,
        });
      }
    });
    const maxHbarGap = hbarPositions.reduce((p, c, i) => {
      const lastOffset = i > 0 ? hbarPositions[i - 1] : 0;
      if (c - lastOffset > p) {
        return c - lastOffset;
      }

      return p;
    }, 0);

    if (hbarPositions.length && maxHbarGap > MAX_HBAR_GAP) {
      errors.push({
        message: `Maximum width between Hbars is ${MAX_HBAR_GAP}mm (found ${maxHbarGap}mm), please add additional Hbars`,
        skippable: false,
      });
    }

    if (hasGpo && !hasRcd) {
      errors.push(Errors.rcd);
    }

    const panError = Errors.pan;

    if (this.series.name.toLowerCase() !== "meditek") {
      if (!!components.length && isPan(components[0], this.series)) {
        if (components[1] && !components[1].isGpo) {
          errors.push(panError);
        }
      }
    }

    return errors;
  }
}

export { Definition, Panel, Protection, ProtectionOther, WritePanel, Errors };

export default Panel;
