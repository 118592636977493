import * as yup from "yup";

import { Choice, Input, Select } from "@bespohk/uikit/components";
import {
  choiceFromBoolean,
  choiceToBoolean,
  territoryOptions,
} from "@app/helpers/form";

import { default as Model } from "@app/models/customer";
import { ModelForm } from "@app/containers";
import React from "react";

type OwnProps = {};
const Customer = (_: OwnProps) => {
  return (
    <ModelForm
      identifier="uuid"
      endpoint="/customers"
      type={Model}
      initialTransform={yup.object().shape({
        enabled: choiceFromBoolean(),
        createdDate: yup.mixed().strip(true),
        updatedDate: yup.mixed().strip(true),
        uuid: yup.mixed().strip(true),
      })}
      preValidateTransform={yup.object().shape({
        enabled: choiceToBoolean(),
      })}
      validate={yup.object().shape({
        businessName: yup
          .string()
          .required("Business Name is a required field."),
        phoneNumber: yup.string().nullable(),
        accountCode: yup.string().required("Account Code is a required field."),
        address: yup.string().required("Address is a required field."),
        suburb: yup.string().required("Suburb is a required field."),
        state: yup.string().required("State is a required field."),
        postcode: yup.number().required("Postcode is a required field."),
        enabled: yup.boolean(),
      })}
      fieldsets={[
        [
          { name: "businessName", renderer: Input, props: { required: true } },
          { name: "phoneNumber", renderer: Input },
        ],
        [
          { name: "accountCode", renderer: Input, props: { required: true } },
          null,
        ],
        [{ name: "address", renderer: Input, props: { required: true } }],
        [
          { name: "suburb", renderer: Input, props: { required: true } },
          {
            name: "state",
            renderer: Select,
            props: {
              openOnFocus: true,
              required: true,
              options: territoryOptions,
            },
          },
          {
            name: "postcode",
            renderer: Input,
            props: { type: "number", required: true },
          },
        ],
        [
          {
            name: "enabled",
            label: null,
            renderer: Choice,
            props: {
              options: [{ value: true, label: "Enabled" }],
            },
          },
        ],
      ]}
    />
  );
};

export { Customer };
export default Customer;
