const zerofill = (str: any, count: number): string => {
  while (str.toString().length < count) {
    str = `0${str}`;
  }
  return str;
};

const ifValue = (val: string, def = null): string => {
  return val ? val : def;
};

export { zerofill, ifValue };
