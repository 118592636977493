import { History } from "history";
import alerts from "./alerts";
import auth from "./auth";
import bom from "./bom";
import { combineReducers } from "redux";
import history from "./history";
import inclusion from "./inclusion";
import oef from "./oef";
import panelBuilder from "./panel-builder";
import project from "./project";
import resource from "./resource";
import resources from "./resources";

const reducer = (_history: History) =>
  combineReducers({
    history: history(_history),
    resources,
    resource,
    alerts,
    auth,
    project,
    oef,
    inclusion,
    bom,
    panelBuilder,
  });

export { reducer };

export default reducer;
